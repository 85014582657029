/*CHAT MAIN*/

/*img{*/
/*	pointer-events: none;*/
/*}*/
@import '@sitestyles';

//iframe { //DISABLE LOCAL FRAME
//  z-index: -100!important;
//}
//
//#checkdv_1 { //DISABLE LOCAL FRAME
//  z-index: -100!important;
//}
$mobileBreakpoint: 599px;

#openCostPolicy {
    font-weight: bold;
    cursor: pointer;
}

.emoji-mart-preview {
    display: none !important;
}

.emoji-mart-bar {
    display: none !important;
}

.emoji-mart-search {
    display: none !important;
}

.blocked * {
    opacity: 0.7;
    cursor: progress !important;
}

em-emoji-picker {
    --background-rgb: #f2f2f2;
    --rgb-background: #f2f2f2;
    box-shadow: none;
    overflow-x: unset !important;
    overflow-y: unset !important;

    .sticky {
        display: none !important;
    }
}

.c1_top {
    background-color: #ebedfa;
    padding-bottom: 8px;
}

.c1_logo {
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 80px;
}

.c1_logo img:nth-child(2) {
    width: 60px;
    display: block;
    margin-left: 6px;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
}

.column-1.small .c1_logo img:nth-child(2) {
    width: 0px;
}

.c1_profile_wrap {
    background-color: #fff;
    padding: 16px 12px 12px;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
}

.c1_profile_photo {
    margin-right: 12px;
}

.c1_profile_photo img {
    width: 56px;
    height: 56px;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 100%;
}

.c1_profile_right div {
    display: block;
    width: 112px;
    height: 24px;
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
    padding-top: 3px;
    font-weight: 500;
    line-height: 17px;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    overflow: hidden;
}

.column-1.small .c1_profile_right .c1_add_credits {
    padding-left: 0px;
    background-position: center;
}

.column-1.small .c1_profile_right div {
    width: 30px;
}

.c1_profile_right a span {
    width: 76px;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    display: block;
    white-space: nowrap;
}

.column-1.small .c1_profile_right div span {
    width: 0px;
    overflow: hidden;
}

.c1_profile_link {
    display: block;
    padding-left: 30px;
    color: $primaryOrangeColor;
    border-color: $primaryOrangeColor;
    background-image: $c1ProfileIco;
    background-repeat: no-repeat;
    background-position: 8px 4px;
    margin-bottom: 8px;
    cursor: pointer;
}

.c1_add_credits {
    cursor: pointer;
    display: block;
    padding-left: 27px;
    padding-right: 15px;
    color: #fff;
    background-color: $primary2Color;
    border-color: $primary2Color;
    background-image: url(../../img/c1-credits-white.svg);
    background-repeat: no-repeat;
    background-position: 18px 3px;
    text-align: center;
    transition: width 0.3s;
}

.c1_add_credits span {
    display: block;
}

.c1_add_credit_big {
    height: 51px;
    background: linear-gradient(90.5deg, $accentLeftColor 0%, $accentRightColor 100%);
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-weight: 600;
    font-size: 18px;
    letter-spacing: -0.03em;
    cursor: pointer;
    margin-top: 8px;
}

.c1_add_credit_big img {
    width: 27px;
    margin-right: 11px;
}

.column-1.small .c1_add_credit_big {
    font-size: 14px;
}

.column-1.small .c1_add_credit_big img {
    width: 19px;
    margin-right: 7px;
}

.c1_copyrights_wrap {
    font-size: 11px;
    line-height: 13px;
    text-align: center;
    color: #446077;
    margin-bottom: 10px;
    margin-top: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.c1_copyrights_links {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.c1_copyrights_links div {
    cursor: pointer;
    text-decoration: underline;
    color: $uploadTabsActiveColor;
}

.c1_copyrights_links div:nth-child(2n):after {
    content: '|';
    padding: 0 3px;
    pointer-events: none;
    cursor: default;
}

.c1_copyrights_links div:last-child:after {
    display: none;
}

.c1_wrap2 {
    padding: 4px 4px 4px 0;
    flex: 1;
    overflow-y: auto;
}

.c1_wrap {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: #fff;
}

.c1_wrap2::-webkit-scrollbar {
    width: 3px;
    background: #f7f8fd;
    border-radius: 5px;
    margin-right: 3px;
}

.c1_wrap2::-webkit-scrollbar-thumb {
    background: $scrollbarThumbColor;
    border-radius: 7px;
}

.c1_wrap::-webkit-scrollbar {
    width: 3px;
    background: #f7f8fd;
    border-radius: 5px;
    margin-right: 3px;
}

.c1_wrap::-webkit-scrollbar-thumb {
    background: $scrollbarThumbColor;
    border-radius: 7px;
}

.c1_menu {
    /*margin-top: -4px;*/
}

.c1_menu_item {
    color: $primaryColor;
    font-size: 20px;
    font-weight: 500;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 56px;
    border-left: 4px solid transparent;
    padding-left: 10px;
    padding-right: 12px;
    cursor: pointer;
    position: relative;
}

.c1_menu_item_privacy {
    padding-right: 0;
}

.c1_menu_item_free_video {
    position: absolute;
    right: 0;
    width: 59px;
    height: 42px;
    line-height: 42px;
    background: #cff5dd;
    border-radius: 5px 1px 1px 5px;
    text-align: center;
    font-weight: 500;
    font-size: 12px;
    color: #333;
}

.c1_menu_item.active {
    color: #fff;
    border-color: $primaryColor;
    background-color: $primaryColor;
}

.c1_menu_item span {
    max-width: 150px;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    white-space: nowrap;
}

.column-1.small .c1_menu_item span {
    max-width: 0px;
    overflow: hidden;
    visibility: hidden;
}

.column-1.small .c1_menu_item {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding-left: 16px;
}

.column-1.small .c1_menu_item_privacy {
    padding-right: 12px;
}

.column-1.small .c1_menu_item.c1_menu_item_videoshow {
}

.c1_menu_item_left {
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.c1_menu_item svg {
    margin-right: 11px;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    fill: $menuIconColor;
    width: 24px;
    height: 24px;
}

.c1_menu_item.active svg {
    fill: #fff;
}

.c1_menu_item_videoshow svg {
    stroke: $menuIconColor;
    stroke-width: 0.5px;
}

.c1_menu_item_videoshow.active svg {
    stroke: #fff;
}

.c1_menu_item_online_now svg {
    fill: transparent;
    stroke: $menuIconColor;
    stroke-width: 0.5px;
}

.c1_menu_item_online_now.active svg {
    stroke: #fff;
    fill: transparent;
}

.c1_menu_item_block svg {
    fill: $menuIconColor;
}

.c1_menu_more_btn.active svg {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}

/*.c1_submenu{*/
/*	display: none;*/
/*}*/

/*.c1_menu_item.active + .c1_submenu{*/
/*	display: block;*/
/*}*/

.c1_submenu_item {
    height: 60px;
    padding: 10px 12px 8px 18px;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    cursor: pointer;
}

.c1_submenu_item.active {
    background-color: $primaryColor;
    color: #fff;
}

.c1_submenu_item_left {
    color: rgba(55, 78, 108, 0.56);
    font-size: 16px;
    font-weight: 500;
}

.c1_submenu_item_left.c1_submenu_item_left_dialog svg {
    width: 17px;
    height: 16px;
    fill: transparent;
    stroke-width: 2px;
}

.c1_submenu_item.active .c1_submenu_item_left.c1_submenu_item_left_dialog svg {
    fill: transparent;
}

.column-1.small .c1_submenu_item_left {
    padding-left: 10px;
}

.c1_submenu_item_left span {
    max-width: 100px;
    max-height: 20px;
    display: block;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    white-space: nowrap;
}

.column-1.small .c1_submenu_item_left span {
    max-width: 0px;
    max-height: 0px;
    overflow: hidden;
}

.c1_submenu_item.active .c1_submenu_item_left {
    color: #fff;
}

.c1_submenu_item_left svg {
    display: block;
    margin-bottom: 9px;
    position: relative;
    top: 3px;
    fill: #3d6599;
    stroke: #3d6599;
    opacity: 0.32;
}

.c1_submenu_item.active .c1_submenu_item_left svg {
    fill: #fff;
    stroke: #fff;
    opacity: 1;
}

.c1_submenu_item_right {
    font-size: 12px;
    font-weight: bold;
    color: #8694ab;
    line-height: 1;
    letter-spacing: -0.02em;
    text-align: center;
    width: 50px;
    background-color: #ebedfa;
    border-radius: 4px;
    padding: 1px 4px 0;
}

.c1_submenu_item_right_top {
    border-bottom: 1px solid #fff;
    padding-bottom: 3px;
    padding-top: 3px;
    margin-bottom: 4px;
}

.c1_submenu_item_right_top span:before {
    content: '';
    display: inline-block;
    width: 4px;
    height: 4px;
    border-radius: 100%;
    background-color: #27ae60;
    vertical-align: middle;
    margin-right: 4px;
}

.c1_submenu_item_right_bottom {
    margin-top: 4px;
    margin-bottom: 4px;
}

.c1_menu_item_count {
    text-align: center;
    width: 50px;
    height: 20px;
    background-color: #ebedfa;
    border-radius: 4px;
    font-size: 12px;
    color: rgba(55, 78, 108, 0.56);
    letter-spacing: -0.02em;
    line-height: 20px;
    font-weight: bold;
    margin-left: auto;
}

.c1_menu_more_btn {
    color: #6f0267;
    cursor: pointer;
    border: none;
}

.c1_menu_more_btn span {
    width: 52px;
}

.c1_menu_item.c1_menu_more_btn.active {
    color: #446077;
    border: none;
}

.c1_menu_more_btn img {
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

.c1_menu_more_btn.active img {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}

.c1_menu_more {
    display: none;
}

.c1_menu_hide_wrap {
    width: calc(100% - 8px);
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    margin-bottom: 10px;
}

.c1_menu_hide_wrap span {
    color: $attachTouchText;
    cursor: pointer;
}

.c1_menu_hide {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid $uploadTabsActiveColor;
    border-radius: 37px;
    width: 25px;
    height: 21px;
    cursor: pointer;
    margin-right: 11px;
}

.c1_menu_hide.small {
}

.c1_menu_hide img {
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
}

.c1_menu_hide.small img {
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
}

.c2_top {
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 25px 16px;
}

.c2_top_online_text {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    color: #374e6c;
}

.c2_top_tumbler input {
    display: none;
}

.c2_top_tumbler label {
    display: block;
    position: relative;
    width: 36px;
    height: 18px;
    background-color: #cdd3da;
    border-radius: 19px;
}

.c2_top_tumbler label:before {
    content: '';
    display: block;
    width: 14px;
    height: 14px;
    border-radius: 100%;
    background-color: #fff;
    position: absolute;
    left: 2px;
    top: 2px;
    -webkit-transition: left 0.3s;
    -o-transition: left 0.3s;
    transition: left 0.3s;
}

.c2_top_tumbler input:checked + label {
    background-color: #fff;
}

.c2_top_tumbler input:checked + label:before {
    left: 20px;
    background-color: $onlineColor;
}

.c2_top_search {
    position: relative;
}

.c2_top_search input {
    width: 174px;
    height: 30px;
    line-height: 28px;
    background-color: #fff;
    border-radius: 5px;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 0.02em;
    padding-left: 12px;
    padding-right: 30px;
    padding-bottom: 1px;
    border: 1px solid $inputBorderNormal;
    color: $inputTextColor;
}

.c2_top_search input::placeholder,
.c2_top_search input::-webkit-input-placeholder {
    font-weight: 500;
    color: #333;
}

.c2_top_search input:hover {
    border-color: $inputBorderHover;
    box-shadow: 0px 3px 6px rgba(28, 38, 119, 0.08);
}

.c2_top_search input:focus {
    border-color: $inputBorderActive;
    box-shadow: 0px 3px 6px rgba(28, 38, 119, 0.08);
}

.c2_top_search button {
    height: 100%;
    width: 30px;
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.c2_top_search_close svg {
    fill: #ccd1d9;
    width: 14px;
    height: 14px;
}

.c2_top_search_search svg {
    fill: $iconCrossSearchColor;
    width: 16px;
    height: 16px;
}

.c2_list_wrap_2 {
    padding-right: 4px;
    flex: 1;
    overflow: hidden;
}

.c2_list_wrap {
    height: 100%;
    overflow-y: auto;
}

.c2_list_wrap::-webkit-scrollbar {
    width: 3px;
    background: #f7f8fd;
    border-radius: 5px;
    margin-right: 3px;
}

.c2_list_wrap::-webkit-scrollbar-thumb {
    background: $scrollbarThumbColor;
    border-radius: 7px;
}

.c2_list {
    padding: 0 4px 0 4px;
}

.c2_item {
    background-color: #fff;
    border-radius: 8px;
    padding: 17px 0 8px;
    margin-bottom: 10px;
    box-shadow: 0px 3px 5px rgba(14, 4, 143, 0.15);
    cursor: pointer;
    position: relative;
}

.c2_item:hover {
    box-shadow: 0px 4px 8px rgba(14, 4, 143, 0.24);
}

.c2_item .message_status {
    position: absolute;
    top: 14px;
    right: 12px;
}

.c2_item_new_message {
    position: absolute;
    right: 0;
    top: 0;
    background-color: $chatNewBg;
    border-radius: 0 8px;
    width: 67px;
    height: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 12px;
    color: #fff;
}

.c2_new_item_big .message_status {
}

.c2_item_top {
    padding: 0 10px 0 16px;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.c2_item_photo_wrap {
    position: relative;
    margin-right: 13px;
}

.c2_item_photo {
    width: 72px;
    height: 72px;
    border-radius: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    display: block;
}

.c2_item_photo_delete {
    position: absolute;
    left: 7px;
    top: 7px;
    cursor: pointer;
}

.c2_item_photo_delete img {
    display: block;
}

.c2_item_photo_delete svg {
    fill: #ccd1d9;
    width: 14px;
    height: 14px;
}

.c2_item .c2_item_photo_delete {
    left: -11px;
    top: -3px;
}

.c2_item_photo_counter {
    display: block;
    width: 28px;
    height: 28px;
    position: absolute;
    left: -8px;
    top: -8px;
    background-color: $notifyCounterBg;
    border-radius: 4px;
    font-weight: 600;
    font-size: 16px;
    line-height: 30px;
    text-align: center;
    letter-spacing: -0.02em;
    color: $notifyCounterColor;
}

.column-4 .c2_item_photo_counter {
    left: 0;
    top: 0;
}

.c2_item_name {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: $chatItemTitleColor;
    margin-bottom: 4px;
}

.c2_item_text {
    overflow: hidden;
    width: 210px;
    //height: 37px;
    max-height: 50px;
    font-size: 13px;
    line-height: 150%;
    color: #4f4f4f;

    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    display: -webkit-box;
    font-weight: normal;
    -webkit-box-orient: vertical;
}

.c2_item_text img {
    width: 15px;
    vertical-align: middle;
    margin-left: 5px;
    margin-top: -2px;
}

.c2_item_text svg {
    width: 15px;
    height: 15px;
    fill: #f2cb4b;
    vertical-align: middle;
    margin-left: 5px;
    margin-top: -2px;
}

.c2_item_text.from_you {
    display: flex;
    position: relative;
    padding-left: 30px;
}

.c2_item_text.from_you svg{
    width: 15px;
    height: 18px;
    margin-left: 5px;
}

.c2_item_text.from_you:before {
    content: 'You:';
    position: absolute;
    left: 0;
    color: $chatItemFromTitleColor;
}

.current .c2_item_text.from_you:before {
    color: #fff;
}

.c2_item_bottom {
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 9px 0 26px;
    margin-top: -6px;
}

.c2_item_id {
    font-size: 10px;
    line-height: 12px;
    color: #bdbdbd;
    margin-left: -10px;
    opacity: 0;
}

.c2_item_time {
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.03em;
    /*text-transform: uppercase;*/
    color: #bdbdbd;
}

.message_status_unanswered {
    width: 72px;
    height: 20px;
    line-height: 20px;
    position: absolute;
    top: 6px;
    right: 6px;
    background-color: #fbdddd;
    border-radius: 5px;
    font-size: 10px;
    color: rgba(51, 51, 51, 0.8);
    text-align: center;
}

.c2_item_photo_fav {
    display: none;
    position: absolute;
    right: 1px;
    top: -1px;
}

.c2_item_photo_fav svg {
    stroke: white;
    fill: #f2c94c;
}

.c2_item.current .c2_item_photo_fav svg {
    stroke: #446077;
}

.c2_item_photo_wrap:after {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    background-color: #e0e0e0;
    border-radius: 100%;
    border: 2px solid #fff;
    position: absolute;
    right: 2px;
    bottom: 2px;
}

.c2_item.current .c2_item_photo_wrap:after {
    border-color: #446077;
}

.c2_item.online .c2_item_photo_wrap:after {
    background-color: $onlineColor;
}

.c2_item.favorites .c2_item_photo_fav {
    display: block;
}

.c2_item.current {
    background-color: #446077;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.c2_item.current .c2_item_name,
.c2_item.current .c2_item_text {
    color: #fff;
}

.c4_top.blur {
    -webkit-filter: blur(2px);
    filter: blur(2px);
}

.c4_credits_info {
    height: 74px;
    background: #9b9bd5;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    text-align: center;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 6px 10px 0;
}

.c4_credits_info_title {
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 0.06em;
    text-shadow: 0px 1px 3px rgba(47, 43, 99, 0.3);
    margin-bottom: 6px;
}

.c4_credits_info_text {
    font-size: 12px;
}

.c4_list_wrap_2 {
    padding-right: 7px;
    //flex: 1;
    overflow: hidden;
    //max-height: calc(50% - 80px);
}

.c4_list_wrap {
    height: 100%;
    overflow-y: auto;
}

.c4_list_wrap::-webkit-scrollbar {
    width: 3px;
    background: #f7f8fd;
    border-radius: 5px;
    margin-right: 3px;
}

.c4_list_wrap::-webkit-scrollbar-thumb {
    background: $scrollbarThumbColor;
    border-radius: 7px;
}

.c4_list {
    padding: 0 6px 0px 8px;
}

.c4_item {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(95, 95, 95, 0.24);
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    margin-bottom: 8px;
    align-items: center;
}

.c4_item:last-child {
    margin-bottom: 19px;
}

.c4_item:hover {
    z-index: 99;
}

.c4_item.letter {
    align-items: flex-start;
}

.c4_item.clicked {
    position: relative;
    z-index: 5;
    border: 8px solid #fff;
    border-radius: 0 8px 8px 0;
    padding: 0;
}

.c4_item.clicked.message {
    padding: 0;
}

.c4_item.clicked:before {
    content: '';
    display: block;
    height: calc(100% + 45px);
    width: 19px;
    position: absolute;
    top: -22px;
    left: -8px;
    background:
        url(/img/c4-item-clicked-top.svg) left top no-repeat,
        url(../../img/c4-item-clicked-bottom.svg) left bottom no-repeat;
}

.c4_item.clicked .c4_item_photo {
    width: 168px;
    height: 270px;
    border-radius: 8px 8px 10px 8px;
}

.c4_item.clicked .c4_item_time {
    bottom: unset;
    top: 8px;
    right: 32px;
}

.c4_item.clicked .c4_item_text {
    width: auto;
}

/*.c4_item.clicked:after{
	content: '';
	display: block;
	width: 120%;
	height: calc(100% + 16px);
	background-color: #fff;
	position: absolute;
	right: -8px;
	top: -8px;
	z-index: 0;
}*/

.c4_item.liked {
    padding: 10px 40px 10px 10px;
}

.c4_item.activity {
    padding: 10px 40px 10px 10px;
}

.c4_item.viewed {
    padding: 10px 20px 10px 10px;
}

.c4_item.message {
    padding: 10px 10px 10px 10px;
    background-color: $notificationMessageBg;
    flex-wrap: wrap;
}

.c4_item.message.favorites {
    background-color: $notificationFavouriteBg;
}

.c4_item.blur {
    -webkit-filter: blur(2px);
    filter: blur(2px);
}

.c4_item_photo_wrap {
    position: relative;
    margin-right: 17px;
}

.c4_item.unknown_like .c4_item_photo_wrap {
    width: 100px;
    height: 100px;
    background-color: #e0e0e0;
    border-radius: 100%;
    flex: none;
    background-image: url(../../img/unknown-like-question.svg);
    background-position: calc(50% - 0px) calc(50% + 5px);
    background-repeat: no-repeat;
}

.c4_item .c2_item_photo_fav {
    display: block;
    top: 4px;
    right: 5px;
}

.c4_item .c2_item_photo_fav svg {
    stroke: #7878b4;
}

.c4_item.read .c2_item_photo_fav svg {
    stroke: #fff;
}

.c4_item.favorites .c2_item_photo_fav svg {
    stroke: #6f0267;
}

.c4_item.favorites.liked .c2_item_photo_fav svg {
    stroke: #fff;
}

.c4_item_photo_wrap:after {
    content: '';
    display: block;
    width: 11px;
    height: 11px;
    background-color: #e0e0e0;
    border-radius: 100%;
    border: 2px solid #fff;
    position: absolute;
    right: 6px;
    bottom: 6px;
}

.c4_item.clicked .c4_item_photo_wrap:after {
    right: 0;
    bottom: 0;
}

.online .c4_item_photo_wrap:after {
    background-color: $onlineColor;
}

.message .c4_item_photo_wrap:after {
    border-color: $notificationMessageBg;
}

.favorites .c4_item_photo_wrap:after {
    border-color: $notificationFavouriteBg;
}

.favorites.message .c4_item_name {
    color: $notificationFavouriteText;
}

.favorites .c4_item_clear_mask {
    background-color: $notificationFavouriteText;
}

.favorites.message .c4_item_text {
    color: $notificationFavouriteText;
}

.favorites .c4_place_small {
    color: $notificationFavouriteText;
}

.favorites.message .c4_item_time {
    color: $notificationFavouriteText;
}

.favorites.message.read .c4_item_name {
    color: $chatItemActiveTitleColor;
}

.favorites.read .c4_item_clear_mask {
    background-color: $chatItemActiveTitleColor;
}

.favorites.message.read .c4_item_text {
    color: $chatItemActiveTitleColor;
}

.favorites.read .c4_place_small {
    color: $chatItemActiveTitleColor;
}

.favorites.message.read .c4_item_time {
    color: $chatItemActiveTitleColor;
}

.c4_item.favorites .c2_item_photo_fav svg {
    stroke: #6f0267;
}

.c4_item.favorites.liked .c2_item_photo_fav svg {
    stroke: #fff;
}

.favorites.liked .c4_item_photo_wrap:after {
    border-color: #fff;
}

.c4_item_photo {
    width: 100px;
    height: 100px;
    border-radius: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    display: block;
}

.c4_item_big_photo {
    display: none;
    position: fixed;
    width: 188px;
    height: 305px;
    padding: 8px;
    background-color: $notifyBigPhotoBg;
    border-radius: 8px 0 0 8px;
    box-shadow: 0 4px 8px rgba(95, 95, 95, 0.24);
    z-index: 10;
    transform: translateX(calc(-100% + 5px));
}

.message.favorites .c4_item_big_photo {
    background-color: $notifyBigPhotoFavBg;
}

.c4_item_big_photo img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}

.c4_item_name {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #474f5c;
}

.message .c4_item_name {
    color: $chatItemActiveTitleColor;
}

.c4_item_text {
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
    color: #333333;
    margin-top: 10px;
}

.c4_item_text.green {
    color: #1c9d53;
    font-weight: bold;
}

.c4_item_subtext {
    color: #333;
    font-weight: 500;
    font-size: 12px;
}

.message .c4_item_text {
    color: $chatItemActiveTitleColor;
    -webkit-line-clamp: 2;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    max-height: 38px;
    display: -webkit-box;
    width: 170px;
    font-weight: normal;
}

.liked .c4_item_text {
    padding-left: 18px;
    position: relative;
}

.liked .c4_item_text:before {
    content: '';
    display: block;
    width: 12px;
    height: 11px;
    background-image: url(/img/c4-like.svg);
    background-repeat: no-repeat;
    position: absolute;
    left: 0;
    top: 3px;
}

.c4_item.is_online .c4_item_text {
    padding-left: 25px;
}

.c4_item.is_online .c4_item_subtext {
    padding-left: 25px;
}

.c4_item.is_online .c4_item_text:before {
    content: '';
    display: block;
    width: 17px;
    height: 11px;
    background-image: url(../../img/c4-online-ico.svg);
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    left: 0;
    top: 3px;
}

.c4_item.searching .c4_item_text {
    padding-left: 22px;
}

.c4_item.searching .c4_item_text:before {
    content: '';
    display: block;
    width: 14px;
    height: 14px;
    background-image: url(../../img/c4-searching-ico.svg);
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    left: 0;
    top: 2px;
}

.c4_item.appeal .c4_item_text {
    padding-left: 22px;
}

.c4_item.appeal .c4_item_text span {
    font-weight: bold;
    color: #4336de;
}

.c4_item.appeal .c4_item_text:before {
    content: '';
    display: block;
    width: 15px;
    height: 15px;
    background-image: url(../../img/c4-appeal-ico.svg);
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    left: 0;
    top: 2px;
}

.c4_item.no_chats .c4_item_text {
    padding-left: 22px;
}

.c4_item.no_chats .c4_item_text span {
    font-weight: bold;
    color: #4336de;
}

.c4_item.no_chats .c4_item_text:before {
    content: '';
    display: block;
    width: 16px;
    height: 16px;
    background-image: url(../../img/c4-no-chats-ico.svg);
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    left: 0;
    top: 2px;
}

.c4_item.want_chat .c4_item_text {
    padding-left: 22px;
}

.c4_item.want_chat .c4_item_text span {
    font-weight: bold;
    color: #00317b;
}

.c4_item.want_chat .c4_item_text:before {
    content: '';
    display: block;
    width: 16px;
    height: 16px;
    background-image: url(../../img/c4-want-chat-ico.svg);
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    left: 0;
    top: 2px;
}

.c4_item.unknown_like .c4_item_text {
    padding-left: 22px;
    font-size: 14px;
}

.c4_item.unknown_like .c4_item_text:before {
    content: '';
    display: block;
    width: 15px;
    height: 13px;
    background-image: url(../../img/c4-viwed.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: absolute;
    left: 0;
    top: 3px;
}

.c4_item.updated .c4_item_text {
    padding-left: 23px;
}

.c4_item.updated .c4_item_text:before {
    content: '';
    display: block;
    width: 14px;
    height: 14px;
    background-image: url(../../img/profile-ico-lightblue.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: absolute;
    left: 0;
    top: 2px;
}

.c4_item.joined .c4_item_text {
    padding-left: 24px;
}

.c4_item.joined .c4_item_text:before {
    content: '';
    display: block;
    width: 14px;
    height: 16px;
    background-image: url(../../img/pin-lightgreen.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: absolute;
    left: 0;
    top: 2px;
}

.c4_item.viewed_photos .c4_item_text {
    padding-left: 23px;
}

.c4_item.viewed_photos .c4_item_text:before {
    content: '';
    display: block;
    width: 15px;
    height: 15px;
    background-image: url(../../img/pic-orange.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: absolute;
    left: 0;
    top: 2px;
}

.c4_item.add_favs .c4_item_text {
    padding-left: 23px;
}

.c4_item.add_favs .c4_item_text:before {
    content: '';
    display: block;
    width: 17px;
    height: 16px;
    background-image: url(../../img/star-yellow.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: absolute;
    left: 0;
    top: 1px;
}

.c4_item.unknown_like .c4_item_text {
    margin-top: 0;
}

.viewed .c4_item_text {
    padding-left: 20px;
    position: relative;
}

.viewed .c4_item_text:before {
    content: '';
    display: block;
    width: 14px;
    height: 10px;
    background-image: url(/img/c4-viwed.svg);
    background-repeat: no-repeat;
    position: absolute;
    left: 0;
    top: 4px;
}

.c4_media_temp {
    /*display: none; !* delete this with class*!*/
}

.c4_item_text_media {
    display: flex;
    flex-wrap: wrap;
    font-size: 13px;
    color: #fff;
    margin-top: 3px;
}

.c4_item_activity_btn {
    width: 174px;
    height: 36px;
    background: $buttonBackgroundColor;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 0.01em;
    color: #ffffff;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    cursor: pointer;
    opacity: 0;
    pointer-events: none;
    transition:
        opacity 0.5s,
        box-shadow 0.3s;
}

.c4_item_go_to_chat {
    width: 190px;
    height: 36px;
    background: $buttonBackgroundColor;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 0.01em;
    color: #ffffff;
    cursor: pointer;
    transition:
        opacity 0.5s,
        box-shadow 0.3s;
    margin: 10px auto 0;
}

.c4_item.message:not(.clicked):hover .c4_item_go_to_chat {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
}

.c4_item_go_to_chat img {
    margin-right: 12px;
}

.c4_item_match .c4_item_activity_btn {
    width: 100px;
    top: calc(50% + 14px);
    right: 10px;
    transition:
        opacity 0.7s,
        box-shadow 0.3s,
        width 0.5s,
        right 0.5s;
    white-space: nowrap;
}

.c4_item_activity_btn .small {
    display: none;
}

.c4_item_activity_btn img {
    width: 16px;
    margin-right: 10px;
}

.c4_item.unknown_like .c4_item_activity_btn img {
    width: 10px;
}

.c4_item_activity_btn:hover {
    box-shadow: 0 6px 12px rgba(84, 0, 114, 0.24);
}

.c4_item.activity:hover .c4_item_activity_btn,
.c4_item_match:hover .c4_item_activity_btn {
    opacity: 1;
    pointer-events: unset;
}

.c4_item_match:hover .c4_item_activity_btn {
    width: 174px;
    right: 35px;
}

.c4_item.activity .c4_item_right {
    transition: opacity 0.5s;
}

.c4_item.activity:hover .c4_item_right {
    pointer-events: none;
    opacity: 0;
}

.c4_item.activity .c4_item_text {
    position: relative;
}

.c4_item_match {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 3px 5px rgba(14, 4, 143, 0.15);
    position: relative;
    margin-bottom: 8px;
    padding: 14px 15px;
}

.c4_item_match:hover {
    z-index: 99;
}

.c4_item_match_title {
    font-weight: 500;
    color: #474f5c;
    margin-bottom: 12px;
    transition: 0.3s;
    width: max-content;
}

.c4_item_match_row {
    display: flex;
    align-items: center;
}

.c4_item_match_ico {
    width: 16px;
    height: 16px;
    background-image: url(../../img/c4-match-ico.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    margin: 0 8px;
    transition: width 0.5s;
}

.c4_item_match_man_img,
.c4_item_match_woman_img {
    width: 62px;
    height: 62px;
    border-radius: 100%;
    display: block;
    object-fit: cover;
    object-position: top;
    transition:
        width 0.5s,
        height 0.5s;
}

.c4_item_match_woman_img_wrap {
    position: relative;
    transition: margin-left 0.5s;
}

.c4_item_match_woman_img_wrap:after {
    content: '';
    display: block;
    width: 9px;
    height: 9px;
    background-color: #e0e0e0;
    border-radius: 100%;
    border: 1px solid #fff;
    position: absolute;
    right: 3px;
    bottom: 3px;
}

.online .c4_item_match_woman_img_wrap:after {
    background-color: #27ae60;
}

.c4_item_match_woman {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.c4_item_match_woman_name {
    font-weight: 600;
    color: #333;
    margin-left: 12px;
    transition: opacity 0.5s;
}

.c4_item_match:hover .c4_item_match_man_img,
.c4_item_match:hover .c4_item_match_ico {
    width: 0;
    height: 0;
    margin: 0;
}

.c4_item_match:hover .c4_item_match_woman_name {
    opacity: 0;
}

.c4_item:hover .c4_item_text_media {
    width: 100%;
}

.c4_item.read .c4_item_text_media {
    color: #333;
}

.c4_item_text_media span {
    width: 100%;
    margin-bottom: 5px;
}

.c4_item_text_media_photo {
    width: 53px;
    height: 35px;
    object-fit: cover;
    border-radius: 4px;
}

.c4_item_text_media_video {
    position: relative;
}

.c4_item_text_media_video img {
    width: 65px;
    height: 33px;
    object-fit: cover;
    border-radius: 4px;
    display: block;
}

.c4_item_text_media_video video {
    width: 65px;
    height: 33px;
}

.c4_item_text_media_video:after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 4px;
    background:
        linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)),
        url(../../img/play-video-small.svg) no-repeat center;
}

.c4_item_text_media_audio {
    width: 134px;
    height: 29px;
    background-position: left center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(../../img/c4-audio-white.svg);
}

.c4_item.read .c4_item_text_media_audio {
    background-image: url(../../img/c4-audio-dark.svg);
}

.c4_item_text_letter {
    margin-top: 4px;
}

.c4_item:hover .c4_item_text_letter {
    width: 100%;
    margin-top: 40px;
}

.c4_item_text_letter_title {
    position: relative;
    font-weight: 500;
    font-size: 12px;
    color: $chatItemActiveTitleColor;
    padding-left: 20px;
    margin-bottom: 4px;
    display: flex;
    align-items: center;
}

.c4_item_text_letter_title:before {
    content: '';
    display: block;
    width: 15px;
    height: 12px;
    position: absolute;
    left: 0;
    top: 3px;
    mask: url(../../img/c4-letter.svg);
    mask-size: 15px 12px;
    background-color: $chatItemActiveTitleColor;
}

.c4_item_text_letter_text {
    font-size: 12px;
    line-height: 1.5;
    color: $chatItemActiveTitleColor;
    max-width: 153px;
    -webkit-line-clamp: 2;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    max-height: 38px;
    display: -webkit-box;
    padding-left: 20px;
}

.c4_item_text_letter_text span {
    color: $chatItemActiveTitleColor;
    font-weight: 500;
    white-space: nowrap;
}

.c4_item:hover .c4_item_text_letter_text {
    height: auto;
    overflow: visible;
    max-width: unset;
}

.c4_item_clear {
    position: absolute;
    top: 7px;
    right: 7px;
    cursor: pointer;
    padding: 5px;
}

.c4_item_clear img {
    display: block;
}

.c4_item_clear_mask {
    position: absolute;
    top: 7px;
    right: 7px;
    cursor: pointer;
    padding: 5px;
    mask: url('../../../src/img/c2-remove-fav.svg');
    mask-size: 9px;
    mask-repeat: no-repeat;
    background-color: $chatItemActiveTitleColor;
}

.c4_item_time {
    position: absolute;
    right: 11px;
    bottom: 8px;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.03em;
    /*text-transform: uppercase;*/
    color: rgba(51, 51, 51, 0.6);
}

.message .c4_item_time {
    color: $chatItemActiveTitleColor;
    opacity: 0.8;
}

.c4_place_small {
    display: none;
    font-size: 12px;
    letter-spacing: -0.03em;
    color: $chatItemActiveTitleColor;
    opacity: 0.8;
    margin-top: 10px;
}

.c4_place_small img {
    vertical-align: middle;
    height: 12px;
    margin-bottom: 2px;
    margin-right: 5px;
}

.c4_item_reply {
    display: none;
    position: absolute;
    right: 8px;
    bottom: 8px;
    cursor: pointer;
    align-items: center;
    padding: 7px 8px;
    transition: background-color 0.3s;
    border-radius: 4px;
}

.c4_item_reply:hover {
    background-color: rgba(255, 255, 255, 0.3);
}

.c4_item_reply_text {
    font-size: 12px;
    letter-spacing: -0.01em;
    color: #ffffff;
    margin-left: 9px;
}

.c4_item.message.read .c4_item_reply_text {
    color: #00317b;
}

.c4_item_reply_info {
    position: absolute;
    right: calc(100% + 5px);
    top: 50%;
    transform: translateY(-50%);
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.01em;
    color: #fff;
    white-space: nowrap;
    display: none;
}

.c4_item_reply:hover .c4_item_reply_text {
    display: flex;
}

.c4_item.message:not(.clicked) {
    //height: 120px;
    transition: 0.5s;
    padding-bottom: 30px;
}

.c4_item.message:not(.clicked):hover {
    display: block;
    padding-bottom: 56px;
    height: 305px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}

.tutorial_step_6_notif_list_global .c4_item {
    pointer-events: none;
    margin-bottom: 10px;
}

.tutorial_step_6_notif_list_global .c4_item:last-child {
    margin-bottom: 0;
}

.c4_item.message:not(.clicked):hover .c4_place_small {
    display: block;
}

.c4_item.message:not(.clicked):hover .c4_item_text {
    height: auto;
    width: 100%;
    margin-top: 24px;
    font-size: 13px;
    max-height: unset;
}

.c4_item.message:not(.clicked):hover .c4_item_photo_wrap {
    float: left;
    margin-bottom: 10px;
}

.c4_item.message:not(.clicked):hover .c4_item_name {
    margin-top: 25px;
}

.c4_item.message:not(.clicked):hover .c4_item_time {
    top: 10px;
    right: 33px;
    height: 16px;
}

.c4_item.message:not(.clicked):hover .c4_item_reply {
    display: flex;
}

.c4_item_photo_wrap:hover + .c4_item_big_photo {
    display: block;
}

.c4_item.message.read {
    background: $chatItemActiveBg;
}

.c4_item.message.read .c4_item_big_photo {
    background: $chatItemActiveBg;
}

.read .c4_item_photo_wrap:after {
    border-color: $chatItemActiveBg;
}

.c4_item.read .c2_item_photo_fav svg {
    stroke: #fff;
}

.c4_mute {
    padding: 16px 0 12px 6px;
}

.c4_mute_btn {
    width: 261px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #446077;
    box-shadow: 0 0 8px rgba(14, 4, 143, 0.24);
    border-radius: 5px;
    font-weight: 600;
    font-size: 12px;
    color: #446077;
    cursor: pointer;
}

.c4_mute_btn img {
    margin-right: 10px;
}

.c4_bottom_buttons {
    position: absolute;
    bottom: 8px;
    right: -119px;
    z-index: 999;
    transition: 0.3s;
}

.c4_bottom_buttons:hover {
    right: 0;
}

.c4_support {
    background-color: #f7f8fd;
    width: 170px;
    height: 52px;
    box-shadow: $smallBtnShadowHover;
    border-radius: 5px 0 0 5px;
    display: flex;
    align-items: center;
    transition: 0.3s;
    cursor: pointer;
    padding-left: 16px;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: $violetBlueColor;
}

.c4_support img {
    margin-right: 28px;
}

.c4_support svg {
    margin-right: 28px;
    width: 28px;
    height: 28px;
    fill: #730067;
}

.c4_how_to_start_btn,
.c4_whatsApp_btn {
    background-color: #f7f8fd;
    width: 170px;
    height: 52px;
    box-shadow: $smallBtnShadowHover;
    border-radius: 5px 0 0 5px;
    display: flex;
    align-items: center;
    transition: 0.3s;
    cursor: pointer;
    padding-left: 18px;

    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: $violetBlueColor;
    position: relative;
    right: -59px;

    margin-bottom: 8px;
}

.c4_bottom_buttons:hover .c4_how_to_start_btn,
.c4_bottom_buttons:hover .c4_whatsApp_btn {
    right: 0;
}

.c4_how_to_start_btn img,
.c4_whatsApp_btn img {
    margin-right: 16px;
}

.c3_head {
    height: 80px;
    background-color: $primaryColor;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 10px 26px 15px 19px;
}

.c3_head_left {
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.c3_head_photo_wrap {
    margin-right: 11px;
    display: block;
    height: 65px;
    width: 75px;
    padding-top: 7px;
}

.c3_head_photo {
    position: relative;
    cursor: pointer;
}

.c3_head_photo:hover {
    background-color: #fff;
    position: relative;
    padding-bottom: 10px;
    margin-top: -10px;
    padding-top: 10px;
    -webkit-box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    z-index: 3;
}

.c3_head_photo_bottom {
    display: none;
    font-weight: 500;
    font-size: 11px;
    line-height: 118%;
    color: $notificationFavouriteBg;
    -ms-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-top: 12px;
}

.c3_head_photo:hover .c3_head_photo_bottom {
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.c3_head_photo_bottom img {
    width: 16px;
    margin-right: 6px;
}

.c3_head_photo_bottom svg {
    margin-right: 6px;
    fill: $iconProfileFillColor;
    width: 16px;
    height: 16px;
    vertical-align: middle;
}

.c3_head_photo > img {
    width: 56px;
    height: 56px;
    display: block;
    border-radius: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    margin: auto;
}

.c3_head_photo:after {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    background-color: #e0e0e0;
    border-radius: 100%;
    border: 2px solid $chatHeaderOnlineBorder;
    position: absolute;
    right: 7px;
    top: 39px;
}

.c3_head_photo:hover:after {
    top: 46px;
    border-color: #fff;
}

.c3_head_photo_wrap.online .c3_head_photo:after {
    background-color: $onlineColor;
}

.c3_head_name {
    grid-area: c2_bottom_name;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #ffffff;
    margin-bottom: 4px;
    cursor: pointer;
}

.liked .c3_head_name:after {
    content: '';
    display: inline-block;
    width: 11px;
    height: 9px;
    background-image: url(/img/c3-like.svg);
    margin-left: 11px;
    position: relative;
    top: -2px;
}

.c3_head_name_bottom {
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.c3_head_id {
    grid-area: c2_bottom_id;
    font-size: 12px;
    line-height: 12px;
    color: #bdbdbd;
}

.c3_head_place {
    grid-area: c2_bottom_place;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-left: 11px;
}

.c3_head_place img {
    height: 12px;
    display: block;
    margin-right: 4px;
    border-radius: 2px;
}

.c3_head_place span {
    line-height: 1;
    font-size: 12px;
    letter-spacing: -0.03em;
    color: #bdbdbd;
}

.c3_head_right {
    margin-top: 5px;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

@media screen and (min-width: 600px) {
}

.c3_options {
    height: 50px;
    background-color: #fff;
    -webkit-box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 0 25px 0 29px;
    position: relative;
}

.c3_options_item {
    line-height: 1;
    font-weight: 500;
    font-size: 16px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    letter-spacing: 0.01em;
    color: #474f5c;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    cursor: pointer;
    transition-duration: 0.3s;
}

.c3_options_gift {
    position: relative;

    svg {
        width: 25px;
        height: 25px;
        min-width: 25px;
        min-height: 25px;
        fill: #474f5c;
        transition-duration: 0.3s;

        @media screen and (max-width: 599px) {
            width: 14px;
            height: 14px;
            min-width: 14px;
            min-height: 14px;
        }
    }

    &:disabled {
        color: #999;

        svg {
            fill: #999;
        }
    }

    &:disabled:hover {
        color: #333;

        svg {
            fill: #333;
        }

        .c3_woman_profile_gift_btn_tip {
            visibility: visible;
            opacity: 1;
        }
    }
}

.c3_options_gift .c3_options_gift_discounts {
    width: 44px;
    height: 24px;
    padding: 8px;
    border-radius: 4px;
    background-color: #EB5757;
    color: #FFFFFF;
    font-size: 12px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 18px;
}

.c3_options_gift .c3_woman_profile_gift_btn_tip {
    top: 130%;

    @media screen and (max-width: 767px) {
        left: 0;
        right: unset;
        font-size: 10px;

        strong {
            font-size: 11px;
        }

        &:before {
            top: -4px;
            left: 16px;
        }
    }
}

.c3_options_item.disabled {
    color: #999;
    cursor: auto;
}

.c3_options_item.disabled img {
    opacity: 0.6;
}

.c3_options_item img {
    display: block;
    margin-right: 18px;
    max-width: 25px;
    max-height: 25px;
}

.c3_options_item svg {
    width: 20px;
    height: 20px;
    max-width: 25px;
    max-height: 25px;
    display: block;
    margin-right: 18px;
    fill: #484f5c;
}

.c3_options_item.c3_options_item_contact_request svg {
    width: 24px;
    height: 24px;
    max-width: 25px;
    max-height: 25px;
    display: block;
    fill: #484f5c;
}

.c3_options_item.webacm {
    position: relative;
    padding-right: 34px;
    display: none;
}

.c3_options_item.webacm:after {
    content: '';
    display: block;
    width: 12px;
    height: 8px;
    position: absolute;
    right: 0;
    top: calc(50% - 4px);
    background-image: url(/img/bottom-grey.svg);
    -webkit-background-size: contain;
    background-size: contain;
    background-repeat: no-repeat;
}

.c3_options_item.media {
    height: 100%;
    position: relative;
}

.c3_options_item.media.active {
    color: $accentLeftRightColor;
    font-weight: 600;
}

.c3_options_item.media > svg {
    max-width: 25px;
    height: 25px;
    stroke: #474f5c;
    margin-right: 12px;
    flex: none;
}

.c3_options_item.media.active > svg {
    stroke: $accentLeftRightColor;
}

.c3_options_item.media.active {
}

.c3_options_item_media_close {
    width: 16px;
    height: 16px;
    background-image: url(../../img/c3-media-close-tab.svg);
    background-size: 14px;
    background-repeat: no-repeat;
    background-position: center;
    display: none;
    margin-left: 17px;
    margin-right: 5px;
}

.c3_options_item.media.active .c3_options_item_media_close {
    display: block;
}

.c3_options_item_border {
    width: 100%;
    height: 3px;
    background-color: $accentLeftRightColor;
    position: absolute;
    bottom: 0;
    left: 0;
    border-radius: 3px;
    display: none;
}

.c3_options_item.media.active .c3_options_item_border {
    display: block;
}

.c3_chat_wrap {
    height: 100%;
    flex: 1;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    -o-flex-direction: column;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    overflow-y: hidden;
}

.c3_chat_wrap.media_open {
    position: relative;
}

.c3_chat_wrap.media_open:after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    background: rgba(55, 78, 108, 0.32);
    backdrop-filter: blur(4px);
    z-index: 5;
}

.c3_chat_wrap.media_open:after {
    display: block;
}

.c3_chat_scroll {
    height: 100%;
    flex: 1;
    overflow-y: auto;
    margin-right: 7px;
    position: relative;
}

@mixin holiday-chat($name, $image-path, $image-mobile-path) {
    .c3_chat_scroll.#{$name} {
        background-image: url(#{$image-path});
        background-size: 100%;
        background-position: top center;
        background-repeat: no-repeat, no-repeat;

        @media screen and (max-width: 599px) {
            background-image: url(#{$image-mobile-path});
        }
    }
}

@include holiday-chat('halloween', '../../img/holiday/halloween_chat.svg', '../../img/holiday/halloween_chat_mobile.svg');
@include holiday-chat('lunar_new_year', '../../img/holiday/lunar_new_year_chat.svg', '../../img/holiday/lunar_new_year_chat_mobile.svg');
@include holiday-chat('thanksgiving_day', '../../img/holiday/thanksgiving_day_chat.svg', '../../img/holiday/thanksgiving_day_chat_mobile.svg');
@include holiday-chat('christmas', '../../img/holiday/christmas_chat.svg', '../../img/holiday/christmas_chat_mobile.svg');
@include holiday-chat('woman_day', '../../img/holiday/woman_day_chat.svg', '../../img/holiday/woman_day_chat_mobile.svg');
@include holiday-chat('valentine_day', '../../img/holiday/valentine_day_chat.svg', '../../img/holiday/valentine_day_chat_mobile.svg');
@include holiday-chat('independence_day', '../../img/holiday/independence_day_chat.svg', '../../img/holiday/independence_day_chat_mobile.svg');
@include holiday-chat('easter', '../../img/holiday/easter_chat.svg', '../../img/holiday/easter_chat_mobile.svg');

.c3_chat_go_to_new_message {
    left: 100%;
    width: 70px;
    height: 70px;
    transform: translateX(-30%);
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: sticky;
    bottom: 14px;
    background: linear-gradient(0deg, rgba(68, 96, 119, 0.88), rgba(68, 96, 119, 0.88));
    color: #fff;
    font-size: 16px;
    z-index: 3;
    cursor: pointer;
}

.c3_chat_go_to_new_message img {
    width: 20px;
}

.c3_chat_go_to_new_message svg {
    fill: #ffffff;
}

.c3_chat_go_to_new_message_counter {
    position: absolute;
    top: -2px;
    text-align: center;
    width: 50px;
    height: 20px;
    background-color: $chatNewBg;
    border-radius: 4px;
    font-size: 12px;
    color: #fff;
    letter-spacing: -0.02em;
    line-height: 20px;
    font-weight: bold;
    margin-left: auto;
}

//
//.c3_chat_go_to_new_message img{
//	margin-right: 12px;
//}

.c3_chat_scroll.block_list {
    height: calc(100vh);
    height: calc(calc(var(--vh, 1vh) * 100));
}

.c3_chat_wrap .c3_chat_scroll::-webkit-scrollbar {
    width: 3px;
    background: #f4f4f4;
    border-radius: 5px;
    margin-right: 3px;
}

.c3_chat_wrap .c3_chat_scroll::-webkit-scrollbar-thumb {
    background: $scrollbarThumbColor;
    border-radius: 7px;
}

.c3_chat {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-height: 100%;
    padding: 20px 17px 20px 24px;
}

.c3_chat.empty {
    height: 100%;
    padding: 0 0 0 7px;
}

.c4_minichat .c3_chat {
    padding: 20px;
}

.chat_message_wrap {
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 20px;
}

.chat_message_wrap:last-child {
    margin-bottom: 0;
}

.chat_message_wrap.right {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.chat_message_wrap.right .chat_message {
    justify-content: flex-end;
}

.chat_message {
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-align-items: flex-end;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    width: 90%;
}

.message_avatar {
    margin-bottom: 15px;
}

.chat_message_wrap.right .message_avatar {
    margin-left: 10px;
}

.chat_message_wrap.left .message_avatar {
    margin-right: 10px;
}

.message_avatar img {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    object-position: top;
}

.message_avatar div {
    width: 40px;
    height: 40px;
    -o-object-fit: cover;
    object-fit: cover;
    padding-left: 2px;
    padding-top: 2px;
    line-height: 38px;
    border-radius: 60px;
    text-align: center;
    color: white;
    font-size: 16px;
}

.message_text {
    font-size: 16px;
    line-height: 140%;
    color: #333333;
    padding: 16px;
    border-radius: 8px;
    margin-bottom: 5px;
    word-break: break-word;
    position: relative;
    cursor: pointer;

    &:hover {
        .message_text--overlay .need_pay {
            opacity: 1;
            z-index: 2;
            height: 22px;
            transition: all 0.3s ease;
        }
    }
}

.message_text--overlay {
    display: flex;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: transparent;
    gap: 17px;

    @media (max-width: $mobileBreakpoint) {
        flex-wrap: nowrap;
        white-space: nowrap;
    }
}

.message_text--overlay .need_pay {
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    opacity: 0;
    z-index: -1;
    transition: all 0.3s ease;
    height: 0;
    white-space: nowrap;

    @media (max-width: $mobileBreakpoint) {
        opacity: 1;
        z-index: 2;
        height: 22px;
        transition: all 0.3s ease;
    }
}

.message_text--overlay .need_pay span {
    position: relative;
    padding-left: 26px;
}

.message_text--overlay .need_pay span:before {
    content: url('../../img/icon_c.svg');
    position: absolute;
    top: -1px;
    left: 0;
}

.c4_minichat .message_text {
    padding: 17px;
}

.message_bottom {
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.message_date {
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.03em;
    /*text-transform: uppercase;*/
    color: #bdbdbd;
}

.chat_message_wrap.left .message_content {
    /*margin-left: 0px;
	margin-right: 56px;*/
}

.chat_message_wrap.right > .disabled,
.chat_message_wrap.left > .disabled {
    display: none;
}

.chat_message_wrap.right.disabled,
.chat_message_wrap.left.disabled {
    margin-bottom: 0 !important;
}

.chat_message_wrap.left .message_text {
    background-color: $messageLeftBackground;
    border-radius: 8px 8px 8px 0;
}

.chat_message_wrap.right .message_content {
    /*margin-left: 56px;
	margin-right: 16px;*/
}

.chat_message_wrap.right .message_text {
    background-color: $messageRightBackground;
    border-radius: 8px 8px 0 8px;
}

.chat_message_wrap.right .message_text.wink {
    background-color: transparent;
}

.chat_message_wrap.sticker .message_text {
    background-color: transparent;
    padding: 0;
}

.chat_message_wrap.sticker .message_text img {
    width: 285px;
    height: 285px;

    -o-object-fit: contain;
    object-fit: contain;
}

.chat_message_wrap.wink .message_text {
    background-color: transparent;
    padding: 0;
}

.chat_message_wrap.wink .message_text img {
}

.message_status {
    width: 19px;
    height: 12px;
    -webkit-background-size: contain;
    background-size: contain;
    background-repeat: no-repeat;
}

.message_status.send {
    margin-left: 10px;
    background-image: $messageStatusSend;
}

/*.c2_new_item_big > .message_status.send{
	margin-left: 10px;
	position: absolute;
	right: 58px;
	top: 8px;
	background-image: url(/img/send.svg);
}*/
img.item_big_favs_star {
    position: absolute;
    top: 0;
    right: 0;
    width: 20px;
}

.message_status.readed {
    margin-left: 10px;
    background-image: $messageStatusRead;
}

.message_img {
    display: block;
    width: auto;
    height: auto;
    object-fit: contain;
    border-radius: 8px;
    max-width: 350px;
    max-height: 350px;
}

.message_reply_text {
    font-style: italic;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    padding-left: 8px;
    border-left: 2px solid #c2cfe0;
    width: 200px;
    min-width: 100%;
    height: 26px;
    overflow: hidden;
    -ms-text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 10px;
}

.message_video_wrap span.need_pay,
.message_audio_wrap span.need_pay {
    font-size: 11px;
    line-height: 130%;
    color: rgba(51, 51, 51, 0.3);
    margin-bottom: 7px;
    display: block;
}

.message_video {
    max-width: 200px;
    position: relative;
    cursor: pointer;
}

.message_video img {
    width: 100%;
    height: 100%;
    margin-top: 0;
    -o-object-fit: cover;
    object-fit: cover;
}

.message_video:after {
    z-index: 2;
    content: '';
    display: block;
    width: 51px;
    height: 52px;
    position: absolute;
    top: calc(50% - 26px);
    left: calc(50% - 26px);
    background-image: url(../../img/play-btn.svg);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.message_video_without_play {
    max-width: 200px;
    position: relative;
    cursor: pointer;

    &:hover {
        & span.need_pay {
            display: block;
        }
    }
}

.message_video_without_play img {
    width: 100%;
    height: 100%;
    margin-top: 0;
    -o-object-fit: cover;
    object-fit: cover;
}

.message_video_without_play span.need_pay {
    display: none;
    margin-top: 10px;
    font-size: 16px;
    line-height: 130%;
    color: rgba(255, 255, 255);
    margin-bottom: 7px;
}

.message_video_status {
    margin-top: 6px;
}

.message_text .message_video_status {
    margin-top: 11px;
}

.message_video_status.paid {
    font-weight: 500;
    font-size: 11px;
    line-height: 130%;
    color: #426076;
    text-align: right;
}

.message_video_status.viewed {
    text-align: right;
    font-size: 11px;
    line-height: 130%;
    color: rgba(51, 51, 51, 0.6);
    position: relative;
}

.message_video_status.viewed span:before {
    content: '';
    display: inline-block;
    width: 14px;
    height: 9px;
    background-image: url(/img/video-viewed.svg);
    background-repeat: no-repeat;
    margin-right: 8px;
    margin-bottom: -1px;
}

.chat_new_line_wrap {
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 20px;
}

.chat_new_line {
    height: 1px;
    background-color: #c2cfe0;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.chat_new_text {
    font-weight: 500;
    font-size: 16px;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    color: rgba(235, 87, 87, 0.72);
    margin: 0 16px;
}

.c3_smiles_list {
    width: 371px;
    //height: 260px;
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    padding-right: 5px;
}

.c3_smiles_list::-webkit-scrollbar {
    width: 4px;
    background: #fff;
    border-radius: 3px;
}

.c3_smiles_list::-webkit-scrollbar-thumb {
    background: #00317b;
    border-radius: 3px;
}

.c3_smiles_list_item {
    width: 52px;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.c3_smiles_list_item img {
    width: 38px;
    display: block;
}

.c3_sticker_wrap {
    position: relative;
}

.c3_stickers_list_rows_wrap {
    padding-right: 10px;
    display: none;
    margin: 7px 0;
    max-height: 345px;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 3px;
        background: #fff;
        border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb {
        background: #00317b;
        border-radius: 3px;
    }
}

.c3_sticker_wrap.active .c3_stickers_list_rows_wrap {
    display: block;
}

.c3_stickers_list_wrap.active .c3_stickers_list_rows_wrap {
    display: block;
}

.c3_sticker_wrap.active .c3_stickers_list_rows_wrap.hide {
    display: none;
}

.c3_stickers_list_open {
    display: none;
}

.c3_stickers_list_open.active {
    display: block;
}

.c3_stickers_list_row + .c3_stickers_list_row {
    margin-top: 23px;
}

.c3_stickers_list_head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    padding-top: 16px;
}

.c3_stickers_list_head_title {
    font-weight: 600;
    color: #446077;
}

.c3_stickers_list_head_price {
    font-size: 10px;
    color: rgba(51, 51, 51, 0.3);
}

.c3_stickers_list {
    display: flex;
    align-items: center;
}

.c3_stickers_list_item {
    width: 94px;
    height: 94px;
    margin-right: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;
}

.c3_stickers_list_item:hover {
    background-color: #d3daff;
}

.c3_stickers_list_item img {
    width: 90px;
    height: 90px;
    object-fit: contain;
    display: block;
}

.c3_stickers_list_open_head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 42px;
    border-bottom: 1px solid rgba(51, 51, 51, 0.1);
    margin-right: 7px;
    padding-top: 3px;
}

.c3_stickers_list_open_back_btn {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 11px;
    color: #6f0267;
    cursor: pointer;
    flex: 1;
}

.c3_stickers_list_open_back_btn img {
    margin-right: 9px;
}

.c3_stickers_list_open_back_btn svg {
    fill: #6f0267;
    stroke: #6f0267;
    stroke-width: 0.5;
    width: 7px;
    height: 11px;
    margin-right: 9px;
}

.c3_stickers_list_open_title {
    font-weight: 600;
    font-size: 16px;
    color: #446077;
    flex: 2;
}

.c3_stickers_list_open_price {
    font-size: 10px;
    text-align: right;
    color: rgba(51, 51, 51, 0.3);
    width: 76px;
    flex: 1;
}

.c3_stickers_list_open_list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 10px;
    width: 410px;
    height: 280px;
    overflow-y: auto;
    margin-bottom: 12px;
}

.c3_stickers_list_open_list::-webkit-scrollbar {
    width: 3px;
    background: #fff;
    border-radius: 3px;
}

.c3_stickers_list_open_list::-webkit-scrollbar-thumb {
    background: #00317b;
    border-radius: 3px;
}

.c3_stickers_list_all_btn {
    width: 49px;
    height: 29px;
    line-height: 29px;
    border: 1px solid #446077;
    border-radius: 6px;
    font-weight: 600;
    font-size: 12px;
    color: #446077;
    margin-left: 2px;
    cursor: pointer;
}

.c3_smile_btn img {
    fill: $accentRightColor;
    width: 28px;
    height: 28px;
    display: block;
}

.c3_smile_btn_wrap.active .c3_sticker_btn {
    display: block;
}

.c3_smile_btn.active {
    background-color: #f4f4f4;
    padding: 10px 15px;
}

.c3_sticker_btn.active {
    background-color: #f4f4f4;
    padding: 10px 15px;
}

.c3_smile_window_wrap {
    display: none;
    background-color: #f4f4f4;
    padding: 10px 13px;
    position: absolute;
    right: 100%;
    bottom: 56px;
    border-radius: 4px 4px 0px 4px;
}

.smile .c3_smile_window_wrap {
    display: block;
}

.c3_sticker_window_wrap {
    display: none;
    background-color: #f4f4f4;
    padding: 10px 13px;
    position: absolute;
    right: 100%;
    bottom: 56px;
    border-radius: 4px 4px 0px 4px;
}

.stiker .c3_sticker_window_wrap {
    display: block;
}

.c3_sticker_window_wrap img {
    width: 345px;
}

.c4_minichat {
    position: absolute;
    top: 0;
    right: calc(100% - 5px);
    height: 100%;
    width: 440px;
    background-color: #fff;
    z-index: 6;
    display: none;
    -webkit-box-shadow: -5px 0px 5px rgba(0, 0, 0, 0.1);
    box-shadow: -5px 0px 5px rgba(0, 0, 0, 0.1);
    -webkit-border-radius: 10px 0 0 10px;
    -moz-border-radius: 10px 0 0 10px;
    border-radius: 10px 0 0 10px;
    /*overflow: hidden;*/
}

.c4_minichat .c3_chat_wrap {
    height: calc(100vh - 80px);
    height: calc(calc(var(--vh, 1vh) * 100) - 80px);
    overflow: visible;
}

.c4_minichat .c3_chat_scroll {
    height: calc(100vh - 297px);
    height: calc(calc(var(--vh, 1vh) * 100) - 297px);
}

.c4_minichat .c3_chat_scroll::-webkit-scrollbar {
    width: 3px;
    background: #f4f4f4;
    border-radius: 5px;
    margin-right: 3px;
}

.c4_minichat .c3_chat_scroll::-webkit-scrollbar-thumb {
    background: $scrollbarThumbColor;
    border-radius: 7px;
}

.close_minichat {
    width: 16px;
    height: 16px;
    cursor: pointer;
}

.c4_minichat .c3_head {
    padding-left: 30px;
}

.c4_minichat .chat_message_wrap.sticker .message_text img {
    width: 285px;
    height: 285px;
}

.c4_minichat .c3_write_msg {
    -ms-flex-wrap: wrap;
    -o-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 24px 20px 21px;
    height: auto;
}

.c4_minichat .c3_textaera_wrap {
    width: 100%;
    -webkit-box-flex: 1;
    -ms-flex: auto;
    flex: auto;
    margin-bottom: 24px;
}

.c3_write_msg_left {
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.fancybox-content {
    padding: 24px;
}

.fancybox-inner {
    height: 100% !important;
}

.react-tabs {
    padding: 24px;
}

.upload_popup_wrap {
    display: flex;
    width: 845px;
    background-color: rgba(255, 255, 255, 0);
    /*-webkit-box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);*/
    /*box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);*/
    border-radius: 8px;
    max-width: 100%;
    z-index: 100;

    @media screen and (max-width: 880px) {
        width: calc(100vw - 32px);
        margin: auto;
    }
}

.upload_popup_tabs_ul {
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-bottom: 1px solid #c2cfe0;
}

.upload_popup_tabs_ul li {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    text-align: center;
    outline: none;
    list-style-type: none;
    cursor: pointer;
}

.upload_popup_tabs_ul li {
    display: block;
    height: 35px;
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    text-align: center;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    color: rgba(55, 78, 108, 0.56);
    outline: none;
    border-bottom: 2px solid transparent;
    cursor: pointer;
}

.upload_popup_tabs_ul li.ui-tabs-active {
    border-color: $accentRightColor;
    color: $accentRightColor;
}

.upload_popup_tabs_content_top {
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-top: 18px;
    padding-bottom: 18px;
}

.upload_popup_tabs_content_top .cost {
    font-size: 11px;
    color: rgba(51, 51, 51, 0.3);
}

.upload_popup_tabs_content_top .sort {
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.01em;
    color: #02317c;
}

.upload_popup_tabs_content_top .sort img {
    vertical-align: middle;
    margin-right: 9px;
}

.upload_popup_tabs_content_middle {
    margin-bottom: 16px;
}

.upload_popup_tabs_content_middle.photo {
    display: grid;
    grid-template-columns: repeat(auto-fill, 120px);
    grid-gap: 8px;
}

.upload_popup_tabs_content_middle.video {
    display: grid;
    grid-template-columns: repeat(auto-fill, 152px);
    grid-gap: 8px;
}

.upload_popup_tabs_content_middle.photo::-webkit-scrollbar,
.upload_popup_tabs_content_middle.video::-webkit-scrollbar {
    width: 3px;
    background: #f7f8fd;
    margin-right: 3px;
}

.upload_popup_tabs_content_middle.photo::-webkit-scrollbar-thumb,
.upload_popup_tabs_content_middle.video::-webkit-scrollbar-thumb {
    background: rgba(2, 49, 124, 0.6);
}

.upload_popup_tabs_content_item {
    border-radius: 4px;
    background-color: $uploadTabsItemBg;
}

.upload_popup_tabs_content_audio_item {
    background-color: $uploadTabsItemBg;
    border-radius: 8px;
    height: 114px;
    padding: 16px;
    margin-bottom: 8px;
}

.upload_popup_tabs_content_item_top input {
    display: none;
}

.upload_popup_tabs_content_item_top label.video_label {
    overflow: hidden;
    z-index: 1;
}

.upload_popup_tabs_content_item_top label {
    position: relative;
    display: block;
    cursor: pointer;
    overflow: hidden;
    width: 100%;
    height: 80px;
}

.upload_popup_tabs_content_item_top label:after {
    content: '';
    display: block;
    width: 16px;
    height: 16px;
    min-width: 16px;
    border-radius: 4px;
    position: absolute;
    top: 6px;
    right: 6px;
    background-color: #fff;
    border: 1px solid #ced6e0;
    z-index: 10;
}

.upload_popup_tabs_content_item_top input:checked + label:after {
    background-image: url(/img/popup-checked.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-color: #9c6ef7;
    border-color: #9c6ef7;
}

.upload_popup_tabs_content_item_top label img {
    border-radius: 4px;
    display: block;
    -o-object-fit: cover;
    object-fit: cover;
    object-position: center;
}

.photo .upload_popup_tabs_content_item_top label img {
    width: 120px;
    height: 120px;
}

.video .upload_popup_tabs_content_item_top label img {
    //width: 202px;
    //height: 80px;
    width: 100%;
    height: 100%;
}

.upload_popup_tabs_content_item_bottom {
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 8px 10px;
}

.upload_popup_tabs_content_item_bottom svg {
    display: block;
    cursor: pointer;
}

.upload_popup_tabs_content_item_bottom .popup_move svg {
    width: 20px;
    height: 20px;
    fill: $iconMoveFillColor;
}

.upload_popup_tabs_content_item_bottom .popup_trash svg {
    width: 16px;
    height: 16px;
    fill: #eb5757;
}

.upload_popup_tabs_content_item_bottom .popup_full_img svg {
    width: 16px;
    height: 16px;
    fill: #6f0367;
}

.upload_popup_tabs_content_bottom {
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.popup_bottom_upload_btn {
    width: 144px;
    height: 42px;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.01em;
    color: #446077;
    border: 1px solid #446077;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 4px;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
    background-color: #fff;
    z-index: 2;
}
.popup_bottom_upload_btn svg {
    width: 20px;
    height: 20px;
    fill: $iconUploadAttachmentFillColor;
    vertical-align: middle;
    margin-bottom: 4px;
    margin-right: 13px;

    @media screen and (max-width: 599px) {
        margin-right: 0;
    }
}

.popup_bottom_upload_btn_wrap {
    display: flex;
}

.popup_bottom_upload_btn_info {
    width: 112px;
    height: 42px;
    background-color: #dff1ff;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: #446077;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 127px;
    padding-left: 19px;
    padding-right: 8px;
    text-align: center;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    opacity: 0;
    position: absolute;
    bottom: 20px;
    z-index: 1;
}

.popup_bottom_upload_btn:hover + .popup_bottom_upload_btn_info {
    opacity: 1;
}

.popup_bottom_upload_btn img {
    vertical-align: middle;
    display: inline-block;
    margin-top: -5px;
    margin-right: 13px;
}

.popup_bottom_send_btn {
    font-weight: 600;
    width: 167px;
    height: 42px;
    background: -o-linear-gradient(359.56deg $accentLeftColor 0%, $accentRightColor 100%);
    background: linear-gradient(90.5deg, $accentLeftColor 0%, $accentRightColor 100%);
    -webkit-box-shadow: 0px 4px 8px rgba(14, 4, 143, 0.24);
    box-shadow: 0px 4px 8px rgba(14, 4, 143, 0.24);
    border-radius: 4px;
    color: #fff;
    line-height: 42px;
    text-align: center;
    cursor: pointer;
}

.popup_bottom_send_btn img {
    display: inline-block;
    margin-right: 14px;
    margin-bottom: -3px;
}

.popup_time {
    font-weight: 500;
    font-size: 11px;
    text-align: center;
    color: #a3b3c9;
}

.popup_audio_item_top {
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 12px;
}

.popup_audio_item_play_btn {
    margin-right: 16px;
    cursor: pointer;
}

.popup_audio_item_info {
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.popup_audio_item_date {
    font-size: 12px;
    letter-spacing: -0.03em;
    color: rgba(55, 78, 108, 0.32);
}

.popup_audio_item_time {
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #c2cfe0;
}

.popup_audio_item_name {
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0.01em;
    color: rgba(55, 78, 108, 0.56);
    max-width: calc(100% - 50px);
    overflow: hidden;
    text-overflow: ellipsis;

    @media screen and (max-width: 880px) {
        max-width: 50vw;
        white-space: nowrap;
    }
}

.popup_audio_item_graph img {
    width: 100%;
}

.popup_audio_item_bottom {
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.popup_audio_item_check input {
    display: none;
}

.popup_audio_item_check label {
    display: block;
    width: 16px;
    height: 16px;
    background-color: #fff;
    border: 1px solid #ced6e0;
    border-radius: 4px;
    cursor: pointer;
}

.popup_audio_item_check input:checked + label {
    background-image: url(../../img/popup-checked.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-color: #9c6ef7;
    border-color: #9c6ef7;
}

.playing.upload_popup_tabs_content_audio_item {
    background-color: #c6ccf0;
}

.playing .popup_audio_item_date {
    color: #fff;
}

.playing .popup_audio_item_name {
    color: #474f5c;
}

.playing .popup_audio_item_time {
    color: #fff;
}

.fancybox-is-open .fancybox-bg {
    background: rgba(55, 78, 108, 0.32);
}

.add_file_popup_wrap.fancybox-content {
    padding: 0;
    padding-bottom: 16px;
}

.popup_video.fancybox-content {
    padding: 0;
}

.add_file_popup_wrap {
    width: 343px;
    height: 250px;
    background-color: #fff;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
}

.popup_add_file_head {
    height: 44px;
    font-weight: bold;
    font-size: 16px;
    text-align: center;
    color: #ffffff;
    background-color: $accentLeftColor;
    line-height: 43px;
    margin-bottom: 20px;
}

.popup_add_file_row {
    display: block;
    margin: 0 20px;
    margin-bottom: 9px;
}

.popup_add_file_row span {
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    color: $accentLeftColor;
}

.popup_add_file_row input[type='text'] {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.4);
    height: 42px;
    outline: none;
    padding: 0 11px;
    margin-top: 6px;
    font-size: 12px;
}

.popup_add_file_row input[type='text']::-webkit-input-placeholder {
    font-size: 12px;
    color: rgba(51, 51, 51, 0.5);
}

.popup_add_file_row input[type='text']::-moz-placeholder {
    font-size: 12px;
    color: rgba(51, 51, 51, 0.5);
}

.popup_add_file_row input[type='text']:-ms-input-placeholder {
    font-size: 12px;
    color: rgba(51, 51, 51, 0.5);
}

.popup_add_file_row input[type='text']::-ms-input-placeholder {
    font-size: 12px;
    color: rgba(51, 51, 51, 0.5);
}

.popup_add_file_row input[type='text']::placeholder {
    font-size: 12px;
    color: rgba(51, 51, 51, 0.5);
}

.popup_add_file_row.oversize {
    font-weight: 600;
    font-size: 12px;
    color: #333333;
    text-align: center;
    background-color: rgba(235, 87, 87, 0.72);
    line-height: 42px;
}

.popup_add_file_row.oversize span {
    font-weight: normal;
    color: #333;
}

.popup_add_file_row .popup_bottom_upload_btn {
    margin: 0 auto;
    margin-top: 20px;
    display: block;
}

.upload_btn input[type='file'] {
    display: none;
}

.popup_add_file_row.uploaded {
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.popup_uploaded_info {
    margin-left: 25px;
    font-weight: 600;
    font-size: 14px;
    color: $accentRightColor;
}

.popup_uploaded_info span {
    color: rgba(51, 51, 51, 0.6);
    font-weight: normal;
    font-size: 12px;
}

.popup_add_file_upload_btn {
    display: block;
    margin: 0 auto;
    margin-top: 20px;
    background: -o-linear-gradient(359.65deg, $accentLeftColor 0%, $accentRightColor 100%);
    background: linear-gradient(90.5deg, $accentLeftColor 0%, $accentRightColor 100%);
    border-radius: 4px;
    height: 42px;
    width: 116px;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    color: #ffffff;
    line-height: 42px;
    cursor: pointer;
}

.fancybox-close-small svg {
    fill: #fff;
    stroke: #fff;
    stroke-width: #fff;
    color: #fff;
}

.popup_video {
    display: none;
    max-width: 100%;
    background-color: transparent;
}

.popup_video video {
    display: block;
    margin: auto;
    max-width: 100%;
}

.fancybox-bg {
    background-color: transparent;
}

.popup_complaint {
    width: 343px;
    padding: 0;
    border-radius: 8px;
    display: inline-block;
}

.reason_label {
    display: block;
    font-size: 14px;
    color: #00317b;
    margin-top: 6px;
    margin-bottom: 6px;
}

.reason_textarea {
    display: block;
    width: 100%;
    height: 188px;
    border: 0.5px solid rgba(0, 0, 0, 0.4);
    border-radius: 4px;
    resize: none;
    padding: 10px;
}

.complain_file_inp {
    display: none;
}

.complain_file_label {
    display: block;
    width: 100%;
    height: 42px;
    font-size: 14px;
    line-height: 42px;
    text-align: center;
    color: #00317b;
    border: 0.5px dashed rgba(0, 0, 0, 0.4);
    border-radius: 4px;
    cursor: pointer;
    margin-top: 9px;
}

.complain_file_label img {
    vertical-align: middle;
    margin-right: 5px;
}

.popup_complaint .popup_add_file_row {
    margin-bottom: 20px;
}

.send_complaint_btn {
    display: block;
    width: 116px;
    height: 42px;
    font-weight: bold;
    font-size: 14px;
    line-height: 42px;
    text-align: center;
    color: #ffffff;
    background: -webkit-gradient(
        linear,
        left top,
        right top,
        from(accentLeftColor),
        to(accentRightColor)
    );
    background: -o-linear-gradient(left, $accentLeftColor 0%, $accentRightColor 100%);
    background: linear-gradient(90deg, $accentLeftColor 0%, $accentRightColor 100%);
    border-radius: 4px;
    margin-left: auto;
    cursor: pointer;
}

.c4_top_close {
    display: none;
    width: 30px;
    height: 30px;
    background-image: url(/img/cross.svg);
    background-size: 20px 20px;
    background-position: center;
    position: absolute;
    right: 5px;
    top: 5px;
    background-repeat: no-repeat;
    cursor: pointer;
}

.head_logo {
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.left_menu_btn span {
    display: block;
    background-color: #fff;
    width: 40px;
    height: 3px;
    margin-bottom: 7px;
    border-radius: 5px;
}

.left_menu_btn span:last-child {
    margin-bottom: 0;
}

.head_empty {
    width: 44px;
    height: 44px;
}

.c1_close {
    display: none;
    width: 30px;
    height: 30px;
    background-image: url(../../img/cross_white.svg);
    background-size: 20px 20px;
    background-position: center;
    position: absolute;
    right: -40px;
    top: 10px;
    background-repeat: no-repeat;
    cursor: pointer;
    visibility: hidden;
}

.upload_popup_wrap {
    height: 460px;
    position: relative;
}

.popup_bottom_send_btn {
    position: absolute;
    bottom: 22px;
    right: 22px;
}

.popup_bottom_upload_btn {
    position: absolute;
    bottom: 20px;
    left: 22px;
}

.popup_add_file_upload_btn {
    position: absolute;
    bottom: 22px;
    right: 50%;
    transform: translateX(50%);
}

.react-tabs__tab--selected {
    border-bottom: 2px solid $accentRightColor !important;
}

.react-tabs__tab--selected span {
    border: $accentRightColor !important;
    color: $accentRightColor !important;
}

.upload_popup_tabs_content_middle.audio {
    /*height: 485px;
	padding-right: 7px;
	margin-right: -10px;*/
}

.upload_popup_tabs_content_middle.audio::-webkit-scrollbar {
    width: 3px;
    background: #f7f8fd;
    margin-right: 3px;
}

.upload_popup_tabs_content_middle.audio::-webkit-scrollbar-thumb {
    background: rgba(2, 49, 124, 0.59);
}

.draggable {
    margin: 3px;
}

.dragged {
    opacity: 1;
}

.placeholder {
    opacity: 0 !important;
}

.video .upload_popup_tabs_content_item_top label img {
    //width: 202px;
    width: 100%;
}

.upload_popup_tabs_content_item_bottom {
    cursor: pointer;
}

.draggable {
    margin: 2px;
}

.dragged {
    opacity: 0.5;
}

.placeholder {
    opacity: 0.5;
}

.c3_smile_btn_wrap .emoji-mart {
    max-width: 100%;
    border: none;
    background: rgba(255, 255, 255, 0);
}

.emoji-mart-title-label {
    display: none;
}

.c3_smile_btn_wrap .emoji-mart-scroll {
    overflow-y: unset;
}

.c3_smile_btn_wrap .emoji-mart-category-label span {
    background: rgba(255, 255, 255, 0);
}

.make-emojis-large {
    font-size: 130px;
    line-height: normal;
}

.c3_smile_btn_wrap .emoji-mart-category-label {
    display: none;
}

.emoji-mart-category:nth-child(2) {
    //display: none;
}

.c4_minichat .c3_write_msg {
    display: grid;
    grid-template-areas:
        'c4_text c4_text c4_text'
        'c4_attach c4_smile c4_send';
    grid-template-columns: 56px 1fr 111px;
}

.c4_minichat .c3_attach {
    grid-area: c4_attach;
    margin-right: 0;
    padding-top: 0;
}

.c4_minichat .c3_textaera_wrap {
    grid-area: c4_text;
}

.c4_minichat .c3_smile_btn_wrap {
    grid-area: c4_smile;
    margin-left: 0;
    height: 32px;
    display: flex;
    align-items: center;
}

.c4_minichat .c3_smile_btn_wrap2.active:before {
    width: 54px;
}

.c4_minichat .c3_smile_btn {
    margin-left: 0;
    padding: 0;
    height: max-content;
    width: max-content;
}

.c4_minichat .c3_sticker_wrap {
    margin-left: 12px;
    z-index: 3;
}

.c4_minichat .c3_sticker_btn {
    margin-top: 0;
    background-position: center;
}

.c4_minichat .c3_sticker_wrap.active:before {
    width: 52px;
}

.c4_minichat .c3_stickers_list_wrap {
    right: 100%;
}

.c4_minichat .c3_sticker_wrap.active:after {
    left: 0;
}

.c4_minichat .c3_send {
    grid-area: c4_send;
}

video {
    pointer-events: none;
}

.c4_item_right {
    cursor: pointer;
    flex: 1;
    max-width: 100%;
}

.upload_popup_tabs_content_item_top {
    display: flex;
    justify-content: center;
    align-items: center;
}

.upload_popup_tabs_content_item.active {
    display: flex;
    justify-content: center;
}

.upload_popup_tabs_content_item.hidden {
    display: none;
}

.upload_popup_tabs_content_item_top video {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
    border-radius: 4px;
    object-position: center;
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
}

.upload_popup_tabs_content_item_top > img {
    padding-top: 30px;
    padding-bottom: 30px;
}

.c2_new_item_big {
    position: relative;
    background-color: $chatItemActiveBg;
    border-radius: 8px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    margin-bottom: 10px;
}

.c2_new_item_big_img_wrap {
    position: relative;
    cursor: pointer;
    background-color: #eceefa;
}

.c2_new_item_big_img {
    width: 162px;
    border-radius: 8px 0 0 8px;
    height: 270px;
    object-fit: fill;
    display: block;
}

.c2_new_item_big_img_wrap:after {
    content: '';
    display: block;
    width: 16px;
    height: 16px;
    position: absolute;
    bottom: 0;
    right: -2px;
    background-color: #e0e0e0;
    border: 2px solid $chatItemActiveBg;
    border-radius: 100%;
}

.c2_new_item_big_img_wrap.online:after {
    background-color: $onlineColor;
}

.c2_new_item_big_img_wrap svg {
  position: absolute;
  top: -1px;
  right: -1px;
  width: 22px;
  height: 22px;
  stroke: #446178;
}

.c2_new_item_big_gallery_btn {
    width: 110px;
    height: 28px;
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $accentLeftColor;
    font-size: 11px;
    letter-spacing: -0.01em;
    position: absolute;
    bottom: 7px;
    left: 50%;
    transform: translateX(-50%);
    transition: background-color 0.3s;
}

.c2_new_item_big_img_wrap:hover .c2_new_item_big_gallery_btn {
    background-color: #fff;
}

.c2_new_item_big_gallery_btn img {
    margin-right: 7px;
}

.c2_new_item_big_gallery_btn svg {
    fill: $accentLeftColor;
    width: 17px;
    height: 18px;
    margin-right: 7px;
    position: initial;
    stroke: none;
}

.c2_new_item_big_count {
    position: absolute;
    top: 8px;
    left: 8px;
    border-radius: 4px;
    background-color: #eb5757;
    height: 27px;
    line-height: 27px;
    padding: 0 7px;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: -0.02em;
    z-index: 1;
}

.c2_new_item_big_rigth {
    -webkit-flex: 1px;
    -moz-flex: 1px;
    -ms-flex: 1px;
    -o-flex: 1px;
    flex: 1px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    -o-flex-direction: column;
    flex-direction: column;
    justify-content: space-between;
    -ms-align-items: flex-end;
    align-items: flex-end;
}

.c2_new_item_big_time {
    margin-top: 8px;
    margin-right: 5px;
    font-size: 12px;
    letter-spacing: -0.03em;
    color: #bdbdbd;
    display: flex;
    justify-content: flex-end;
}

.c2_new_item_big_middle {
    width: 100%;
    padding-left: 16px;
    padding-right: 9px;
}

.c2_new_item_big_name {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: $chatItemActiveTitleColor;
    margin-bottom: 7px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
}

.c2_new_item_big_name img {
    margin-bottom: 3px;
    margin-left: 8px;
}

.c2_new_item_big_text {
    font-size: 13px;
    color: $chatItemActiveTitleColor;
    overflow: hidden;
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-width: 140px;
    text-overflow: ellipsis;
}

.c2_new_item_big_text img {
    width: 15px;
    vertical-align: middle;
    margin-left: 5px;
    margin-top: -2px;
}

.c2_new_item_big_text svg {
    width: 16px;
    height: 16px;
    margin-left: 5px;
    fill: #ffba30;
    vertical-align: bottom;
}

.c2_new_item_big_bottom {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding-bottom: 7px;
}

.c2_new_item_big_link {
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    font-size: 11px;
    letter-spacing: -0.01em;
    color: $chatItemActiveTitleColor;
    opacity: 0.7;
    transition: 0.3s;
    padding: 0 10px;
    border-radius: 5px;
    height: 28px;
}

.c2_new_item_big_link:hover {
    opacity: 1;
    background-color: rgba(255, 255, 255, 0.3);
}

.c2_new_item_big_link img {
    margin-right: 9px;
}

.c2_new_item_big_link svg {
    fill: $chatItemActiveTitleColor;
    width: 15px;
    height: 15px;
    margin-right: 5px;

    @media screen and (max-width: 1649px) {
        margin-right: 0;
    }
}

.c2_new_item_big_link_video svg {
    fill: $chatItemActiveTitleColor;
    width: 15px;
    height: 15px;
    margin-right: 5px;

    @media screen and (max-width: 1649px) {
        margin-right: 0;
    }
}

.c2_new_item_big_link_info.min {
    width: min-content;
}

.c2_new_item_small {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    background-color: #fff;
    box-shadow: 0px 3px 5px rgba(14, 4, 143, 0.15);
    border-radius: 10px;
    padding: 14px 23px 16px 17px;
    position: relative;
    margin-bottom: 10px;
}

.c2_new_item_small:hover {
    box-shadow: 0px 4px 8px rgba(14, 4, 143, 0.24);
}

.c2_new_item_small_img_wrap {
    position: relative;
}

.c2_new_item_small_img_wrap:after {
    content: '';
    display: block;
    width: 16px;
    height: 16px;
    position: absolute;
    bottom: 5px;
    right: 4px;
    background-color: #e0e0e0;
    border: 2px solid #fff;
    border-radius: 100%;
}

.c2_new_item_small_img_wrap.online:after {
    background-color: #27ae60;
}

.c2_new_item_small_img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 100%;
}

.c2_new_item_small_count {
    position: absolute;
    top: -2px;
    left: -4px;
    border-radius: 4px;
    background-color: #eb5757;
    height: 27px;
    line-height: 27px;
    padding: 0 7px;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: -0.02em;
}

.c2_new_item_small_favs {
    position: absolute;
    top: 3px;
    right: 3px;
}

.c2_new_item_small_right {
    margin-left: 16px;
    padding-top: 9px;
}

.c2_new_item_small_name {
    font-size: 16px;
    font-weight: 600;
    color: #474f5c;
    margin-bottom: 5px;
}

.c2_new_item_small_text {
    font-size: 13px;
    line-height: 150%;
    color: #4f4f4f;
    max-height: 57px;
    overflow: hidden;
    cursor: pointer;
}

.c2_new_item_small_text.from_you {
    position: relative;
    padding-left: 32px;
}

.c2_new_item_small_text.from_you:before {
    content: 'You:';
    position: absolute;
    left: 0;
    color: #6f0267;
}

.c2_new_item_big_text.from_you {
    position: relative;
    padding-left: 32px;
    display: flex;
}

.c2_new_item_big_text.from_you:before {
    content: 'You:';
    position: absolute;
    left: 0;
    color: $chatItemFromActiveTitleColor;
}

.c2_new_item_small_time {
    position: absolute;
    right: 8px;
    bottom: 8px;
    font-size: 12px;
    letter-spacing: -0.03em;
    text-transform: uppercase;
    color: #bdbdbd;
}

.c2_new_item_small_close {
    position: absolute;
    left: 5px;
    top: 5px;
    cursor: pointer;
    padding: 5px;
}

.c2_new_item_small .message_status {
    position: absolute;
    top: 10px;
    right: 8px;
    width: 19px;
    height: 12px;
    background-size: contain;
    background-repeat: no-repeat;
}

.ReactModal__Content.ReactModal__Content--after-open::-webkit-scrollbar {
    width: 3px;
    background: #f4f4f4;
    border-radius: 5px;
}

.ReactModal__Content.ReactModal__Content--after-open::-webkit-scrollbar-thumb {
    background: #446077;
    border-radius: 7px;
}

.c3_chat_empty_img {
    max-width: 90%;
    margin-bottom: 36px;
    max-height:  25vh;
    margin-top: auto;
}

.c3_chat_empty_img.halloween {
  @media screen and (min-width: 1600px) {
    max-height: calc(100% - 640px);
  }
}

.c3_chat_empty_bottom {
    margin-bottom: auto;
}

.c3_chat_empty_row:not(:last-child) {
    margin-bottom: 33px;
}

.c3_chat_empty_row:first-child .c3_chat_empty_row_btn {
    width: 139px;
}

.c3_chat_empty_row_title {
    font-weight: 600;
    font-size: 18px;
    color: $accentLeftColor;
    margin-bottom: 12px;
}

.c3_chat_empty_row_btn {
    width: 159px;
    height: 33px;
    line-height: 31px;
    margin: 0 auto;
    background-color: #fff;
    border: 1px solid $bluePinkColor;
    border-radius: 5px;
    cursor: pointer;
    color: $bluePinkColor;
    font-weight: 500;
    transition: box-shadow 0.3s;
}

.c3_chat_empty_row_btn:hover {
    box-shadow: $stepNextButtonHoverShadow;
}

.c2_chat_categories {
    display: none;
}

.mob_notify_list_fixed {
    display: none;
}

.mob_notify_bottom {
    display: none;
}

.c3_top {
    flex: none;
    position: relative;
}

.c3_touch_stickers_wrap {
    display: none;
}

.c3_touch_smiles_wrap {
    display: none;
}

.c4_girls_title_mob_dot {
    display: none;
}

.column-3.column_3_letters_page .girls_slider_list_wrap_global:after,
.column-3.column_3_letters_page .girls_slider_list_wrap_global:before,
.column-3.column_3_chat_page .girls_slider_list_wrap_global:after,
.column-3.column_3_chat_page .girls_slider_list_wrap_global:before {
    //background: linear-gradient(to left, #fff 0%, rgba(255, 255, 255, 0) 60%);
    box-shadow: 2px 2px 50px 50px #ffffff;
}

.column_2_chat_page .girls_slider_wrap_global {
    display: none;
}

.column_2_chat_page .girls_slider_list_wrap_global:after {
    background: linear-gradient(to left, #ebedfa 0%, rgba(255, 255, 255, 0) 60%);
}

.emoji-mart-category-list li {
    cursor: pointer;
    border-radius: 4px;
}

.emoji-mart-category-list li:hover {
    background-color: #d3daff;
}

.emoji-mart-category .emoji-mart-emoji:hover:before {
    display: none;
}

.emoji-mart-category .emoji-mart-emoji span,
.emoji-mart .emoji-mart-emoji {
    cursor: pointer;
}

.c3_top .rhap_progress-section {
    width: 170px;
}

.c4_item_each_like {
    padding: 20px 30px 10px;
    background-color: $notificationMatchBg;
    margin-bottom: 8px;
    box-shadow: 0 3px 5px rgba(14, 4, 143, 0.15);
    border-radius: 10px;
    position: relative;
    overflow: hidden;
}

.c4_item_each_like:hover {
    z-index: 99;
}

.tutorial_step_6_activity_list_global .c4_item_each_like {
    pointer-events: none;
}

.tutorial_step_6_activity_list_global .c4_item_each_like_row {
    justify-content: center;
}

.tutorial_step_6_activity_list_global .c4_item_each_like_girl_photo {
    object-position: top;
}

.c4_item_each_like.type_1 {
    background-color: $notifyItemEachLike;
}

.c4_item_each_like_title {
    font-weight: bold;
    font-size: 16px;
    text-align: center;
    color: $notificationMatchColor;
    margin-bottom: 11px;
}

.c4_item_each_like.type_1 .c4_item_each_like_title {
    color: $notifyItemEachLikeTitle2Color;
}

.c4_item_each_like_row {
    display: flex;
    align-items: center;
    position: relative;
}

.c4_item_each_like_man_photo,
.c4_item_each_like_girl_photo {
    width: 100px;
    height: 100px;
    border-radius: 100%;
    object-fit: cover;
}

.c4_item_each_like_man_photo {
    transition:
        width 0.5s,
        height 0.5s;
}

.c4_item_each_like:hover .c4_item_each_like_man_photo {
    width: 0;
    height: 0;
}

.c4_item_each_like_heart {
    width: 35px;
    height: 31px;
    margin: 0 10px;
    transition: 0.5s;
}

.c4_item_each_like:hover .c4_item_each_like_heart {
    width: 0;
    height: 0;
    margin: 0;
}

.c4_item_each_like_girl_photo_wrap {
    position: relative;
    transition: margin-left 0.5s;
}

.c4_item_each_like:hover .c4_item_each_like_girl_photo_wrap {
    margin-left: -10px;
}

.c4_item_each_like_girl_photo_wrap:after {
    content: '';
    display: block;
    width: 11px;
    height: 11px;
    background-color: $onlineColor;
    border-radius: 100%;
    border: 3px solid #7474b2;
    position: absolute;
    right: 6px;
    bottom: 8px;
}

.c4_item_each_like.type_1 .c4_item_each_like_girl_photo_wrap:after {
    border-color: $notifyItemEachLikeOnlineBorder;
}

.c4_item_each_like_hi_btn {
    width: 20px;
    height: 44px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: $notifyItemSayHiButtonBackground;
    box-shadow: 0 4px 5px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.01em;
    color: #001027;
    right: -50px;
    overflow: hidden;
    white-space: nowrap;
    opacity: 0;
    transition:
        width 0.5s,
        right 0.5s,
        opacity 0.5s,
        box-shadow 0.3s;
    cursor: pointer;
}

.c4_item_each_like_hi_btn:hover {
    box-shadow: 0px 6px 12px rgba(84, 0, 114, 0.24);
}

.c4_item_each_like:hover .c4_item_each_like_hi_btn {
    width: 174px;
    right: -16px;
    opacity: 1;
}

.c4_item_each_like.type_1 .c4_item_each_like_hi_btn {
    background: linear-gradient(90.13deg, #00317b 0%, #720066 100%);
    color: #fff;
}

.c4_item_each_like_hi_btn img {
    width: 26px;
    margin-right: 12px;
}

.c4_item_each_like_time {
    margin-top: 2px;
    font-size: 12px;
    letter-spacing: -0.03em;
    color: #fff;
    text-align: center;
}

.c4_item_each_like.type_1 .c4_item_each_like_time {
    color: $notifyItemEachLikeTitleColor;
}

.c4_item_each_like_remove {
    width: 15px;
    height: 15px;
    position: absolute;
    right: 7px;
    top: 7px;
    background-image: url(../../img/cross_white.svg);
    background-size: 10px 10px;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
}

.c4_item_each_like.type_1 .c4_item_each_like_remove {
    background-image: url(../../img/cross-darkgrey.svg);
    background-position: center;
}

.mob_hor {
    display: none;
}

@media screen and (max-width: 1749px) {
    .make-emojis-large {
        font-size: 130px;
        line-height: normal;
    }
    .c3_options {
        padding: 0 30px 0 40px;
    }
}

@media screen and (max-width: 1649px) {
    .c4_list_wrap_2 {
        padding-right: 4px;
    }

    .c2_item_new_message {
        width: 49px;
        height: 17px;
        font-size: 10px;
    }

    .c4_credits_info_title {
        font-size: 16px;
    }

    .c4_item.message:not(.clicked):hover .c4_item_text {
        height: 132px;
    }

    .c2_item_name {
        font-size: 13px;
    }

    .c2_item_text {
        font-size: 12px;
    }

    .c2_item_photo_wrap:after {
        width: 9px;
        height: 9px;
    }

    .column-4.active {
        right: 0;
    }

    .c3_options {
        padding: 0 25px 0 45px;
    }

    .c2_item_text {
        //height: 38px;
        font-weight: normal;
        width: 145px;
    }

    .c2_item_photo {
        width: 72px;
        height: 72px;
    }

    .c2_item_photo_counter {
        width: 25px;
        height: 25px;
        line-height: 27px;
        font-size: 14px;
    }

    .c4_item_photo_wrap {
        margin-right: 10px;
    }

    .c4_item_photo {
    }

    .c4_item_name {
        font-size: 14px;
    }

    .c4_item_text {
        margin-top: 5px;
    }

    .c4_item.liked {
        padding: 10px;
    }

    .c4_item {
        margin-bottom: 5px;
    }

    .c4_item_each_like {
        margin-bottom: 5px;
    }

    .c4_item.message {
        padding: 10px;
    }

    .c4_top {
        font-size: 15px;
    }

    .message .c4_item_text {
        width: auto;
        -webkit-line-clamp: 3;
        height: 56px;
    }

    .c2_top_search input {
        width: 131px;
    }

    .c2_top_online_text {
        font-size: 14px;
    }

    .c2_top_tumbler label {
        width: 30px;
        height: 14px;
    }

    .c2_top_tumbler label:before {
        height: 10px;
        width: 10px;
    }

    .c2_top_tumbler input:checked + label:before {
        left: 18px;
    }

    .c2_new_item_big_img {
        width: 124px;
        height: 198px;
    }

    .c2_new_item_big_name {
        font-size: 14px;
    }

    .c2_new_item_big_middle {
        padding-left: 10px;
        padding-right: 5px;
    }

    .c2_new_item_big_text {
        font-size: 12px;
    }

    .c2_new_item_small_name {
        font-size: 14px;
    }

    .c2_new_item_small_right {
        margin-left: 10px;
    }

    .c2_new_item_small {
        padding: 14px 16px 13px 10px;
    }

    .c2_new_item_small_text {
        font-size: 12px;
    }

    .c4_item.clicked .c4_item_photo {
        width: 105px;
        height: 168px;
    }

    .message_status_unanswered {
        width: 62px;
        height: 17px;
        right: 3px;
        top: 4px;
        line-height: 17px;
        font-size: 9px;
    }

    .c2_new_item_big_gallery_btn {
        text-transform: capitalize;
        width: max-content;
        padding: 0 10px;
    }

    .c2_new_item_big_gallery_btn span span {
        display: none;
    }

    .c2_new_item_big_bottom {
        align-items: stretch;
        padding-bottom: 8px;
        flex-wrap: wrap;
    }

    .c2_new_item_big_link_info {
        margin-top: 3px;
    }

    .c2_new_item_big_link {
        height: auto;
        width: 50px;
        flex-direction: column;
        justify-content: center;
        padding: 6px 0;
    }

    .c2_new_item_big_link img {
        margin: auto 0;
    }

    .c4_item_text_media_audio {
        width: 105px;
    }

    .c4_item_text_media {
        margin-top: 0;
    }

    .c4_item_clear {
        right: 2px;
        top: 2px;
    }

    .c4_item_time {
        right: 8px;
        bottom: 2px;
        font-size: 10px;
    }

    .c4_support {
        height: 44px;
        font-size: 14px;
    }

    .c4_support img {
        margin-right: 24px;
    }

    .c4_support svg {
        margin-right: 28px;
    }

    .c4_how_to_start_btn,
    .c4_whatsApp_btn {
        height: 44px;
        font-size: 14px;
    }

    .c4_list {
        padding-left: 10px;
    }

    .c2_item {
        margin-bottom: 5px;
    }

    .c2_new_item_big {
        margin-bottom: 5px;
    }

    .c2_new_item_big_link_info.min {
        text-align: center;
    }

    .c4_mute {
        padding-bottom: 8px;
    }

    .c4_mute_btn {
        width: 185px;
        height: 44px;
    }
}

@media screen and (max-width: 1649px) and (min-width: 1140px) {
    .c4_item.appeal .c4_item_text br,
    .c4_item.no_chats .c4_item_text br,
    .c4_item.want_chat .c4_item_text br,
    .c4_item.is_online_hi .c4_item_subtext br {
        display: none;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1649px), screen and (max-width: 599px) {
    .c4_item.activity {
        padding: 8px 10px;
    }

    .c4_item_activity_btn {
        width: 107px;
        right: 8px;
    }

    .c4_item_activity_btn .big {
        display: none;
    }

    .c4_item_activity_btn .small {
        display: block;
    }

    .c4_item_activity_btn img {
        display: none;
    }

    .c4_item.activity .c4_item_text br {
        display: none;
    }

    .c4_item.joined .c4_item_text {
        padding-left: 19px;
    }

    .c4_item.searching .c4_item_text {
        padding-left: 18px;
        padding-right: 10px;
    }

    .c4_item.unknown_like .c4_item_text {
        font-size: 12px;
    }

    .c4_item.updated .c4_item_text span {
        display: none;
    }

    .c4_item_match {
        padding: 15px;
        margin-bottom: 5px;
    }

    .c4_item_match_woman {
        display: block;
    }

    .c4_item_match_man_img,
    .c4_item_match_woman_img {
        width: 52px;
        height: 52px;
    }

    .c4_item_match_woman_name {
        width: 100%;
        text-align: center;
        font-size: 10px;
        margin-top: 5px;
    }

    .c4_item_match_row {
        flex-wrap: wrap;
        justify-content: center;
    }

    .c4_item_match_title {
        font-size: 12px;
        text-align: center;
        margin-bottom: 10px;
        width: 100%;
    }

    .c4_item_match_woman_img_wrap {
        transition: 0.5s;
    }

    .c4_item_match:hover .c4_item_match_woman_img_wrap {
        transform: translateX(-70px);
    }

    .c4_item_match {
        overflow: hidden;
    }

    .c4_item_match .c4_item_activity_btn {
        width: 10px;
        top: calc(50% + 3px);
    }

    .c4_item_match:hover .c4_item_activity_btn {
        width: 130px;
        right: 18px;
    }

    .c4_item_each_like {
        padding: 13px 8px 9px;
    }

    .c4_item_each_like.type_3 {
        background-size: 100% auto;
    }

    .c4_item_each_like_title {
        font-size: 14px;
    }

    .c4_item_each_like_man_photo,
    .c4_item_each_like_girl_photo {
        width: 83px;
        height: 83px;
    }

    .c4_item_each_like_heart {
        width: 29px;
        height: 26px;
        margin: 0 9px;
    }

    .c4_item_each_like_hi_btn img {
        display: none;
    }

    .c4_item_each_like:hover .c4_item_each_like_hi_btn {
        width: 125px;
        right: 0;
    }

    .c4_item_each_like:hover .c4_item_each_like_girl_photo_wrap {
        margin-left: 0;
    }
}

@media screen and (max-width: 1599px) {
    .column-3 .c3_head {
        height: 70px;
    }

    .c3_head_name {
        font-size: 14px;
    }

    .c3_head_id {
        font-size: 11px;
    }

    .c3_head_place {
        font-size: 11px;
    }

    .c3_chat_wrap {
        /*height: calc(100vh - 126px);
		height: calc(calc(var(--vh, 1vh) * 100) - 126px);*/
    }

    /*.c3_chat_scroll{
		height: calc(100vh - 276px);
		height: calc(calc(var(--vh, 1vh) * 100) - 276px);
	}*/

    .column_3_chat_page .c3_chat_empty_img,
    .column_3_letters_page .c3_video_empty_img {
        //display: none;
    }

    .column_3_chat_page .c3_chat_empty_bottom,
    .need_confirm_email .column_3_chat_page .c3_chat_empty_bottom {
        margin: auto;
    }
}

@media screen and (max-width: 1549px) {
    .c3_options_item {
        font-size: 12px;
    }

    .c3_options_item img,
    .c3_options_item svg,
    .c3_options_item.media > svg {
        max-width: 20px;
        max-height: 20px;
    }

    .c3_options_item.c3_options_item_contact_request svg {
        max-width: 24px;
        max-height: 24px;
    }

    .c3_options {
        height: 48px;
    }

    .c3_options_item:first-child {
        /*margin-right: 30px;*/
    }

    .c3_options_item.webacm:after {
        right: 15px;
    }
}

@media screen and (max-width: 1499px) {
    .c3_top .rhap_progress-section {
        width: 108px;
    }

    .c1_add_credit_big {
        font-size: 14px;
    }

    .c1_add_credit_big img {
        width: 16px;
        margin-right: 7px;
    }

    .column-1:hover .c1_add_credit_big {
        font-size: 16px;
    }

    .column-1:hover .c1_add_credit_big img {
        width: 20px;
        margin-right: 11px;
    }

    .column-1 .c1_logo img:nth-child(1) {
        height: 45px;
    }

    .column-1 .c1_logo img:nth-child(2) {
        height: 25px;
        margin-left: 4px;
        display: none;
    }

    .column-1 .c1_profile_right div {
        width: 30px;
    }

    .c1_add_credits {
        padding-left: 0;
        background-position: center;
    }

    .column-1:hover .c1_add_credits {
        padding-left: 27px;
        background-position: 18px center;
    }

    .column-1 .c1_profile_right div.with_notif {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
    }

    .column-1 .c1_profile_right div span {
        width: 0px;
        overflow: hidden;
    }

    .column-1 .c1_menu_item span {
        max-width: 0px;
        overflow: hidden;
        visibility: hidden;
    }

    .c1_menu_item_privacy {
        padding-right: 12px;
    }

    .column-1:hover .c1_menu_item_privacy {
        padding-right: 0;
    }

    .column-1 .c1_menu_item {
        justify-content: center;
        padding-left: 16px;
    }

    .column-1 .c1_menu_item.c1_menu_item_videoshow {
    }

    .column-1 .c1_submenu_item_left {
        display: flex;
        align-items: center;
    }

    .c1_submenu_item_left svg {
        margin-bottom: 0;
        margin-right: 15px;
        top: 0;
        width: 20px;
    }

    .column-1 .c1_submenu_item_left span {
        max-width: 0px;
        overflow: hidden;
        font-size: 12px;
    }

    .c1_menu_item {
        height: 50px;
        font-size: 16px;
    }

    .c1_logo {
        height: 60px;
    }

    .c4_top {
        height: 57px;
        line-height: 57px;
    }

    .c2_top {
        padding: 15px 10px;
    }

    .c2_top_tumbler {
        margin-right: auto;
        margin-left: 8px;
    }

    .c1_submenu_item {
        height: 50px;
    }

    .c1_submenu_item_left.c1_submenu_item_left_dialog svg {
        height: 16px;
    }

    .c1_submenu_item_right_top {
        font-size: 11px;
        padding-bottom: 2px;
        margin-bottom: 3px;
    }

    .c1_submenu_item_right_bottom {
        font-size: 11px;
        margin-bottom: 3px;
    }

    .column-1:hover {
        width: 204px;
    }

    .c1_logo img:nth-child(1) {
        height: 40px;
    }

    .c1_logo img:nth-child(2) {
        width: 41px;
    }

    .column-1:hover .c1_logo img:nth-child(2) {
        height: auto;
        margin-left: 4px;
        display: block;
    }

    .column-1:hover .c1_profile_right div {
        width: 112px;
    }

    .column-1:hover .c1_profile_right div span {
        width: 80px;
    }

    .column-1:hover .c1_menu_item span {
        max-width: 150px;
        visibility: visible;
    }

    .column-1:hover .c1_menu_item {
        justify-content: flex-start;
        padding-left: 10px;
    }

    .column-1:hover .c1_submenu_item_left {
    }

    .column-1:hover .c1_submenu_item_left span {
        max-width: 100px;
        overflow: visible;
    }

    .column-1:hover .c1_menu_more_btn {
        justify-content: center;
    }

    .c1_menu_hide_wrap {
        display: none;
    }

    .column-1:hover .c1_menu_item_free_video {
        display: block;
    }
}

@media screen and (max-width: 1399px) {
    .c3_options {
        padding: 0 15px 0 40px;
    }

    .c3_head {
        padding-left: 20px;
    }

    .c3_options_item img,
    .c3_options_item svg {
        margin-right: 8px;
    }

    .c3_options_gift .c3_options_gift_discounts {
        margin-left: 8px;
    }

    .message_text {
        padding: 16px;
    }

    .c3_chat_empty_row_title {
        font-size: 15px;
    }

    //.c3_chat_empty_img {
    //  max-height: 300px;
    //}

    //.chat_wrap_confirm_email_wrap ~ .c3_chat_empty_img {
    //  max-height: 300px;
    //}

    .c3_chat_empty_row {
        margin-bottom: 22px;
    }
}

@media screen and (max-width: 1299px) {
    .c3_head_btn.ring_not {
        margin: 0;
        margin-right: 30px;
    }

    .c1_menu_hide_wrap {
        display: none;
    }

    .c1_wrap {
        padding-bottom: 5px;
        overflow-x: hidden;
    }

    .c3_head {
        padding: 0 15px;
    }
}

@media screen and (max-width: 1199px) {
    .c3_head {
        padding-right: 30px;
    }

    .c3_options {
        padding-right: 40px;
    }

    .c1_logo {
        display: none;
    }

    /*.c1_wrap2{
		height: calc(100vh - 162px);
		height: calc(calc(var(--vh, 1vh) * 100) - 162px);
	}*/

    .column-4.active {
        right: 0;
    }

    .c4_top_close {
        display: block;
    }

    .mob_notif_fixed {
        display: block;
        z-index: 10;
    }

    .mob_notif_fixed.animate img {
        animation-duration: 3s;
        animation-name: notif_anim;
        animation-iteration-count: infinite;
        transform-origin: top center;
    }

    @keyframes notif_anim {
        0% {
            transform: rotate(0deg);
        }

        5% {
            transform: rotate(15deg);
        }

        10% {
            transform: rotate(-15deg);
        }

        15% {
            transform: rotate(15deg);
        }

        20% {
            transform: rotate(-15deg);
        }

        25% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(0deg);
        }
    }

    html,
    body {
        //width: 100vw;
        //height: 100vh;
        //height: calc(var(--vh, 1vh) * 100);
        //overflow: hidden;
        //position: fixed;
        //top: 0;
        //left: 0;
    }
}

@media screen and (max-width: 1199px) and (min-width: 1140px) {
    .c3_head {
        padding-right: 80px;
    }
}

@media screen and (min-width: 1140px) {
    .need_confirm_email .c3_chat_empty_bottom {
        margin-bottom: auto;
    }
}

@media (hover: none) and (pointer: coarse) {
    @media screen and (min-width: 1140px) {
        .c2_top {
            padding: 16px;
        }

        .c2_chat_categories_btn {
            height: 30px;
            line-height: 30px;
            font-weight: 600;
            font-size: 12px;
            letter-spacing: -0.02em;
            color: #00317b;
            text-align: center;
            cursor: pointer;
            position: relative;
            padding: 0 10px;
        }

        .c2_chat_categories_btn_count {
            height: 18px;
            line-height: 18px;
            padding: 0 3px;
            background-color: #9999dd;
            border-radius: 4px;
            position: absolute;
            right: -2px;
            top: -5px;
            font-weight: 600;
            font-size: 12px;
            letter-spacing: -0.02em;
            color: #fff;
            min-width: 18px;
            text-align: center;
        }

        .c2_chat_categories_btn.active {
            background-color: #fff;
            box-shadow: 0px 2px 5px rgba(0, 49, 123, 0.1);
            border-radius: 5px;
        }

        .c3_chat_wrap {
            height: auto;
            flex: 1;
            overflow: hidden;
        }

        .need_confirm_email .c3_chat_wrap {
            height: auto;
        }

        .c3_chat_scroll {
            height: unset;
            flex: 1;
        }

        .need_confirm_email .c3_chat_scroll {
            height: unset;
            flex: 1;
        }

        .column-2.active .c2_close {
            display: block;
            visibility: visible;
        }

        .c4_top_close {
            background-image: url(../../img/cross_white.svg);
            right: unset;
            left: -50px;
        }

        .column-4 .c4_top_close {
            display: none;
        }

        .column-4.active .c4_top_close {
            display: block;
        }

        .c3_fx {
            display: flex;
            flex-direction: column;
            height: 100%;
        }

        .c4_item_big_photo {
            display: none !important;
        }
    }

    @media screen and (min-width: 1140px) {
        .c2_top {
            padding: 16px;
        }

        .c2_chat_categories_btn {
            height: 30px;
            line-height: 30px;
            font-weight: 600;
            font-size: 12px;
            letter-spacing: -0.02em;
            color: #00317b;
            text-align: center;
            cursor: pointer;
            position: relative;
            padding: 0 10px;
        }

        .c2_chat_categories_btn_count {
            height: 18px;
            line-height: 18px;
            padding: 0 3px;
            background-color: #9999dd;
            border-radius: 4px;
            position: absolute;
            right: -2px;
            top: -5px;
            font-weight: 600;
            font-size: 12px;
            letter-spacing: -0.02em;
            color: #fff;
            min-width: 18px;
            text-align: center;
        }

        .c2_chat_categories_btn.active {
            background-color: #fff;
            box-shadow: 0px 2px 5px rgba(0, 49, 123, 0.1);
            border-radius: 5px;
        }

        .c3_chat_wrap {
            height: auto;
            flex: 1;
            overflow: hidden;
        }

        .need_confirm_email .c3_chat_wrap {
            height: auto;
        }

        .c3_chat_scroll {
            height: unset;
            flex: 1;
        }

        .need_confirm_email .c3_chat_scroll {
            height: unset;
            flex: 1;
        }

        .column-2.active .c2_close {
            display: block;
            visibility: visible;
        }

        .c4_top_close {
            background-image: url(../../img/cross_white.svg);
            right: unset;
            left: -50px;
        }

        .column-4 .c4_top_close {
            display: none;
        }

        .column-4.active .c4_top_close {
            display: block;
        }

        .c3_fx {
            display: flex;
            flex-direction: column;
            height: 100%;
        }

        .c4_item_big_photo {
            display: none !important;
        }
    }
}

@media screen and (max-width: 1139px) {
    .c2_top {
        padding: 16px;
    }

    .c2_chat_categories_btn {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30px;
        line-height: 30px;
        font-weight: 600;
        font-size: 12px;
        letter-spacing: -0.02em;
        color: #00317b;
        text-align: center;
        cursor: pointer;
        position: relative;
        padding: 0 10px;
    }

    .c2_chat_categories_btn_count {
        height: 18px;
        line-height: 18px;
        padding: 0 3px;
        background-color: #9999dd;
        border-radius: 4px;
        position: absolute;
        right: -2px;
        top: -5px;
        font-weight: 600;
        font-size: 12px;
        letter-spacing: -0.02em;
        color: #fff;
        min-width: 18px;
        text-align: center;
    }

    .c2_chat_categories_btn.active {
        background-color: #fff;
        box-shadow: 0px 2px 5px rgba(0, 49, 123, 0.1);
        border-radius: 5px;
    }

    .c3_top {
        flex: none;
    }

    .c3_chat_wrap {
        height: 100%;
        flex: 1;
        overflow: hidden;
        position: relative;
    }

    .need_confirm_email .c3_chat_wrap {
        //height: auto;
    }

    .c3_chat_scroll {
        height: unset;
        flex: 1;
    }

    .need_confirm_email .c3_chat_scroll {
        height: unset;
        flex: 1;
    }

    .column-2.active .c2_close {
        display: block;
        visibility: visible;
    }

    .c4_top_close {
        background-image: url(../../img/cross_white.svg);
        right: unset;
        left: -50px;
    }

    .column-4 .c4_top_close {
        display: none;
    }

    .column-4.active .c4_top_close {
        display: block;
    }

    .c3_fx {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .c4_item_big_photo {
        display: none !important;
    }

    .c4_list_wrap_2 {
        max-height: 0;
        transition: max-height 0.5s;
    }

    .c4_list_wrap {
        //max-height: 0;
    }

    .c4_top.active ~ .c4_list_wrap_2 {
        max-height: 100vh;
    }

    .c4_girls_title_mob_dot {
        display: block;
        width: 11px;
        height: 11px;
        border-radius: 100%;
        background-color: #eb5757;
        margin-left: 10px;
    }

    .c4_bottom_buttons {
        display: flex;
        flex-direction: row-reverse;
        flex-wrap: wrap;
        position: static;
        margin: 12px 8px 12px 8px;
    }

    .c4_support {
        width: auto;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        padding-left: 0;
    }

    .c4_support img {
        margin-left: 0;
        margin-right: 12px;
    }

    .c4_support svg {
        margin-left: 0;
        margin-right: 12px;
    }

    .c4_how_to_start_btn,
    .c4_whatsApp_btn {
        width: auto;
        flex: 1;
        position: static;
        border-radius: 5px;
        align-items: center;
        justify-content: center;
        padding-left: 0;
        margin-left: 8px;
        margin-bottom: 0;
    }

    .c4_how_to_start_btn {
        width: 100%;
        flex: none;
        order: 3;
        margin-top: 8px;
    }

    .c4_how_to_start_btn img,
    .c4_whatsApp_btn img {
        margin-right: 12px;
    }

    //.chat_wrap_confirm_email_wrap ~ .c3_chat_empty_img {
    //  max-height: calc(100% - 580px);
    //}

    .c3_chat_empty_bottom {
        margin-bottom: auto;
    }

    .c3_chat_empty_row_btn {
        height: 40px;
        line-height: 38px;
    }

    .column_2_chat_page .girls_slider_wrap_global {
        display: block;
    }

    .c4_mute {
        padding: 0 8px;
        margin-bottom: 12px;
    }

    .c4_mute_btn {
        width: 100%;
    }

    .mob_notify_list_fixed {
        display: block;
        position: fixed;
        top: 56px;
        right: 8px;
        padding-top: 6px;
        max-height: 161px;
        z-index: 9999;
        /*overflow: hidden;*/
        /*box-shadow: 0px 0px 12px rgba(47, 43, 99, 0.16);*/
        /*-webkit-border-radius: 5px;*/
        /*-moz-border-radius: 5px;*/
        /*border-radius: 5px;*/
    }

    .mob_notify_list_fixed:before {
        content: '';
        display: block;
        width: 14px;
        height: 14px;
        position: absolute;
        top: 2px;
        right: 18px;
        background: linear-gradient(45deg, transparent 50%, #fff 50%);
        transform: rotate(-45deg);
    }

    .mob_notify_list_fixed_item {
        width: 214px;
        background-color: #fff;
        box-shadow: 0px 0px 12px rgba(47, 43, 99, 0.16);
        border-radius: 5px;
        padding: 8px 10px 8px 9px;
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        position: relative;
    }

    .mob_notify_list_fixed_item_photo {
        margin-right: 12px;
        position: relative;
    }

    .mob_notify_list_fixed_item_photo img {
        width: 58px;
        height: 58px;
        object-fit: cover;
        border-radius: 100%;
        display: block;
    }

    .mob_notify_list_fixed_item_photo:after {
        content: '';
        display: block;
        width: 9px;
        height: 9px;
        position: absolute;
        bottom: 3px;
        right: 2px;
        background-color: #e0e0e0;
        border: 1px solid #ffffff;
        border-radius: 100%;
    }

    .mob_notify_list_fixed_item_photo.online:after {
        background-color: #27ae60;
    }

    .mob_notify_list_fixed_item_photo_fav {
        position: absolute;
        top: 2px;
        right: 2px;
        width: 14px;
        height: 13px;
        background-image: url(../../img/star-white-bd.svg);
        background-repeat: no-repeat;
        background-position: right top;
    }

    .mob_notify_list_fixed_item_name {
        font-weight: 600;
        color: #474f5c;
        margin-bottom: 5px;
    }

    .mob_notify_list_fixed_item_text {
        font-size: 11px;
        color: #333;
        overflow: hidden;
        display: block;
        display: -webkit-box;
        max-width: 125px;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
    }

    .mob_notify_list_fixed_item_close {
        width: 17px;
        height: 17px;
        background-image: url(../../img/cross-light.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 11px;
        position: absolute;
        right: 5px;
        top: 5px;
    }

    .mob_notify_bottom {
        display: block;
        width: 100%;
        overflow-x: auto;
        position: absolute;
        bottom: 70px;
        //padding-bottom: 5px;
        left: 0;
        z-index: 3;
        /*display: none; !* delete this*!*/
    }

    .mob_notify_bottom.likeher {
        bottom: 230px;
    }

    .mob_notify_bottom_list {
        display: flex;
        width: max-content;
        padding-bottom: 5px;
        padding-right: 5px;
    }

    .mob_notify_bottom_list_clear_all {
        width: 90px;
        height: 70px;
        box-shadow: 0px 1px 2px rgba(14, 4, 143, 0.24);
        border-radius: 0 5px 5px 0;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .mob_notify_bottom_list_clear_all_cross {
        width: 11px;
        height: 11px;
        background-image: url(../../img/notify-clear-all.svg);
        background-repeat: no-repeat;
        margin-right: 7px;
    }

    .mob_notify_bottom_list_clear_all_text {
        font-weight: 600;
        font-size: 12px;
        color: #474f5c;
    }

    .mob_notify_bottom_list_item {
        width: 292px;
        height: 80px;
        display: flex;
        align-items: center;
        padding: 8px;
        background-color: rgba(238, 241, 254, 0.88);
        box-shadow: 0px 1px 2px rgba(14, 4, 143, 0.24);
        backdrop-filter: blur(4px);
        border-radius: 5px;
        flex: none;
        margin-right: 5px;
    }

    .mob_notify_bottom_list_clear_all + .mob_notify_bottom_list_item {
        margin-left: 5px;
    }

    .mob_notify_bottom_list_item:first-child {
        margin-left: 3px;
    }

    .mob_notify_bottom_list_item_photo {
        width: 50px;
        height: 50px;
        display: block;
        border-radius: 100%;
    }

    .mob_notify_bottom_list_item_info {
        overflow: hidden;
        width: 70%;
    }

    .mob_notify_bottom_list_item_photo_wrap {
        position: relative;
        margin-right: 5px;
    }

    .mob_notify_bottom_list_item_photo_wrap:after {
        content: '';
        display: block;
        width: 6px;
        height: 6px;
        position: absolute;
        bottom: 1px;
        right: 1px;
        border: 1px solid #dee4f3;
        background-color: #b2b2b2;
        border-radius: 100%;
    }

    .mob_notify_bottom_list_item_photo_wrap.online:after {
        background-color: #27ae60;
    }

    .mob_notify_bottom_list_item_name {
        font-weight: 600;
        font-size: 17px;
        color: #474f5c;
    }

    .mob_notify_bottom_list_item_text {
        font-size: 16px;
        color: #333333;
        //width: 200px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        //width: 23%;
    }

    .mob_notify_bottom_list_item_close {
        width: 21px;
        height: 21px;
        background-image: url(../../img/notify-bottom-close.svg);
        background-size: 11px;
        background-repeat: no-repeat;
        background-position: center;
        margin-left: auto;
        padding: 5px;
    }
}

@media screen and (max-width: 1099px) {
}

@media screen and (max-width: 999px) {
    .column-3 .c3_head {
        padding-right: 15px;
    }

    .column-1.active {
        top: 50px;
        left: 0;
        opacity: 1;
    }

    /*.c1_wrap2{
		height: calc(100vh - 158px);
		height: calc(calc(var(--vh, 1vh) * 100) - 158px);
	}*/

    .column-1.active .c1_close {
        display: block;
        visibility: visible;
    }

    .c1_menu_item_privacy {
        padding-right: 0px;
    }

    .c1_add_credits {
        padding-left: 27px;
        background-position: 18px center;
    }

    .c1_add_credit_big {
        font-size: 18px;
    }

    .c1_add_credit_big img {
        width: 27px;
        margin-right: 11px;
    }

    .c1_logo {
        display: none;
    }

    .column-1:hover .c1_submenu_item_left {
        padding-left: 8px;
    }

    .c1_menu_item_free_video {
        display: block;
    }

    /*.c2_list_wrap{
		height: calc(100vh - 160px);
		height: calc(calc(var(--vh, 1vh) * 100) - 160px);
	}*/

    .left_menu_chat_list {
        display: none;
    }

    .mob_block_list_menu_btn {
        display: none;
    }

    .mob_block_list_menu_btn svg {
        fill: #fff;
        width: 30px;
        height: 30px;
    }

    .left_menu_chat_list svg {
        fill: #fff;
        width: 26px;
        height: 26px;
    }

    .column-1 .c1_logo img:nth-child(1) {
        height: auto;
    }

    .column-1 .c1_logo img:nth-child(2) {
        height: auto;
        margin-left: 4px;
    }

    .column-1 .c1_profile_right div {
        width: 112px;
    }

    .column-1 .c1_profile_right div span {
        width: 80px;
    }

    .column-1 .c1_menu_item span {
        max-width: 150px;
        visibility: visible;
    }

    .column-1 .c1_menu_item {
        justify-content: flex-start;
        padding-left: 10px;
    }

    .column-1 .c1_submenu_item_left {
        padding-left: 8px;
    }

    .column-1 .c1_submenu_item_left span {
        max-width: 100px;
        max-height: 20px;
        overflow: visible;
    }

    .column-1 .c1_menu_more_btn {
        justify-content: center;
    }

    .c4_list_wrap_2 {
        padding-right: 4px;
    }

    .head_empty {
        width: 90px;
    }
}

@media screen and (max-width: 899px) {
    .left_menu_chat_list {
        display: block;
        margin-left: 20px;
    }

    .mob_head_search .left_menu_chat_list {
        display: none;
    }

    .left_menu_chat_list svg {
        fill: #fff;
    }

    .head_empty {
        width: 91px;
    }
}

@media screen and (max-width: 789px) {
    .c4_minichat {
        right: 0;
    }
}

@media screen and (max-height: 820px) {
    .c3_chat_empty_img {
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 599px) {
    .c3_chat_go_to_new_message {
        width: 50px;
        height: 50px;
        transform: unset;
        margin-right: 5px;

        &.mobile {
            position: absolute;
            bottom: 14px;
            right: 8px;
            z-index: 10;
            left: unset;
            margin-right: 0;
        }
    }

    .c3_top .rhap_container {
        height: 43px;
    }

    .c3_top .rhap_progress-section {
        width: auto;
        left: 25px;
        height: 43px;
    }

    .c3_top .rhap_volume-button {
        display: none;
    }

    .c3_top .rhap_time {
        font-size: 10px;
        letter-spacing: -0.03em;
    }

    .c3_top .rhap_progress-container {
        display: none;
    }

    .c3_top .rhap_play-pause-button {
        width: 30px;
        height: 30px;
        line-height: 30px;
    }

    .c3_top .rhap_current-time:after {
        content: '/';
        margin-left: 2px;
        margin-right: 2px;
    }

    .c3_options_item.media > svg {
        width: 16px;
        height: 16px;
        margin-right: 8px;
    }

    .c3_options_item_border {
        height: 2px;
    }

    .c3_options_item_media_close {
        width: 13px;
        height: 13px;
        background-size: 9px;
        margin-right: 0;
        margin-left: 10px;
    }

    .c4_item_activity_btn {
        width: 140px;
    }

    .c4_item.unknown_like .c4_item_photo_wrap {
        width: 72px;
        height: 72px;
        background-size: 29px;
        background-position: calc(50% - 0px) calc(50% + 4px);
    }

    .c4_item_each_like_heart {
        margin: 0 13px;
    }

    .c3_head {
        height: auto;
        -webkit-flex-wrap: wrap;
        -moz-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        -o-flex-wrap: wrap;
        flex-wrap: wrap;
        padding: 10px 20px;
        position: relative;
    }

    .c3_head_place span {
        font-size: 9px;
        max-width: 50px;
    }

    .c3_head_place img {
        height: 9px;
    }

    .c3_head_right {
        margin-top: 0;
    }

    .head_logo {
        align-items: center;
    }

    .mob_block_list_menu_btn {
        display: block;
        margin-left: 16px;
    }

    .mob_block_list_menu_btn img {
        display: block;
        width: 26px;
    }

    .mob_block_list_menu_btn svg{
        fill: #fff;
        width: 30px;
        height: 30px;
    }

    .left_menu_chat_list svg {
        fill: #fff;
        width: 26px;
        height: 26px;
    }

    //.c3_chat_empty_img {
    //  max-height: calc(100% - 209px);
    //}

    .column-3 .c3_head {
        padding-left: 15px;
        height: 50px;
        padding-top: 0;
        padding-bottom: 0;
    }

    .c2_new_item_big_img {
        width: 110px;
        height: 170px;
    }

    .c2_new_item_big_img_wrap:after {
        width: 10px;
        height: 10px;
    }

    .c3_options {
        -webkit-flex-wrap: wrap;
        -moz-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        -o-flex-wrap: wrap;
        flex-wrap: wrap;
        padding: 0 12px;
        height: 30px;
    }

    .c3_options_item {
        font-size: 11px;
        text-transform: capitalize;
    }

    .c3_options_item span span {
        display: none;
    }

    .c3_options_item img,
    .c3_options_item svg {
        max-width: 14px;
        max-height: 14px;
    }

    .c3_options_item.c3_options_item_contact_request svg {
        max-width: 18px;
        max-height: 18px;
    }

    .c3_chat_wrap .c3_attach_text {
        top: calc(100% + 4px);
    }

    .message_content .message_text {
        font-size: 14px;
        padding: 9px;
    }

    .chat_message_wrap:last-child {
        margin-bottom: 0;
    }

    .c3_write_msg_wink_wrap {
        display: flex;
    }

    .chat_message_wrap.sticker .message_text img {
        width: 200px;
        height: 200px;
    }

    .c3_head_info {
        display: block;
    }

    .c3_head_place {
        margin-left: 0;
    }

    /*.c3_chat_wrap{
		height: calc(100vh - 185px);
		height: calc(calc(var(--vh, 1vh) * 100) - 185px);
	}

	.need_confirm_email .c3_chat_wrap{
		height: calc(100vh - 185px);
		height: calc(calc(var(--vh, 1vh) * 100) - 185px);
	}*/

    .c3_chat_scroll {
        /*height: 200px; !*TODO*!*/
        &.c3_chat_scroll_mobile {
            //position: relative;
            width: 100%;
            height: 100%;
            margin-right: 0;
        }
    }

    .need_confirm_email .c3_chat_scroll {
        height: 100%;
    }

    .c3_chat {
        padding: 20px 10px 12px;

        &.c3_chat_mobile {
            padding: 0 8px;
            display: grid;
            align-items: end;
            width: 100%;
            max-width: 100%;
            height: 100%;
            grid-template-rows: 1fr;
            //position: relative;
            grid-row-gap: 8px;
            grid-template-columns: 1fr;
        }
    }

    //.chat_message_wrap.right .message_avatar{
    //	display: none;
    //}

    .chat_message_wrap.right .message_content {
        margin-right: 0;
    }

    .chat_message_wrap.left .message_content {
        margin-right: 0;
    }

    .message_reply_text {
        width: 90px;
    }

    .message_video {
        /*width: 150px;*/
    }

    .message_img {
        width: 100%;
        height: auto;
        max-width: 283px;
    }

    .c4_minichat {
        width: 100vw;
        position: fixed;
        right: 0;
        top: 0;
    }

    .c4_minichat .chat_message_wrap.sticker .message_text img {
        width: 150px;
        height: 150px;
    }

    .left_menu_btn span {
        height: 2px;
        width: 28px;
        margin-bottom: 6px;
    }

    .left_menu_chat_list svg {
        width: 24px;
        height: 24px;
    }

    .head_empty {
        width: 74px;
    }

    .c3_head_photo_wrap {
        width: auto;
        height: auto;
        padding-top: 0;
        margin-right: 7px;
    }

    .c3_head_id {
        font-size: 9px;
        margin-right: 10px;
    }

    .c3_head_photo:after {
        width: 6px;
        height: 6px;
        top: unset;
        bottom: 1px;
        right: 1px;
        border-width: 1px;
    }
    .c3_head_photo:hover {
        background-color: transparent;
        position: relative;
        padding-bottom: 0px;
        margin-top: 0px;
        padding-top: 0px;
        box-shadow: unset;
        border-radius: 0px;
        z-index: 1;
    }

    .c3_head_photo:hover .c3_head_photo_bottom {
        display: none;
    }

    .c3_head_photo:hover:after {
        top: 27px;
        border-color: #446077;
    }

    .c3_send_btn span {
        display: block;
    }

    .c4_minichat .c3_chat_scroll {
        height: calc(100vh - 235px);
        height: calc(calc(var(--vh, 1vh) * 100) - 235px);
    }

    .emoji-mart {
        background-color: #ebedfa;
    }

    .c3_chat_empty_bottom {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
    }

    .c3_chat_empty_row {
        width: 100%;
        margin-bottom: 0;
    }

    .c3_chat_empty_row_half {
        align-self: flex-end;
        width: auto;
        margin-bottom: 27px;
    }

    .c3_chat_empty_row:first-child .c3_chat_empty_row_btn {
        width: 100px;
    }

    .c3_chat_empty_row_btn {
        font-size: 12px;
    }

    .c3_chat_empty_row_half .c3_chat_empty_row_btn {
        width: 100px;
    }

    .c3_chat_empty_row_half .c3_chat_empty_row_title {
        width: 100px;
    }

    .c3_chat_empty_row_title {
        font-size: 12px;
    }

    .add_file_popup_wrap {
        max-width: 100%;
    }

    .popup_add_file_row {
        margin: 0 10px;
    }

    .popup_add_file_row span {
        font-size: 12px;
    }

    .column-4 .c4_top_close {
        display: none;
    }

    .column-4.active .c4_top_close {
        display: block;
    }

    .c4_item_photo {
        width: 72px;
        height: 72px;
    }

    .c4_item_clear {
        top: 3px;
        right: 3px;
    }

    .mob_notify_list_fixed {
        top: 45px;
    }

    .column-4 .c2_item_photo_counter {
        top: -5px;
        left: -5px;
    }

    .c4_item_time {
        bottom: 3px;
    }

    .c4_item .c2_item_photo_fav {
        top: -1px;
        right: 1px;
    }

    .c4_item_photo_wrap:after {
        right: 2px;
        bottom: 2px;
        width: 10px;
        height: 10px;
    }

    .c4_item.viewed {
        padding: 10px;
    }

    .mob_notify_bottom_list_item {
        width: 92vw;
        height: 56px;
        margin-left: 9px;
        background-color: #e8ebfa;
    }

    //.mob_notify_bottom_list_item.small{
    //	width: 49vw;
    //}

    .mob_notify_bottom.likeher {
        bottom: 150px;
    }

    .mob_notify_bottom_list_item_photo {
        width: 40px;
        height: 40px;
    }

    .mob_notify_bottom_list_item_name {
        font-size: 14px;
    }

    .mob_notify_bottom_list_item_text {
        font-size: 12px;
    }

    .mob_notify_bottom_list_item_photo_wrap {
        margin-right: 7px;
    }

    .c4_support {
        font-size: 12px;
        height: 38px;
    }

    .c4_support img {
        width: 20px;
    }

    .c4_support svg {
        width: 24px;
        height: 24px;
    }

    .c4_how_to_start_btn,
    .c4_whatsApp_btn {
        font-size: 12px;
        height: 38px;
    }

    .c4_how_to_start_btn img {
        width: 14px;
        margin-right: 10px;
    }

    .c4_whatsApp_btn img {
        width: 18px;
        margin-right: 10px;
    }

    .message_avatar {
        margin-bottom: 12px;
    }

    .c4_mute_btn {
        height: 38px;
    }
}

@media screen and (max-width: 899px) and (max-height: 450px) and (min-width: 420px) {
    .c3_options_item.media > svg {
        width: 16px;
        height: 16px;
        margin-right: 8px;
    }

    .c3_options_item_border {
        height: 2px;
    }

    .c3_options_item_media_close {
        width: 13px;
        height: 13px;
        background-size: 9px;
        margin-right: 0;
        margin-left: 10px;
    }

    .column_2_chat_page .girls_slider_wrap_global {
        display: none;
    }

    .c4_top_close {
        background-image: url(../../img/cross_white.svg);
        right: -50px;
        left: unset;
    }

    .head_logo {
        align-items: center;
    }
    .left_menu_chat_list img {
        height: 25px;
    }

    .c2_new_item_big_img {
        width: 110px;
        height: 170px;
    }

    .c2_new_item_big_img_wrap:after {
        width: 10px;
        height: 10px;
    }

    .c3_head {
        height: auto;
        -webkit-flex-wrap: wrap;
        -moz-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        -o-flex-wrap: wrap;
        flex-wrap: wrap;
        padding: 10px 20px;
        position: relative;
    }

    //.c3_chat_empty_img {
    //  max-height: calc(100% - 30px);
    //  margin-bottom: 0;
    //}

    .column-3 .c3_head {
        padding-left: 24px;
        padding-right: 24px;
        height: 50px;
        padding-top: 0;
        padding-bottom: 0;
    }

    .c3_head_photo > img {
        height: 32px;
        width: 32px;
    }

    .c3_options {
        height: auto;
        -webkit-flex-wrap: wrap;
        -moz-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        -o-flex-wrap: wrap;
        flex-wrap: wrap;
        padding: 0 24px;
        height: 25px;
    }

    .c3_options_item {
        font-size: 9px;
    }

    .c3_options_item img,
    .c3_options_item svg {
        max-width: 16px;
        max-height: 16px;
    }

    .c3_options_item.c3_options_item_contact_request svg {
        max-width: 20px;
        max-height: 20px;
    }

    .message_content .message_text {
        font-size: 14px;
        padding: 9px;
    }

    .chat_message_wrap:last-child {
        margin-bottom: 0;
    }

    .chat_message_wrap.sticker .message_text img {
        width: 200px;
        height: 200px;
    }

    .c3_head_info {
        display: block;
    }

    .c3_head_place {
        margin-left: 0;
    }

    .c3_chat_wrap {
        height: auto;
        /*height: calc(100vh - 75px);
		height: calc(calc(var(--vh, 1vh) * 100) - 75px);*/
    }

    .need_confirm_email .c3_chat_wrap {
        /*height: calc(100vh - 75px);
		height: calc(calc(var(--vh, 1vh) * 100) - 75px);*/
    }

    .c3_chat_scroll {
        height: unset;
    }

    .need_confirm_email .c3_chat_scroll {
        height: unset;
    }

    .c3_chat {
        padding: 20px 10px 12px;
    }

    //.chat_message_wrap.right .message_avatar{
    //	display: none;
    //}

    .chat_message_wrap.right .message_content {
        margin-right: 0;
    }

    .chat_message_wrap.left .message_content {
        margin-right: 0;
    }

    .message_reply_text {
        width: 90px;
    }

    .message_video {
        /*width: 150px;*/
    }

    .message_img {
        width: 100%;
        height: auto;
        max-width: 283px;
    }

    .c4_minichat {
        width: 100vw;
        position: fixed;
        right: 0;
        top: 0;
    }

    .c4_minichat .chat_message_wrap.sticker .message_text img {
        width: 150px;
        height: 150px;
    }

    .left_menu_btn span {
        height: 2px;
        width: 28px;
        margin-bottom: 6px;
    }

    .left_menu_chat_list svg {
        width: 24px;
        height: 24px;
    }

    .head_empty {
        width: 74px;
    }

    .c3_head_photo_wrap {
        width: auto;
        height: auto;
        padding-top: 0;
        margin-right: 7px;
    }

    .c3_head_id {
        font-size: 10px;
        margin-right: 10px;
    }

    .c3_head_photo:after {
        width: 6px;
        height: 6px;
        top: unset;
        bottom: 1px;
        right: 1px;
        border-width: 1px;
    }
    .c3_head_photo:hover {
        background-color: transparent;
        position: relative;
        padding-bottom: 0px;
        margin-top: 0px;
        padding-top: 0px;
        box-shadow: unset;
        border-radius: 0px;
        z-index: 1;
    }

    .c3_head_photo:hover .c3_head_photo_bottom {
        display: none;
    }

    .c3_head_photo:hover:after {
        top: 27px;
        border-color: #446077;
    }

    .c3_send_btn {
    }

    .c3_send_btn span {
    }

    .c4_minichat .c3_chat_scroll {
        height: calc(100vh - 235px);
        height: calc(calc(var(--vh, 1vh) * 100) - 235px);
    }

    .c3_chat_empty_bottom {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
    }

    .c3_chat_empty_row {
        width: 100%;
        margin-bottom: 0;
    }

    .c3_chat_empty_row_half {
        align-self: flex-end;
        width: auto;
        margin-bottom: 27px;
    }

    .c3_chat_empty_row:first-child .c3_chat_empty_row_btn {
        width: 100px;
    }

    .c3_chat_empty_row_btn {
        font-size: 12px;
    }

    .c3_chat_empty_row_half .c3_chat_empty_row_btn {
        width: 100px;
    }

    .c3_chat_empty_row_half .c3_chat_empty_row_title {
        width: 100px;
    }

    .c3_chat_empty_row_title {
        font-size: 12px;
    }

    .c2_list {
        padding-bottom: 10px;
    }

    .c2_item {
        padding: 10px 0 4px;
    }

    .c2_item_top {
        align-items: center;
    }

    .c2_item_photo {
        width: 56px;
        height: 56px;
    }

    .c2_top {
        padding: 16px;
    }

    .c2_list_wrap {
        //height: calc(100vh - 50px);
        //height: calc(calc(var(--vh, 1vh) * 100) - 50px);
        flex: 1;
    }

    .mob_notify_bottom {
        bottom: 0px;
        width: calc(100% - 100px);
        left: 50px;
    }

    .c4_bottom_buttons {
        //flex-direction: column;
        margin-bottom: -36px;
        justify-content: flex-end;
        width: max-content;
    }

    .c4_bottom_buttons > div > img {
        display: none;
    }

    .c4_support {
        width: 71px;
        position: static;
        flex: none;
        height: 36px;
        font-size: 12px;
    }

    .c4_support img {
        width: 16px;
    }

    .c4_support svg {
        width: 20px;
        height: 20px;
    }

    .c4_how_to_start_btn {
        width: 71px;
        margin-left: 5px;
        flex: none;
        margin-bottom: 0;
        height: 36px;
        font-size: 12px;
    }

    .c4_how_to_start_btn img {
        width: 13px;
    }

    .c4_how_to_start_btn span:not(.mob_hor) {
        display: none;
    }

    .mob_hor {
        display: block;
    }

    .c4_mute {
        width: max-content;
        margin-left: auto;
    }

    .c4_mute_btn {
        height: 36px;
        width: 71px;
        margin-left: auto;
    }

    .c4_mute_btn img,
    .c4_mute_btn span span {
        display: none;
    }
}

.message_text.like > img {
    margin-right: 10px;
}

.message_text.like > svg {
    width: 12px;
    height: 12px;
    fill: #e0142c;
    margin-right: 10px;
}

@media (hover: none) and (pointer: coarse) {
    .column-3.c3_fx {
        width: 100%;
    }
}

.trust_form button[type='submit'] {
    width: 180px;
    height: 48px;
    max-width: 100%;
    background: $buttonBackgroundColor;
    color: #fff;
    font-size: 24px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
}

.notification-frame.notification-frame--error {
    font-size: 18px;
    line-height: 1.3;
    height: 50px;
    margin-bottom: 40px;
}

@media (orientation: landscape) and (max-height: 200px) and (min-width: 420px) {
    .c3_top {
        display: none;
    }
}

.c2_chat_categories_btn_counter {
    // Return Display for new mob chat
    //display: none;
    background: #eb5757;
    border-radius: 4px;
    min-width: 19px;
    height: 17px;
    margin: 0 4px 0 0;
    font-weight: 600;
    font-size: 10px;
    line-height: 100%;
    letter-spacing: -0.02em;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 6px;
    font-family: $mainFont;
}

@media screen and (max-width: 599px) {
    .c3_chat_mobile .chat_message_wrap {
        margin-bottom: 0;

        &:last-child {
            padding-bottom: 8px;
        }
    }
}

//.message_gift {
//  border-radius: 10px;
//  background: $chatTextareaBg;
//  width: 100%;
//  max-width: 100%;
//  margin-top: 212px;
//  padding: 56px 48px;
//  display: flex;
//  flex-direction: column;
//  box-sizing: border-box;
//  position: relative;
//  height: 212px;
//
//  @media screen and (max-width: 1359px) and (min-width: 1140px) {
//    padding: 100px 24px 56px;
//    height: fit-content;
//  }
//
//  @media screen and (max-width: 820px) {
//    padding: 90px 24px 24px;
//    height: fit-content;
//  }
//}
//
//.message_gift_name {
//  color: $profileButtonActiveColor;
//  font-size: 32px;
//  font-weight: 700;
//  line-height: 150%;
//  display: flex;
//  align-items: center;
//  margin-bottom: 10px;
//
//  @media screen and (max-width: 820px) {
//    font-size: 20px;
//    margin-bottom: 12px;
//  }
//
//  &:before {
//    content: '';
//    display: block;
//    width: 48px;
//    min-width: 48px;
//    height: 48px;
//    background: url('../../img/icon_virtual_gift.svg') center no-repeat;
//    background-size: contain;
//    margin-right: 16px;
//
//    @media screen and (max-width: 820px) {
//      width: 32px;
//      min-width: 32px;
//      height: 32px;
//      margin-right: 12px;
//    }
//  }
//}
//
//.message_gift_text {
//  margin: 0 0 0 46px;
//  font-size: 16px;
//  color: #474F5C;
//  font-weight: 600;
//  line-height: normal;
//
//  @media screen and (max-width: 820px) {
//    font-size: 14px;
//    margin-left: 38px;
//  }
//}
//
//.message_gift_image {
//  position: absolute;
//  right: 0;
//  bottom: 0;
//  width: 389px;
//  height: auto;
//  max-width: 389px;
//
//  @media screen and (max-width: 1460px) and (min-width: 1360px) {
//    width: 280px;
//    max-width: 280px;
//    bottom: 60px;
//  }
//
//  @media screen and (max-width: 1359px) and (min-width: 1140px) {
//    width: 280px;
//    max-width: 280px;
//    bottom: unset;
//    right: 50%;
//    transform: translateX(50%);
//    top: -140px;
//  }
//
//  @media screen and (max-width: 820px) {
//    width: 180px;
//    max-width: 180px;
//    bottom: unset;
//    right: 50%;
//    transform: translateX(50%);
//    top: -90px;
//  }
//}
//
//.message_gift_info {
//  display: flex;
//  align-items: center;
//  justify-content: flex-start;
//  position: relative;
//}
//
//.message_gift_image_wrapper {
//  width: 40px;
//  min-width: 40px;
//  height: 40px;
//  border-radius: 50%;
//  border: 2px solid #EEF1FE;
//  z-index: 1;
//
//  @media screen and (max-width: 820px) {
//    width: 32px;
//    min-width: 32px;
//    height: 32px;
//  }
//
//  img {
//    display: block;
//    object-fit: cover;
//    width: 100%;
//    height: 100%;
//    object-position: top center;
//    border-radius: 50%;
//  }
//
//  &:nth-child(2) {
//    position: absolute;
//    left: 30px;
//    top: 50%;
//    transform: translateY(-50%);
//    z-index: 0;
//
//    @media screen and (max-width: 820px) {
//      left: 22px;
//    }
//  }
//}
