@import '@sitestyles';

.column_2_letters_wrap {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
}

.column_2_letters_mob_scroll {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.c2l_compose_wrap {
    height: 80px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    justify-content: center;
    flex: none;
}

.c3l_mob_folder {
    display: none;
}

.c2l_compose_btn {
    background: $backgroundButtonColor;
    border-radius: 5px;
    width: 258px;
    height: 44px;
    line-height: 46px;
    font-weight: 600;
    font-size: 16px;
    color: $whiteColor;
    text-align: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.c2l_compose_btn:hover {
    box-shadow: 0 6px 12px rgba(84, 0, 114, 0.24);
}

.c2l_compose_btn img {
    width: 21px;
    vertical-align: middle;
    margin-top: -5px;
    margin-right: 12px;
}

.c2l_compose_btn svg {
    fill: #FFFFFF;
    width: 21px;
    height: 20px;
    margin-right: 12px;
}

/*letter folder*/

.c2l_letter_folders {
    padding: 0 16px;
    margin-bottom: 60px;
}

.c2l_letter_folder_item {
    height: 44px;
    border-left: 3px solid transparent;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px 0 20px;
    cursor: pointer;
}

.c2l_letter_folder_item_left {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
}

.c2l_letter_folder_item_left svg {
    margin-right: 9px;
    fill: $lettersMenuIconColor;
    width: 28px;
    height: 28px;
}

.c2l_letter_folder_item_left span {
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0.01em;
    color: $primaryColor;
}

.c2l_letter_folder_item_count {
    background-color: $primary2Color;
    border-radius: 4px;
    width: 50px;
    height: 24px;
    line-height: 26px;
    text-align: center;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: -0.02em;
    color: $whiteColor;
}

.c2l_letter_folder_item.active {
    background-color: $whiteColor;
    border-color: $accentLeftColor;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
    border-radius: 3px 5px 5px 3px;
}

.c2l_letter_folder_item.active .c2l_letter_folder_item_left span {
    font-weight: bold;
    color: $accentLeftColor;
}

.c2l_letter_folder_item.active .c2l_letter_folder_item_left svg {
    fill: $accentLeftColor;
}

.c2l_letter_folder_item.active.folder_inbox .c2l_letter_folder_item_left svg {
    fill: $accentLeftColor;
    stroke: $accentLeftColor;
    stroke-width: 0.2px;
}

/* column 2 filters */

.c2l_letter_filters_wrap {
    padding-right: 8px;
    flex: 1;
    overflow: hidden;
}

.c2l_letter_filters_wrap2 {
    padding-right: 8px;
    padding-left: 16px;
    height: 100%;
    overflow-y: auto;
}

.c2l_letter_filters_wrap2::-webkit-scrollbar {
    width: 3px;
    background: $littleGreyLittleBlueColor;
}

.c2l_letter_filters_wrap2::-webkit-scrollbar-thumb {
    background: $scrollbarThumbColor;
}

.c2l_letter_filters_box {
    margin-bottom: 60px;
}

.c2l_letter_filters_box:last-child {
    margin-bottom: 0;
}

.c2l_filters_head {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.c2l_filters_head_title {
    font-weight: 600;
    font-size: 18px;
    text-transform: uppercase;
    color: $accentLeftColor;
}

.c2l_filters_head_switch span {
    font-weight: 500;
    font-size: 14px;
    color: $primaryColor;
    margin-right: 13px;
}

.c2l_filters_head_switch input {
    display: none;
}

.c2l_filters_head_switch label {
    display: inline-block;
    width: 30px;
    height: 14px;
    background-color: $whiteColor;
    border-radius: 8px;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
    position: relative;
    vertical-align: middle;
    cursor: pointer;
}

.c2l_filters_head_switch label:after {
    content: '';
    display: block;
    width: 12px;
    height: 12px;
    position: absolute;
    left: 2px;
    top: 1px;
    background-color: $olderGrayColor;
    border-radius: 100%;
    transition: 0.3s;
}

.c2l_filters_head_switch input:checked + label:after {
    left: 16px;
    background-color: $onlineColor;
}

/* column 2 filters row */

.c2l_filters_row {
    padding-left: 27px;
    margin-bottom: 10px;
    height: 28px;
}

.c2l_filters_row input[type='radio'] {
    display: none;
}

.c2l_filters_row label {
    position: relative;
    padding-left: 24px;
    font-weight: 500;
    font-size: 16px;
    color: $arsenicColor;
    cursor: pointer;
    display: inline-block;
    margin-top: 5px;
}

.c2l_filters_row label:before {
    content: '';
    display: block;
    width: 12px;
    height: 12px;
    border: 1px solid $primaryColor;
    border-radius: 100%;
    position: absolute;
    left: 0;
    top: 2px;
}

.c2l_filters_row input[type='radio']:checked + label {
    color: $defaultTextColorAlpha9;
}

.c2l_filters_row input[type='radio']:checked + label:after {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    background-color: $primaryColor;
    border-radius: 100%;
    position: absolute;
    top: 5px;
    left: 3px;
}

.c2l_id_input {
    display: none;
    position: relative;
    margin-left: 9px;
}

.c2l_filters_row input:checked ~ .c2l_id_input {
    display: inline-block;
}

.c2l_id_input input {
    font-size: 14px;
    letter-spacing: 0.04em;
    width: 138px;
    height: 28px;
    background-color: $whiteColor;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    outline: none;
    padding: 2px 28px 0 7px;
}

.c2l_id_input input::placeholder {
    color: #333;
    font-weight: 500;
}

.c2l_id_input button {
    display: block;
    width: 28px;
    height: 28px;
    position: absolute;
    right: 0;
    top: 2px;
    cursor: pointer;
}

.c2l_id_input button img {
    display: block;
    margin: auto;
}

.c2l_id_input button svg {
    width: 16px;
    height: 16px;
}

/* column 2 bookmarked*/

.c2l_filters_bookmarked_wrap {
    display: none;
    margin-bottom: 80px;
    justify-content: space-between;
    -ms-align-items: center;
    align-items: center;
}

.bookmarked .c2l_filters_bookmarked_wrap {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
}

.c2l_filters_bookmarked_right {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
}

.c2l_filters_bookmarked_count {
    color: $oldGrayColor;
}

.c2l_filters_bookmarked_count span:nth-child(1) {
    color: $accentRightColor;
}

.c2l_filters_bookmarked_arrows {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    margin-left: 9px;
}

.c2l_filters_bookmarked_btn {
    width: 27px;
    height: 14px;
    border-radius: 5px;
    text-align: center;
    line-height: 12px;
    cursor: pointer;
}

.c2l_filters_bookmarked_btn.active {
    background-color: $accentRightColor;
}

.c2l_filters_bookmarked_btn:first-child {
    margin-right: 3px;
}

.c2l_filters_bookmarked_btn svg {
    width: 5px;
    height: 7px;
    fill: $silverColor;
    margin: auto;
}

/* column 2 search */

.c2l_letter_search_wrap {
    padding-right: 8px;
    display: none;
}

.c2l_letter_search_wrap2 {
    padding: 0 8px 5px 16px;
}

.c2l_search_wrap {
    position: relative;
    margin-bottom: 17px;
}

.c2l_search_wrap input {
    display: block;
    width: 100%;
    height: 30px;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
    background-color: $whiteColor;
    padding-left: 5px;
    padding-right: 35px;
    outline: none;
    color: $stormGreyColor;
}

.c2l_search_wrap button {
    position: absolute;
    height: 30px;
    width: 30px;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    outline: none;
    text-align: center;
}

.c2l_search_wrap button img {
    vertical-align: middle;
}

/* column 2 search result*/

.c2l_search_res_wrap {
    height: calc(100vh - 494px);
    height: calc(calc(var(--vh, 1vh) * 100) - 494px);
    overflow-y: auto;
    padding-right: 5px;
}

.bookmarked .c2l_search_res_wrap {
    height: calc(100vh - 596px);
    height: calc(calc(var(--vh, 1vh) * 100) - 596px);
}

.c2l_search_res_wrap::-webkit-scrollbar {
    width: 3px;
    background: $littleGreyLittleBlueColor;
}

.c2l_search_res_wrap::-webkit-scrollbar-thumb {
    background: $scrollbarThumbColor;
}

.c2l_search_res_item {
    background-color: $whiteColor;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
    padding: 12px;
    position: relative;
    margin-bottom: 10px;
}

.c2l_search_res_item:last-child {
    margin-bottom: 0;
}

.c2l_search_res_item_text {
    line-height: 150%;
    color: $stormGreyColor;
    padding-right: 36px;
    height: 60px;
    overflow: hidden;
    margin-bottom: 10px;
}

.c2l_search_res_item_bottom {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: space-between;
    -ms-align-items: flex-end;
    align-items: flex-end;
}

.c2l_search_res_item_attach span {
    line-height: 150%;
    color: $primaryColor;
}

.c2l_search_res_item_attach img {
    vertical-align: middle;
    margin-top: -2px;
}

.c2l_search_res_item_time {
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.03em;
    color: $silverColor;
}

.c2l_search_res_item_btn {
    position: absolute;
    right: 7px;
    top: 8px;
    cursor: pointer;
}

/* column 3 men list*/

.c3l_men_list_wrap {
    padding-right: 9px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    flex: 1;
}

.c3l_men_list_wrap .c2l_compose_wrap {
    display: none;
}

.c3l_men_list_wrap2 {
    padding: 10px 14px 30px 30px;
    //height: 100vh;
    //height: calc(var(--vh, 1vh) * 100);
    overflow-y: auto;
    height: 100%;
}

.c3l_men_list_wrap3 {
    height: 100%;
    overflow: hidden;
}

.need_confirm_email .c3l_men_list_wrap2 {
    //height: calc(100vh - 80px);
    //height: calc(calc(var(--vh, 1vh) * 100) - 80px);
}

.c3l_men_list_wrap2::-webkit-scrollbar {
    width: 5px;
    height: 3px;
    -webkit-border-radius: 22px;
    -moz-border-radius: 22px;
    border-radius: 22px;
    background: $bleachColor;
}

.c3l_men_list_wrap2::-webkit-scrollbar-thumb {
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    border-radius: 9px;
    background: $scrollbarThumbColor;
}

/* column 3 men item*/

.c3l_men_list_item {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    margin-bottom: 10px;
    background-color: $whiteColor;
}

.c3l_men_list_item:last-child {
    margin-bottom: 0;
}

.c3l_men_list_item.unread .c3l_delete_btn {
    opacity: 0.5;
    pointer-events: none;
}

.c3l_men_list_item_main {
    background-color: $whiteColor;
    width: calc(100% - 74px);
    box-shadow: 0px 4px 8px rgba(14, 4, 143, 0.24);
    padding-bottom: 0;
    position: relative;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
}

.c3l_men_list_item_main.favorites:before {
    content: '';
    display: block;
    height: 100%;
    width: 11px;
    position: absolute;
    left: 0;
    top: 0;
    background: linear-gradient(90deg, $bookmarkIconColor 0%, $transparentColor 248.78%);
    border-radius: 8px 0px 0px 8px;
}

.c3l_men_list_item.unread .c3l_men_list_item_main {
    background-color: $unreadLetter;
}

.c3l_men_list_item .c3l_men_list_item_main .letters_attach_files_item.file_audio {
    /*background-color: $background1Color;
*/
    flex-direction: column;
}

.c3l_letters_list_item_bottom .letters_attach_files_item.file_audio {
    flex-direction: column;
}

.c3l_men_list_item
    .c3l_men_list_item_main
    .letters_attach_files_item.file_audio
    .letters_attach_files_item_play {
    background-color: $background1Color;
}

.c3l_men_list_item.unread .c3l_men_list_item_main .letters_attach_files_item.file_audio {
    background-color: $whiteColor;
}

.files .c3l_men_list_item_main {
    padding-bottom: 5px;
}

.c3l_men_list_item_main_top {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    position: relative;
    padding: 18px 25px 16px 18px;
    margin: auto 0;
    cursor: pointer;
}

/* column 3 men item users*/

.c3l_men_list_item_users {
    width: 320px;
    max-width: 100%;
    flex: none;
}

/* column 3 men item from*/

.c3l_men_list_item_from {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
}

.c3l_big_avatar {
    display: none;
    padding: 5px 7px;
    background-color: $primary2Color;
    position: fixed;
    border-radius: 10px;
    transform: translateX(-100%);
    z-index: 5;
}

.c3l_big_avatar img {
    width: 201px;
    height: 294px;
    object-fit: cover;
    border-radius: 10px;
    display: block;
}

.c3l_men_list_item_from_photo {
    margin-right: 13px;
    position: relative;
}

.c3l_men_list_item_from_photo:before {
    content: '';
    display: none;
    width: 150px;
    height: 112px;
    position: absolute;
    right: 50%;
    top: 0;
    background-color: $primary2Color;
}

.c3l_men_list_item_from_photo:after {
    content: '';
    display: block;
    width: 11px;
    height: 11px;
    border-radius: 100%;
    background-color: $familySizeCondensedSoupColor;
    position: absolute;
    bottom: 14px;
    right: 14px;
    border: 2px solid $whiteColor;
}

.online .c3l_men_list_item_from_photo:after {
    background-color: $onlineColor;
}

.unread .c3l_men_list_item_from_photo:after {
    border-color: $background5Color;
}

.c3l_men_list_item_from_photo img {
    display: block;
    width: 112px;
    height: 112px;
    border-radius: 100%;
    object-fit: cover;
    position: relative;
    border: 6px solid transparent;
}

.c3l_men_list_item_from_photo_fav {
    width: 25px;
    height: 25px;
    position: absolute;
    top: 3px;
    right: 7px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(../../img/star-big.svg);
}

@media screen and (max-width: 599px) {
    .c3l_men_list_item_from_photo_fav {
        width: 17px;
        height: 17px;
        position: absolute;
        top: 0;
        right: -3px;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url(../../img/star-big.svg);
        background-size: 17px;
    }
}

.c3l_men_list_item_from_id {
    font-size: 12px;
    color: $arsenicColor;
    white-space: nowrap;
}

.c3l_men_list_item_from_name {
    font-weight: bold;
    font-size: 18px;
    color: $primaryColor;
    margin: 5px 0;
    padding-right: 5px;
    //white-space: nowrap;

    overflow: hidden;
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
}

.c3l_men_list_item_from_place {
    font-weight: 500;
    font-size: 12px;
    color: $arsenicColor;
}

.c3l_men_list_item_from_place img {
    vertical-align: middle;
    margin-top: -2px;
    margin-right: 6px;
    width: 16px;
    height: 12px;
    object-fit: contain;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}

/* column 3 men item to*/

.c3l_men_list_item_to {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    margin-top: 12px;
}

.c3l_men_list_item_to_photo {
    width: 34px;
    height: 34px;
    border-radius: 100%;
    object-fit: cover;
    margin-right: 12px;
}

.c3l_men_list_item_to_name {
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.01em;
    color: $defaultTextColorAlpha8;
    margin-bottom: 2px;
}

.c3l_men_list_item_to_bottom {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
}

.c3l_men_list_item_to_id {
    font-size: 10px;
    color: $olderGrayColor;
    position: relative;
}

.c3l_men_list_item_to_id:after {
    content: '';
    display: inline-block;
    width: 2px;
    height: 2px;
    border-radius: 100%;
    background-color: $olderGrayColor;
    margin: 0 4px;
    vertical-align: middle;
}

.c3l_men_list_item_to_place {
    font-size: 10px;
    letter-spacing: -0.03em;
    color: $olderGrayColor;
}

/* column 3 men item users end*/
/* column 3 men item content*/

.c3l_men_list_item_time {
    font-size: 10px;
    color: $defaultTextColorAlpha5;
    position: absolute;
    right: 16px;
    bottom: 6px;
}

.files .c3l_men_list_item_time {
    right: 24px;
}

.c3l_men_list_item_status {
    font-size: 10px;
    color: $defaultTextColorAlpha8;
    background-color: $background1Color;
    position: absolute;
    top: 16px;
    right: 16px;
    line-height: 18px;
    padding: 0 9px;
}

.c3l_men_list_item_status.answered {
    width: 78px;
    border-radius: 5px;
    background-color: $freeContentColor;
    text-align: center;
}

.unread .c3l_men_list_item_status.free {
    background-color: $letterReadBg;
}

.unread .c3l_men_list_item_status.count {
    background-color: $counterAndErrorColor;
}

.c3l_men_list_item_status.free {
    border-radius: 4px;
    background-color: $letterReadBg;
    min-width: 38px;
    text-align: center;
    padding: 0 10px;
    width: auto;
    height: 27px;
    line-height: 27px;
    color: $whiteColor;
    font-weight: 600;
    font-size: 16px;
    top: 9px;
    right: 60px;
}

.c3l_men_list_item_status.count {
    border-radius: 4px;
    background-color: $counterAndErrorColor;
    min-width: 38px;
    text-align: center;
    padding: 0 10px;
    width: auto;
    height: 27px;
    line-height: 27px;
    color: $whiteColor;
    font-weight: 600;
    font-size: 16px;
    top: 9px;
}

.c3l_men_list_item_status.unanswered {
    width: 78px;
    border-radius: 5px;
    background-color: $springSunsetFlowerColor;
    text-align: center;
}

.c3l_men_list_item_status.is_first {
    border-radius: 5px;
    border: $arsenicColor solid 1px;
    background-color: white;
}

.c3l_letters_list_history_wrap {
    width: 100%;
    position: absolute;
    left: 0;
    top: 1px;
    padding: 15px 0;
    background-color: $whiteColor;
    z-index: 2;
}

.c3l_letters_list_history_wrap ~ .c3l_letters_list_box_wrap {
    padding-top: 55px;
}

.mob-smiles {
    position: relative;
    left: 0;
    margin-top: 10px;
    width: 320px;
    height: 0;
    margin-bottom: 164px;
    background-color: white !important;
}

.mob-smiles .emoji-mart-category-label {
    display: none !important;
}

.mob-smiles .emoji-mart {
    width: 100% !important;
    border: none !important;
}

.c3l_letters_list_history_btn {
    width: 308px;
    height: 41px;
    max-width: 100%;
    border: 1px solid $primaryColor;
    border-radius: 8px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 18px;
    color: $primaryColor;
    cursor: pointer;
}

.c3l_letters_list_history_btn img {
    width: 17px;
    margin-right: 10px;
}

.c3l_letters_list_history_btn svg {
    width: 20px;
    height: 20px;
    fill: $primaryColor;
    transform: rotate(180deg);
    margin-right: 10px;
}

.files .c3l_men_list_item_status {
    right: 24px;
}

.unread .c3l_men_list_item_status {
    background-color: $whiteColorAlpha8;
}

.c3l_men_list_item_text_blur {
    filter: blur(3px);
}

.c3l_men_list_item_text {
    font-size: 13px;
    line-height: 19px;
    color: $stormGreyColor;
    max-height: 60px;
    margin-top: 8px;
    overflow: hidden;
    cursor: pointer;
    word-break: break-word;
    //margin-bottom: 10px;
    position: relative;
}

/*.c3l_men_list_item_text:after{*/
/*	content: "...";
*/
/*	position: absolute;
*/
/*	right: 0;
*/
/*	bottom: 3px;
*/
/*	background-color: $whiteColor;
*/
/*	padding-left: 2px;
*/
/*}*/

.unread .c3l_men_list_item_text:after {
    background-color: $background5Color;
}

/* column 3 men item bottom files*/

.c3l_men_list_item_main_bottom {
    padding-top: 8px;
    margin: 0 24px 0;
    border-top: 1px solid $defaultTextColorAlpha2;
    position: relative;
}

/* letters attach files list */

.letters_attach_files_list {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    -o-flex-wrap: wrap;
    flex-wrap: wrap;
    position: relative;
    padding-bottom: 10px;
}

.c3l_men_list_item_main_bottom .letters_attach_files_list {
    margin-left: -24px;
    margin-right: -24px;
}

.popup_write_letter .letters_attach_files_list {
    margin-left: -24px;
    margin-right: -24px;
    padding-bottom: 0;
}

.c3l_letters_list_write_wrap .letters_attach_files_list {
    margin-left: -24px;
    margin-right: -24px;
    padding-bottom: 0;
}

.c3l_men_list_item_main_bottom .carousel_container {
    padding: 0 24px;
}

.letters_attach_files_item {
    margin-right: 11px;
    cursor: pointer;
    border-radius: 5px;
    box-shadow: 0px 3px 3px rgba(14, 4, 143, 0.05);
}

.c3_write_msg_textareas_files .letters_attach_files_item {
    padding: 6px 0 6px 6px;
    box-sizing: content-box;
    background-color: $bleachColor;
    position: relative;
    display: flex;
    box-shadow: 0px 3px 3px rgba(14, 4, 143, 0.05);
}

.c3_write_msg_textareas_files .letters_attach_files_item.file_photo {
    /*width: 123px;
*/
}

.c3_write_msg_textareas_files .letters_attach_files_item.file_photo img {
    /*width: 85px;
*/
}

.c3_write_msg_textareas_files .letters_attach_files_item.file_video {
    width: 133px;
}

.c3_write_msg_textareas_files .letters_attach_files_item.file_video:after {
    left: calc(50% - 14px);
}

.c3_write_msg_textareas_files .letters_attach_files_item.file_video img {
    width: 95px;
}

.c3_write_msg_textareas_files_close {
    width: 38px;
    height: 100%;
    flex: none;
}

.c3_write_msg_textareas_files_close:after {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 14px;
    background-image: url(/img/c3l-remove-attach.svg);
}

.letters_attach_files_item.unpaid img {
    filter: blur(4px);
}

.letters_attach_files_item.file_photo img,
.letters_attach_files_item.file_video img {
    //width: auto;
    width: 100%;
    height: 53px;
    object-fit: cover;
    border-radius: 5px;
    margin: 0;
    top: 0;
    position: relative;
    z-index: 2;
    display: block !important;
}

.letters_attach_files_item.file_photo img {
    width: auto;
}

.letters_attach_files_item.file_photo {
    /*width: 85px;
*/
}

.letters_attach_files_item.file_video {
    width: 95px;
    position: relative;
}

.letters_attach_files_item.file_video video {
    height: 53px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    display: block;
}

.letters_attach_files_item.file_video:after {
    content: '';
    display: block;
    width: 29px;
    height: 29px;
    background-image: url(../../img/play-video.svg);
    position: absolute;
    left: 50%;
    top: 27px;
    transform: translateX(-50%) translateY(-50%);
    -webkit-background-size: contain;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 3;
    pointer-events: none;
}

.letters_attach_files_item.file_audio {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    background-color: $whiteColor;
    padding: 0 10px;
}

.letters_attach_files_item_play {
    z-index: 2;
    margin: 0 -10px;
    padding: 0 10px;
    box-shadow: 0px 3px 3px rgba(14, 4, 143, 0.05);
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}

.letters_attach_files_item_play img {
    display: block;
}

.letters_attach_files_item_preview {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 2;
}

.letters_attach_files_item_preview img {
    width: 100%;
    height: auto;
    max-height: 70vh;
    max-height: calc(var(--vh, 1vh) * 70);
    object-fit: contain;
}

.c3l_men_list_item_main_btn {
    width: 24px;
    height: 100%;
    line-height: 53px;
    text-align: center;
    position: absolute;
    top: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.c3l_men_list_item_main_btn.btn-prev {
    left: 3px;
}

.c3l_men_list_item_main_btn.btn-next {
    right: 3px;
}

.c3l_men_list_item_main_btn img {
    vertical-align: middle;
}

.c3l_men_list_item_main_btn.btn-prev img {
    left: -18px;
}

.c3l_men_list_item_main_btn.btn-next img {
    right: -18px;
}

/*letters attach files list slider

.letters_attach_files_list .slick-btn{
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	outline: none;
	cursor: pointer;
}

.letters_attach_files_list .slick-btn img{
	display: block;
}

.letters_attach_files_list .slick-btn.slick-disabled{
	opacity: .5;
	cursor: default;
}

.letters_attach_files_list .slick-prev{
	left: -17px;
}

.letters_attach_files_list .slick-prev img{
	transform: rotate(180deg);
}

.letters_attach_files_list .slick-next{
	right: -17px;
}

.letters_attach_files_list.slick-slider:after{
	content: '';
	display: block;
	width: 250px;
	height: 100%;
	position: absolute;
	top: 0;
	right: 0;
	background: linear-gradient(270deg, $background5Color 0%, rgba(227, 236, 252, 0) 112.98%);
	pointer-events: none;
}*/

/* column 3 letters list */

.c3l_letters_list_wrap {

    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
}

@mixin holiday-letter($name, $image-path, $image-mobile-path) {
    .c3l_letters_list_wrap.#{$name} {
        .c3l_letters_list_wrap2::before {
            content: '';
            display: block;
            height: 100%;
            width: 100%;
            background-image: url(#{$image-path});
            background-size: 100%;
            background-position: top center;
            background-repeat: no-repeat, no-repeat;
            position: absolute;
            pointer-events: none;
            left: 0;
            top: 0;

            @media screen and (max-width: 599px) {
                background-image: url(#{$image-mobile-path});
                background-position: top -15px center;
            }
        }

        .c3l_letters_chat_bottom {
            z-index: 100;
        }
    }
}

@include holiday-letter('halloween', '../../img/holiday/halloween_chat.svg', '../../img/holiday/halloween_chat_mobile.svg');
@include holiday-letter('lunar_new_year', '../../img/holiday/lunar_new_year_chat.svg', '../../img/holiday/lunar_new_year_chat_mobile.svg');
@include holiday-letter('thanksgiving_day', '../../img/holiday/thanksgiving_day_chat.svg', '../../img/holiday/thanksgiving_day_chat_mobile.svg');
@include holiday-letter('christmas', '../../img/holiday/christmas_chat.svg', '../../img/holiday/christmas_chat_mobile.svg');
@include holiday-letter('woman_day', '../../img/holiday/woman_day_chat.svg', '../../img/holiday/woman_day_chat_mobile.svg');
@include holiday-letter('valentine_day', '../../img/holiday/valentine_day_chat.svg', '../../img/holiday/valentine_day_chat_mobile.svg');
@include holiday-letter('independence_day', '../../img/holiday/independence_day_chat.svg', '../../img/holiday/independence_day_chat_mobile.svg');
@include holiday-letter('easter', '../../img/holiday/easter_chat.svg', '../../img/holiday/easter_chat_mobile.svg');

.c3l_letters_list_wrap2 {
    position: relative;
    padding: 24px;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.need_confirm_email .c3l_letters_list_wrap2 {
    /*height: calc(100vh - 216px);*/
    /*height: calc(calc(var(--vh, 1vh) * 100) - 216px);*/
}

/* column 3 letters write */

.popup_write_letter .c3_write_msg_textareas textarea {
    height: 100%;
}

.popup_write_letter .c3_write_msg_textareas textarea:focus {
    height: 100%;
}

.c3l_letters_list_write_top {
    /*background-color: #fff;*/
    flex: 1;
    border-radius: 8px;
}

.c3l_letters_list_write_top.with_files {
    display: flex;
    flex-direction: column;
}

.c3l_letters_list_write_top.with_files .c3_write_msg_textareas {
    height: calc(100% - 130px);
    position: relative;
}

.c3_write_msg_textareas_files {
    padding: 10px 0;
    border-top: 1px solid $defaultTextColorAlpha1;
    margin: 10px 16px 0;
}

.c3l_letters_list_write_wrap {
    background-color: $background1Color;
    /*height: 300px;*/
    margin-top: 24px;
    padding: 24px;
    border-radius: 8px;
}

.c3l_letters_list_write_area {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.popup_write_letter_write_area .c3l_letters_list_write_bottom {
    justify-content: space-between;
    align-items: center;
}

/* column 3 letters write bottom */

.c3l_letters_list_write_bottom {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
}

.c3l_letters_list_write_go_to_chat {
    background-color: rgb(255, 255, 255);
    text-align: center;
    width: 154px;
    height: 42px;
    color: $primaryOrangeColor;
    font-weight: 600;
    line-height: 40px;
    cursor: pointer;
    border-width: 1px;
    border-style: solid;
    border-color: $primaryOrangeColor;
    border-image: initial;
    border-radius: 5px;
}

.c3l_letters_list_write_go_to_chat:hover {
    box-shadow: 0px 4px 8px rgba(14, 4, 143, 0.24);
}

.c3l_letters_list_write_go_to_chat img {
    vertical-align: middle;
    width: 17px;
    margin-right: 10px;
}

.c3l_letters_list_write_go_to_chat svg {
    vertical-align: middle;
    width: 17px;
    height: 17px;
    margin-right: 10px;
    fill: $primaryOrangeColor;
}

.c3l_letters_list_write_bottom_right {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
}

.c3l_letters_list_write_bottom_left {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
}

.c3l_letters_list_write_bottom_count {
    color: $accentLeftColor;
}

.c3l_letters_list_write_smile {
    position: relative;
    padding: 7px;
    margin-right: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    letter-spacing: -0.01em;
    color: $accentRightColor;
}

.c3l_letters_list_write_smile img {
    display: block;
    margin-right: 9px;
    width: 27px;
}

.c3l_letters_list_write_smile_list {
    display: none;
    background-color: $bleachColor;
    position: absolute;
    right: 0;
    bottom: 100%;
    padding: 10px;
}

.c3l_letters_list_write_attach_btn {
    margin-right: 8px;
    cursor: pointer;
    position: relative;
    height: 40px;
    padding: 0 16px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    transition: 0.3s;
    color: $accentLeftColor;
    font-size: 12px;
    font-weight: 600;
    background: transparent;

    @media screen and (max-width: 1465px) {
        padding: 0 8px;
    }

    &:hover {
        color: $accentRightColor;

        .c3_attach_img {
            background: $accentRightColor;
        }

        .c3_attach_text {
            color: $accentRightColor;
        }
    }
}

@media screen and (max-width: 1465px) and (min-width: 1140px) {
    .c3l_letters_list_write_attach_btn .c3_attach_img {
        margin-right: 0;
        margin-bottom: 3px;
    }
}

//.c3l_letters_list_write_attach_btn img {
//  display: block;
//}

//.c3l_letters_list_write_attach_btn .c3_attach_text {
//  top: 50%;
//  left: calc(100% + 10px);
//  transform: translateY(-50%);
//  width: max-content;
//  //color: $accentLeftRightColor;
//}

//.popup_write_letter .c3_attach_text {
//  top: calc(100% + 5px);
//  left: 50%;
//  transform: translateX(-50%);
//  //color: $accentLeftRightColor;
//}

/* column 3 letters listbox */

.c3l_letters_list_box_wrap {
    flex: 1;
    overflow-y: auto;
    padding-right: 14px;
    margin-right: -19px;
}

.c3l_letters_list_box_wrap::-webkit-scrollbar {
    width: 3px;
    background: $bleachColor;
    border-radius: 5px;
    margin-right: 3px;
}

.c3l_letters_list_box_wrap::-webkit-scrollbar-thumb {
    background: $scrollbarThumbColor;
    border-radius: 7px;
}

.c3l_letters_list_box_wrap.close_letter_form {
    height: calc(100% - 60px);
    margin-bottom: 16px;
}

.c3l_letters_list_item {
    position: relative;
    padding: 24px;
    padding-bottom: 10px;
    margin-bottom: 24px;
    border-radius: 8px;
}

.c3l_letters_list_item:last-child {
    margin-bottom: 0;
}

.c3l_letters_list_item.right {
    background-color: $messageRightBackground;
}

.c3l_letters_list_item.left {
    background-color: $messageLeftBackground;
}

.c3l_letters_list_item.left.bookmarks {
    background-color: $background3Color;
}

.c3l_letters_list_item_head {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: space-between;
    margin-bottom: 11px;
    width: 100%;
}

.c3l_letters_list_item_head .message_status {
    margin-left: 0;
}

.c3l_letters_list_item_user {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
}

.c3l_letters_list_item_user span {
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.01em;
    color: $stormGreyColor;
}

.c3l_letters_list_item_user img {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    object-fit: cover;
    object-position: top;
    margin-left: 16px;
}

.left .c3l_letters_list_item_user img {
    margin-left: 0;
    margin-right: 9px;
}

.unselectable {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Chrome/Safari/Opera */
    -khtml-user-select: none; /* Konqueror */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently not supported by any browser */
}

.c3l_letters_list_item_read_btn {
    cursor: pointer;
    position: absolute;
    top: 5%;
    left: 36%;
    background: $letterReadBg;
    width: 28%;
    padding: 7px;
    text-align: center;
    border-radius: 6px;
    color: white;
}

.c3l_letters_list_item_text {
    /*max-height: 200px;
*/
    /*overflow: auto;
*/
    position: relative;
    padding-right: 9px;
}

.c3l_letters_list_item.right .c3l_letters_list_item_text {
}

.c3l_letters_list_item_text::-webkit-scrollbar {
    width: 3px;
    height: 3px;
    background: $whiteColor;
}

.c3l_letters_list_item_text::-webkit-scrollbar-thumb {
    background: $primaryColor;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}

.c3l_letters_list_item_text p {
    font-size: 16px;
    line-height: 150%;
    color: $stormGreyColor;
    word-break: break-word;
    white-space: break-spaces;
}

.c3l_letters_list_item_time {
    font-size: 12px;
    letter-spacing: -0.03em;
    color: $defaultTextColorAlpha6;
    margin-top: 12px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.left .c3l_letters_list_item_time {
    text-align: right;
}

.c3l_letters_list_item_delete {
    display: flex;
    align-items: center;
    padding: 0 10px;
    transition:
        background-color,
        opacity 0.3s;
    opacity: 0.6;
    color: #333;
    cursor: pointer;
    height: 37px;
    border-radius: 4px;
}

.c3l_letters_list_item_delete:hover {
    background-color: #fff;
    opacity: 1;
}

.c3l_letters_list_item_delete img {
    margin-right: 9px;
}

.c3l_letters_list_item_delete.left {
    margin-right: -13px;
}

.c3l_letters_list_item_delete svg {
    margin-right: 5px;
    width: 24px;
    height: 24px;
    fill: #7f7f82;
}

.c3l_letters_list_item_delete.right {
    margin-left: -13px;
}

.c3l_letters_list_item_bookmarks {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 37px;
    padding: 0 10px;
    font-size: 12px;
    letter-spacing: -0.03em;
    color: #333333;
    opacity: 0.6;
    transition:
        opacity,
        background-color 0.3s;
    border-radius: 4px;
    margin-right: -10px;
}

.c3l_letters_list_item_bookmarks:hover {
    opacity: 1;
    background-color: #fff;
}

.c3l_letters_list_item_bookmarks.active {
    opacity: 1;
}

.c3l_letters_list_item_bookmarks svg {
    width: 20px;
    height: 23px;
    fill: #000;
    margin-right: 8px;
}

.c3l_letters_list_item_bookmarks.active svg {
    fill: $bookmarkIconColor;
}

.c3l_letters_list_item_bottom {
    width: 100%;
    padding-top: 12px;
    border-top: 1px solid $defaultTextColorAlpha4;
    margin-top: 10px;
}

.c3l_letters_list_write_bottom .c3_smiles_list_wrap {
    background-color: #fff;
}

.c3l_letters_list_write_bottom .c3_smile_btn_wrap2.active:before {
    background-color: #fff;
}

/* popup compose letter */

.popup_compose_letter {
    display: inline-block;
    width: 1067px;
    height: 740px;
    background-color: $background1Color;
    padding: 32px;
    min-height: 400px;
    border-radius: 10px;
    max-width: 100%;
}

.popup_compose_letter .fancybox-close-small svg {
    color: $liteGreyColor;
}

.popup_compose_letter .fancybox-button {
    width: 30px;
    height: 30px;
    padding: 0;
}

/* popup compose letter head */

.popup_compose_head {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    justify-content: space-between;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    -o-flex-wrap: wrap;
    flex-wrap: wrap;
}

.popup_compose_head_search {
    position: relative;
    border-radius: 4px;
}

.popup_compose_head_search input {
    width: 247px;
    height: 42px;
    display: block;
    outline: none;
    background-color: $whiteColor;
    padding: 0 42px 0 15px;
    font-weight: 600;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    border: 1px solid #9aafd0;
    color: #00317b;
}

.popup_compose_head_search input::placeholder {
    font-weight: 500;
    color: #333;
}

.popup_compose_head_search input:hover {
    border-color: #5577ad;
    box-shadow: 0px 3px 6px rgba(28, 38, 119, 0.08);
}

.popup_compose_head_search input:focus {
    border-color: #00317b;
    box-shadow: 0px 3px 6px rgba(28, 38, 119, 0.08);
}

.popup_compose_head_search button {
    width: 42px;
    height: 42px;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
}

.popup_compose_head_search button img {
    display: block;
    margin: auto;
}

.popup_compose_head_button_close svg {
    fill: #ccd1d9;
    width: 14px;
    height: 14px;
    vertical-align: middle;
}

.popup_compose_head_button_search svg {
    fill: $iconCrossSearchColor;
    width: 16px;
    height: 16px;
    vertical-align: middle;
}

.popup_compose_head_right {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    -o-flex-wrap: wrap;
    flex-wrap: wrap;
}

.popup_compose_head_choosen_filters {
    margin-right: 16px;
}

.popup_compose_head_choosen_filters_item {
    border: 1px solid $defaultTextColorAlpha8;
    height: 42px;
    padding: 0 12px 0 12px;
    line-height: 42px;
    position: relative;
    border-radius: 5px;
}

.popup_compose_head_choosen_filters_item img {
    margin-left: 15px;
    cursor: pointer;
}

.popup_compose_head_choose_filter_wrap {
    position: relative;
}

.popup_compose_head_choose_filter_main {
    position: relative;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
    background-color: $whiteColor;
    width: 215px;
    height: 42px;
    line-height: 44px;
    font-weight: 500;
    font-size: 14px;
    color: $defaultTextColorAlpha5;
    padding-left: 14px;
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid #9aafd0;
    display: flex;
    align-items: center;
    text-transform: capitalize;
}

.popup_compose_head_choose_filter_main.active {
    border-radius: 5px 5px 0 0;
    border: 1px solid #00317b;
    border-bottom: none;
}

.popup_compose_head_choose_filter_main:not(.active):hover {
    border-color: #5577ad;
}

.popup_compose_head_choose_filter_main.chosen {
    font-weight: 600;
    color: #00317b;
}

.popup_compose_head_choose_filter_main:after {
    content: '';
    display: block;
    width: 9px;
    height: 5px;
    position: absolute;
    top: 19px;
    right: 15px;
    background-image: url(../../img/c3l-choose-filter-arrow.svg);
    transition: 0.3s;
}

.popup_compose_head_choose_filter_main.active:after {
    transform: rotate(180deg);
}

.popup_compose_head_choose_filter_main img {
    margin-right: 8px;
}

.popup_compose_head_choose_filter_main svg {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    fill: #999999;
}

.popup_compose_head_choose_filter_list_wrap {
    display: none;
    position: absolute;
    left: 0;
    top: 100%;
    background-color: $whiteColor;
    width: 100%;
    padding: 0 14px 14px 14px;
    z-index: 1;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
    border-radius: 0 0 5px 5px;
    border: 1px solid #00317b;
    border-top: none;
}

.popup_compose_head_choose_filter_list_wrap.active {
    display: block;
}

.popup_compose_head_choose_filter_list {
    border-top: 1px solid $familySizeCondensedSoupColor;
    padding-top: 14px;
}

.popup_compose_head_choose_filter_row {
    margin-bottom: 15px;
}

.popup_compose_head_choose_filter_row:last-child {
    margin-bottom: 0;
}

.popup_compose_head_choose_filter_row input {
    display: none;
}

.popup_compose_head_choose_filter_row label {
    display: block;
    position: relative;
    font-weight: 500;
    font-size: 16px;
    color: $arsenicColor;
    padding-left: 24px;
    line-height: 1;
    cursor: pointer;
}

.popup_compose_head_choose_filter_row label:before {
    content: '';
    display: block;
    width: 12px;
    height: 12px;
    border: 1px solid $primaryColor;
    border-radius: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

.popup_compose_head_choose_filter_row label:after {
    content: '';
    display: none;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background-color: $primaryColor;
    position: absolute;
    left: 3px;
    top: 3px;
}

.popup_compose_head_choose_filter_row input:checked + label:after {
    display: block;
}

.popup_compose_head_choose_filter_row input:checked + label {
    color: $defaultTextColorAlpha9;
}

.popup_compose_head_choose_filter_btn {
    width: 106px;
    height: 25px;
    line-height: 24px;
    border: 1px solid $primaryColor;
    border-radius: 5px;
    text-align: center;
    font-weight: 600;
    font-size: 12px;
    color: $primaryColor;
    margin: auto;
    cursor: pointer;
}

.popup_compose_head_choose_filter_btn img {
    vertical-align: middle;
    margin-top: -2px;
    margin-right: 10px;
}

/* popup compose letter men list */

.popup_compose_men_list_wrap {
    background-color: $whiteColor;
    padding-bottom: 22px;
    padding-left: 22px;
    border-radius: 8px;
    padding-right: 22px;
}

.popup_compose_men_list_wrap3 {
    padding-bottom: 19px;
    padding-top: 22px;
    margin-top: 23px;
    overflow-x: auto;
}

.popup_compose_men_list_wrap2 {
    padding-bottom: 22px;
    padding-left: 5px;
    margin-bottom: -19px;
    display: grid;
    grid-template-columns: repeat(auto-fill, 300px);
    grid-template-rows: repeat(4, 104px);
    grid-row-gap: 20px;
    grid-column-gap: 20px;
    grid-auto-flow: column;
}

.popup_compose_men_list_wrap3::-webkit-scrollbar {
    width: 3px;
    height: 3px;
    background: $bleachColor;
}

.popup_compose_men_list_wrap3::-webkit-scrollbar-thumb {
    background: $scrollbarThumbColor;
}

.popup_compose_man_item {
    border-radius: 8px;
}

.popup_compose_man_item input {
    display: none;
}

.popup_compose_man_item_label {
    position: relative;
    display: block;
    width: 300px;
    height: 104px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    box-shadow: 0px 4px 8px rgba(14, 4, 143, 0.24);
    padding: 0 16px;
    cursor: pointer;
    border-radius: 8px;
}

.popup_compose_man_item input:checked + .popup_compose_man_item_label,
.popup_compose_man_item.active {
    background-color: $background1Color;
}

.popup_compose_man_item_label:before {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    background-color: $whiteColor;
    border: 2px solid $liteGreyColor;
    position: absolute;
    top: 14px;
    right: 14px;
    border-radius: 1px;
}

.popup_compose_man_item_label.single:before {
    border: none;
    background: none;
}

.popup_compose_man_item input:checked + .popup_compose_man_item_label:before {
    border-color: $whiteColor;
    border-radius: 3px;
}

.popup_compose_man_item_label:after {
    content: '';
    display: none;
    width: 12px;
    height: 10px;
    position: absolute;
    top: 22px;
    right: 20px;
    background-image: url(../../img/c3l-compose-man-check.svg);
    -webkit-background-size: contain;
    background-size: contain;
    background-repeat: no-repeat;
}

.popup_compose_man_item input:checked + .popup_compose_man_item_label:after {
    display: block;
}

.popup_compose_man_item_photo {
    margin-right: 9px;
    position: relative;
}

.popup_compose_man_item_photo:after {
    content: '';
    display: block;
    width: 11px;
    height: 11px;
    background-color: $familySizeCondensedSoupColor;
    border: 2px solid $whiteColor;
    border-radius: 100%;
    position: absolute;
    right: 0;
    bottom: 0;
}

.online .popup_compose_man_item_photo:after {
    background-color: $onlineColor;
}

.popup_compose_man_item_photo img {
    width: 72px;
    height: 72px;
    border-radius: 100%;
    object-fit: cover;
    display: block;
}

.popup_compose_man_item
    input:checked
    + .popup_compose_man_item_label
    .popup_compose_man_item_photo:after {
    border-color: $background1Color;
}

.popup_compose_man_item_photo img {
    width: 72px;
    height: 72px;
    border-radius: 100%;
    object-fit: cover;
    display: block;
}

.popup_compose_man_item_id {
    font-size: 10px;
    color: $defaultTextColorAlpha6;
}

.popup_compose_man_item_name {
    font-weight: 600;
    font-size: 18px;
    color: $stormGreyColor;
    margin: 2px 0 4px;
}

.popup_compose_man_item_place {
    font-size: 12px;
    letter-spacing: -0.03em;
    color: $defaultTextColorAlpha6;
}

.popup_compose_man_item_place img {
    height: 12px;
    vertical-align: middle;
    margin-right: 2px;
}

.popup_compose_add_men_btn {
    width: 177px;
    height: 42px;
    background: $backgroundButtonColor;
    border-radius: 4px;
    color: $whiteColor;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.01em;
    line-height: 42px;
    text-align: center;
    cursor: pointer;
    margin-top: 24px;
    margin-left: auto;
}

.popup_compose_add_men_btn:hover {
    box-shadow: 0px 6px 12px rgba(84, 0, 114, 0.24);
}

.popup_compose_add_men_btn img {
    vertical-align: middle;
    margin-top: -2px;
    margin-right: 10px;
}

.popup_compose_add_men_btn svg {
    vertical-align: middle;
    margin-top: -2px;
    margin-right: 10px;
    width: 20px;
    height: 20px;
    fill: #FFFFFF;
}

/* popup compose letter end */
/* popup write new letter */

.popup_write_letter {
    display: flex;
    flex-direction: column;
    width: 1104px;
    max-width: calc(100% - 16px);
    background-color: $background1Color;
    padding: 32px;
    height: 671px;
    max-height: 100%;
    overflow-y: auto;
    border-radius: 8px;
    position: relative;
}

.popup_write_letter_head {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    justify-content: space-between;
}

.popup_write_letter_fromto {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: center;
}

.popup_write_letter_fromto:first-child {
    margin-right: 38px;
}

.popup_write_letter_fromto_text {
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0.01em;
    color: $primaryColor;
}

.popup_write_letter_user {
    position: relative;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    background-color: $whiteColor;
    width: 264px;
    max-width: 100%;
    height: 54px;
    -ms-align-items: center;
    align-items: center;
    padding-left: 8px;
    /*margin-left: 10px;*/
    cursor: pointer;
    border-radius: 8px;
    border: 1px solid #9aafd0;
}

.popup_write_letter_users_hidden .popup_write_letter_user {
    border: none;
}

.popup_write_letter_user.active {
    border-radius: 8px 8px 0 0;
    border-color: #00317b;
}

.popup_write_letter_user:not(.active):hover {
    box-shadow: 0px 3px 6px rgba(28, 38, 119, 0.08);
}

.popup_write_letter_user:after {
    content: '';
    display: block;
    width: 10px;
    height: 6px;
    position: absolute;
    top: 24px;
    right: 15px;
    background-image: url(../../img/c3l-choose-filter-arrow.svg);
    -webkit-background-size: contain;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    transition: 0.3s;
}

.popup_write_letter_user.active:after {
    transform: rotate(180deg);
}

.popup_write_letter_user_photo {
    margin-right: 11px;
    position: relative;
}

.popup_write_letter_user_photo:after {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background-color: $familySizeCondensedSoupColor;
    position: absolute;
    bottom: 0;
    right: 0;
    border: 2px solid $whiteColor;
}

.online .popup_write_letter_user_photo:after {
    background-color: $onlineColor;
}

.popup_write_letter_user_photo img {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    object-fit: cover;
    display: block;
}

.popup_write_letter_user_name {
    font-weight: 600;
    font-size: 12px;
    color: $stormGreyColor;
    margin-bottom: 2px;
}

.popup_write_letter_user_bottom {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
}

.popup_write_letter_user_to_id {
    position: relative;
    font-size: 10px;
    color: $defaultTextColorAlpha6;
}

.popup_write_letter_user_to_id:after {
    content: '';
    display: inline-block;
    width: 2px;
    height: 2px;
    border-radius: 100%;
    background-color: $olderGrayColor;
    margin: 0 4px;
    vertical-align: middle;
}

.popup_write_letter_user_place {
    font-size: 10px;
    letter-spacing: -0.03em;
    color: $defaultTextColorAlpha6;
}

.popup_write_letter_users_hidden {
    display: none;
    position: absolute;
    left: -1px;
    top: 100%;
    width: calc(100% + 2px);
    z-index: 2;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
    border-radius: 0 0 8px 8px;
    max-height: 216px;
    overflow-y: auto;
    border: 1px solid #00317b;
    border-top: none;
}

.popup_write_letter_users_hidden.active {
    display: block;
}

.popup_write_letter_users_hidden::-webkit-scrollbar {
    width: 3px;
    background: $whiteColor;
    border-radius: 3px;
}

.popup_write_letter_users_hidden::-webkit-scrollbar-thumb {
    background: $scrollbarThumbColor;
    border-radius: 3px;
}

.popup_write_letter_users_hidden .popup_write_letter_user {
    border-radius: 0;
}

.popup_write_letter_users_hidden:before {
    content: '';
    display: block;
    width: calc(100% - 16px);
    height: 1px;
    background-color: $familySizeCondensedSoupColor;
    position: absolute;
    top: 0;
    left: 8px;
    z-index: 2;
}

.popup_write_letter_users_hidden .popup_write_letter_user {
    margin-left: 0;
}

.popup_write_letter_users_hidden .popup_write_letter_user:after {
    display: none;
}

/* popup write new letter write_area*/

.popup_write_letter_write_area {
    margin-top: 16px;
    flex: 1;
}

.popup_write_letter .c3l_letters_list_write_go_to_chat {
    width: 213px;
}

.popup_write_letter .fancybox-close-small svg {
    color: $liteGreyColor;
}

@media screen and (max-width: 1199px) and (min-width: 1140px) {
    .need_confirm_email ~ .mob_notif_fixed {
        top: 8px;
    }
}

.mob_notif_fixed {
    display: none;
    width: 65px;
    height: 44px;
    position: fixed;
    right: 0;
    top: 10px;
    padding: 7px;
    padding-left: 15px;
    background-color: $whiteColor;
    border-radius: 8px 0 0 8px;
    box-shadow: 0px 4px 8px rgba(14, 4, 143, 0.14);
    cursor: pointer;
}

/* mobile notification fixed */
@media screen and (max-width: 1199px) {
    .mob_notif_fixed {
        display: block;
    }
}

.mob_notif_fixed .c2_item_photo_counter {
    width: 20px;
    height: 20px;
    line-height: 22px;
    font-size: 12px;
    left: 4px;
    top: -3px;
}

.mob_notif_fixed.active .c2_item_photo_counter {
    left: 18px;
    top: 8px;

    @media screen and (max-width: 599px) {
        left: 6px;
        top: 1px;
    }
}

/* popup attach photo click*/

.popup_attach_photo_click {
    display: inline-block;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    -ms-align-items: center;
    align-items: center;
    justify-content: center;
    background: $fadeColor;
    z-index: 9999;
}

.popup_attach_photo_click.active {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
}

.popup_attach_photo_click_img_wrap {
    height: auto;
    max-height: 100%;
    position: relative;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup_attach_photo_click_img_wrap.unpaid {
    overflow: hidden;
    background-color: $trueGreyColor;
}

.popup_attach_photo_click_img {
    max-width: 100%;
    max-height: calc(100vh - 30px);
    max-height: calc(calc(var(--vh, 1vh) * 100) - 30px);
    object-fit: contain;
    vertical-align: middle;
    border-radius: 10px;
}

.pap-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 77px;
    height: 77px;
    background: $whiteColorAlpha5;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 99;
}

.pap-btn svg {
    width: 22px;
    height: 35px;
    fill: $primaryColor;
}

.btn-left {
    left: 0;
    border-radius: 0px 40px 40px 0px;
}

.btn-right {
    right: 0;
    border-radius: 40px 0px 0px 40px;
}

.pap-btn-close {
    z-index: 9999;
    position: absolute;
    right: 32px;
    top: 32px;
    cursor: pointer;
}

.pap-btn-close svg {
    width: 55px;
    height: 55px;
    fill: #FFFFFF;
}

.pap-btn-close-sendberly {
    z-index: 9999;
    position: absolute;
    right: 40px;
    top: 40px;
    cursor: pointer;
}

.pap-btn-close-sendberly svg {
    width: 40px;
    height: 40px;
    fill: #FFFFFF;
}

.column-3.profile_man ~ .pap-btn-close {
    top: 15px;
    right: 15px;
    position: fixed;
}

.click_to_view {
    cursor: pointer;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 80px;
    line-height: 80px;
    font-weight: 600;
    font-size: 1.2em;
    color: $defaultTextColor;
    text-align: center;
    background: linear-gradient(0deg, $whiteColor 0%, $transparentColor 100%);
    border-radius: 0px 0px 10px 10px;
}

.c3l_men_list_item_from_to {
    font-weight: 500;
    font-size: 18px;
    color: $defaultTextColorAlpha8;
    margin-right: 15px;
    margin-left: 8px;
}

.popup_audio_item_check {
    margin-left: 32px;
}

.popup_audio_item_delete {
    cursor: pointer;
}

.popup_audio_item_delete svg {
    fill: #eb5757;
    margin-right: -2px;
    height: 20px;
    width: 20px;
    vertical-align: middle;
}

.letters_attach_files_item.not-payed {
    filter: blur(4px);
}

.letters_attach_files_item .not-payed {
    filter: blur(4px);
}

.file_video_overflow {
    overflow: hidden;
    height: 100%;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}

.file_video_inside {
    position: relative;
    z-index: 2;
}

/*slider in letter chat*/
.c3l_letters_list_item_bottom {
    padding-top: 8px;
    margin-top: 16px;
    margin: 16px 24px 0;
    /*overflow: hidden;
*/
    position: relative;
}

.c3l_item_btn {
    width: 24px;
    height: 53px;
    line-height: 53px;
    text-align: center;
    position: absolute;
    bottom: 50px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.c3l_item_btn.btn-prev {
    left: 7px;
}

.c3l_item_btn.btn-next {
    right: 7px;
}

.c3l_item_btn img {
    vertical-align: middle;
}

.c3l_item_btn.btn-prev img {
    transform: rotate(180deg);
}

/*slider in letter chat (form)*/
.c3_write_msg_textareas_files {
    padding-top: 8px;
    margin-top: 16px;
    margin: 16px 24px 0;
    /*overflow: hidden;
*/
    position: relative;
    height: max-content;
}

.popup_write_letter .c3_write_msg_textareas_files {
    height: auto;
    overflow: visible;
}

.popup_write_letter .c3l_men_list_item_main_btn {
    height: 100%;
}

.popup_write_letter .c3l_men_list_item_main_btn .slick-arrow.slick-prev {
    top: 50%;
    transform: translateY(-50%);
}

.popup_write_letter .c3l_men_list_item_main_btn .slick-arrow.slick-next {
    top: 50%;
    transform: translateY(-50%);
}

.c3l_form_item_btn {
    width: 24px;
    height: 53px;
    line-height: 53px;
    text-align: center;
    position: absolute;
    bottom: 18px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.c3l_form_item_btn.btn-prev {
    left: 3px;
}

.c3l_form_item_btn.btn-next {
    right: 3px;
}

.c3l_form_item_btn img {
    vertical-align: middle;
}

.c3l_form_item_btn.btn-prev img {
    transform: rotate(180deg);
}

.c3_write_msg_textareas.with_files {
    position: relative;
}

.carousel_container {
    padding: 0 20px;
    width: 100%;
}

.c3_write_msg_textareas_files .carousel_container {
    padding: 0 24px;
}

.c3l_letters_list_item .carousel_container {
    padding: 0 24px;
}

.slick-arrow.slick-next {
    right: -16px;
    cursor: pointer;
}

.slick-arrow.slick-prev {
    left: -16px;
    cursor: pointer;
}

.letters_attach_files_item.hidden {
    display: none;
}

.c3l_letters_list_item .letters_attach_files_list {
    padding-bottom: 0;
    margin-left: -24px;
    margin-right: -24px;
}

.c3l_letters_chat_bottom {
    display: flex;
    justify-content: space-between;
}

.c3l_letters_chat_bottom .c2l_compose_wrap {
    height: auto;
}

.c3l_letters_chat_bottom .c2l_compose_btn {
}

.c3l_letters_chat_bottom .c3l_letters_list_write_go_to_chat {
    height: 44px;
    line-height: 44px;
    width: 173px;
}

.c3l_letters_list_write_wrap .c3l_letters_list_write_go_to_chat {
    height: 44px;
    line-height: 44px;
    width: 173px;
}

.c3_write_letter_like,
.c3_write_letter_wink {
    display: none;
}

//.c2l_compose_btn {
//  position: relative;
//}
//
//.c2l_compose_btn:before {
//  content: "";
//  display: inline-block;
//  width: 17px;
//  height: 14px;
//  background-image: url(../../img/letter-white.svg);
//  background-repeat: no-repeat;
//  background-position: center;
//  margin-bottom: -1px;
//  margin-right: 8px;
//}

.mobile_c2l_compose_btn {
    position: relative;
    background: $backgroundButtonColor;
    border-radius: 5px;
    width: auto;
    height: 30px;
    font-weight: 600;
    font-size: 12px;
    line-height: 100%;
    padding: 8px;
    color: $whiteColor;
    text-align: center;
    cursor: pointer;

    &:before {
        content: '';
        display: inline-block;
        width: 17px;
        height: 14px;
        background-image: url(../../img/letter-white.svg);
        background-repeat: no-repeat;
        background-position: center;
        margin-bottom: -2px;
        margin-right: 8px;
    }
}

@media screen and (max-width: 1139px) {
    .pap-btn-close {
        width: 46px;
        height: 46px;
        background-image: url(../../img/icon_exit.svg);
        background-repeat: no-repeat;
        background-position: center;
        top: 10px;
        right: 10px;
        background-size: contain;
    }

    .column-3.profile_man ~ .pap-btn-close {
        right: 5px;
        top: 5px;
        width: 46px;
        height: 46px;
    }

    .pap-btn-close img {
        width: 20px;
        display: none;
    }

    .pap-btn-close svg {
        display: none;
    }

    .need_confirm_email .c3l_letters_list_wrap {
        height: 100%;
    }
}

@media (hover: none) and (pointer: coarse) {
    .c3l_letters_list_item_delete.left {
        //display: flex;
        //align-items: center;
        //justify-content: center;
        //position: static;
        //width: 30px;
        //height: 30px;
    }

    .left .c3l_letters_list_item_time {
        //display: flex;
        //justify-content: flex-end;
        //align-items: center;
        //margin-top: 6px;
    }

    .c3l_letters_list_item .c3l_letters_list_item_bottom {
        //margin-top: 8px;
    }

    .c3l_letters_list_item_bookmarks {
        //width: 30px;
        //height: 30px;
        //display: flex;
        //align-items: center;
        //justify-content: center;
    }

    @media screen and (min-width: 1000px) {
        .c3l_men_list_wrap2 {
            padding: 20px 10px 20px 20px;
        }
    }
}

@media screen and (max-width: 599px) {
    .c3l_men_list_item_status.free {
        right: 50px;
        font-size: 12px;
    }

    .c3l_men_list_item_main .carousel_container {
        padding: 0 16px;
    }

    .c3l_letters_list_item .carousel_container {
        padding: 0 16px;
    }

    .c3l_letters_list_write_wrap .letters_attach_files_list {
        margin-left: -10px;
        margin-right: -10px;
    }

    .c3l_letters_list_item .letters_attach_files_list {
        margin-left: -16px;
        margin-right: -16px;
    }

    .c3l_letters_list_item .slick-arrow.slick-prev {
        left: -17px;
    }

    .c3l_letters_list_item .slick-arrow.slick-next {
        right: -16px;
    }

    .c3l_men_list_item_main .slick-arrow.slick-prev {
        left: -17px;
    }

    .c3l_men_list_item_main .slick-arrow.slick-next {
        right: -16px;
    }

    .c3l_men_list_item_main_bottom .letters_attach_files_list {
        margin-left: -16px;
        margin-right: -16px;
    }

    .c3l_letters_list_write_wrap .slick-arrow.slick-prev {
        /*top: 16px;*/
    }

    .c3l_letters_list_write_wrap .slick-arrow.slick-next {
        /*top: 16px;*/
    }

    .c3l_men_list_item_main_btn.btn-next img {
        width: 10px;
    }

    .c3l_men_list_item_main_btn.btn-prev img {
        width: 10px;
    }

    .popup_write_letter {
        padding: 16px 16px 40px;
        height: 70vh;
        display: flex;
        flex-direction: column;
        margin: auto;
  }

    .popup_write_letter_write_area {
        height: auto;
        flex: 1;
        margin-top: 10px;
    }

    .popup_write_letter_user {
        width: 100%;
        height: 42px;
    }

    .popup_write_letter_fromto {
        flex: 1;
        margin-left: 10px;
    }

    .popup_write_letter_user_photo img {
        width: 34px;
        height: 34px;
    }

    .popup_write_letter_user_photo:after {
        width: 6px;
        height: 6px;
    }

    .c3l_letters_chat_bottom .c3l_letters_list_write_go_to_chat span {
        display: block;
    }

    .c3l_letters_list_write_wrap .c3l_letters_list_write_go_to_chat span {
        display: none;
    }

    .c3l_letters_chat_bottom .c3l_letters_list_write_go_to_chat {
        white-space: nowrap;
        padding-left: 5px;
        width: 60px;
    }

    .c3l_letters_list_write_wrap .c3l_letters_list_write_go_to_chat {
        width: 36px;
        height: 32px;
        background-image: url(../../img/in-letter-back.svg);
        background-position: center;
        background-repeat: no-repeat;
        display: none;
    }

    .c3l_letters_chat_bottom .c2l_compose_btn {
        width: 180px;
    }

    .c2l_compose_btn svg {
        fill: #FFFFFF;
        width: 32px;
        height: 32px;
        margin-right: 6px;
    }

    .c3l_letters_list_box_wrap.close_letter_form {
        height: calc(100% - 70px);
        margin-bottom: 16px;
        padding-bottom: 0;
    }

    .c3l_letters_list_box_wrap.mobile_c3l_letters_list_box_wrap.close_letter_form {
        height: 100%;
    }

    .c3l_letters_list_write_bottom_count {
        max-width: 100px;
        font-size: 11px;
        text-align: center;
    }

    .c3l_letters_list_write_wrap .c3l_letters_list_write_bottom_count {
        max-width: 80px;
        margin-left: 0;
        position: absolute;
        right: 63px;
        text-align: right;
    }

    .c2l_compose_wrap {
        height: 70px;
        flex: none;
    }
}

@media screen and (max-width: 899px) and (max-height: 450px) and (min-width: 420px) {
    .c3l_letters_list_item .carousel_container {
        padding: 0 16px;
    }

    .c3l_letters_list_item .letters_attach_files_list {
        margin-left: -16px;
        margin-right: -16px;
    }

    .c3l_men_list_item_main_btn.btn-prev img {
        left: -16px;
    }

    .c3l_men_list_item_main_btn.btn-next img {
        right: -16px;
    }

    .c3l_letters_list_box_wrap.close_letter_form {
        height: 100%;
    }

    .c3l_letters_list_box_wrap.close_letter_form.c3l_letters_scroll {
        width: 80%;
        height: calc(100% - 8px);
        margin-bottom: 0;
    }

    .c3l_letters_chat_bottom {
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: flex-end;
        padding-bottom: 8px;
    }

    .c3l_letters_chat_bottom .c2l_compose_btn {
        width: 150px;
    }

    .c3l_letters_chat_bottom .c3l_letters_list_write_go_to_chat {
        margin-bottom: 16px;
        width: 150px;
        padding-left: 0;
    }

    .c2l_letter_filters_wrap2 {
        height: auto;
    }

    .c3l_letters_list_write_wrap {
        /*height: 100%;*/
    }
}

// For new mobile letters

.c2l_letter_filter_btn {
    //display: none;
    min-width: 98px;
    height: 30px;
    padding: 0 22px;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    color: $accentLeftColor;
    background: transparent;
    outline: 0;
    border: 0;
    white-space: nowrap;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: -0.02em;
    line-height: 1;
    font-family: $mainFont;
    text-transform: capitalize;
    width: 100%;
    display: flex;

    @media screen and (max-width: 599px) {
        display: flex;
    }
}

.c2l_letter_filter_btn.selected {
    background: #ebedfa;
}

.c2l_letter_filter_counter {
    background: #eb5757;
    border-radius: 4px;
    min-width: 19px;
    height: 17px;
    margin: -2px 4px 0 0;
    font-weight: 600;
    font-size: 10px;
    line-height: 100%;
    letter-spacing: -0.02em;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 4px;
    font-family: $mainFont;
}

.mobile_c3l_letters_list_box_wrap {
    padding: 8px 8px 0 !important;
    margin-bottom: 0 !important;
}

.mobile_c3l_letters_list_box_wrap .c3l_letters_list_item .carousel_container {
    padding: 0 !important;
}

.mobile_c3l_letters_list_box_wrap .c3l_letters_list_item {
    padding: 12px !important;
    border-radius: 8px !important;
    margin-bottom: 8px !important;
}

.mobile_c3l_letters_list_box_wrap .c3l_letters_list_item.right {
    background: #e3ecfc !important;
}

.mobile_c3l_letters_list_box_wrap .c3l_letters_list_item.left {
    background: #f3f4fc !important;
}

.mobile_c3l_letters_list_box_wrap .c3l_letters_list_item .letters_attach_files_list {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.mobile_c3l_letters_list_box_wrap .c3l_letters_list_item_user img {
    width: 32px;
    height: 32px;
}

.mobile_c3l_letters_list_box_wrap .c3l_letters_list_item_user span {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: #474f5c;
}

.mobile_c3l_letters_list_box_wrap .c3l_letters_list_item_head {
    margin-bottom: 8px !important;
    width: 100%;
}

.mobile_c3l_letters_list_box_wrap .c3l_letters_list_item_text {
    padding-right: 0 !important;
}

.mobile_c3l_letters_list_box_wrap .c3l_letters_list_item_text p {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.01em;
    color: #4f4f4f;
    padding-right: 0;
}

.mobile_c3l_letters_list_box_wrap .c3l_letters_list_item_time {
    line-height: 14px;
    color: #bdbdbd;
    margin-top: 4px;
}

.mobile_c3l_letters_list_box_wrap .c3l_letters_list_item_delete {
    display: none;
}

.mobile_c3l_letters_list_box_wrap .c3l_letters_list_item_bookmarks {
    color: #4f4f4f;
    opacity: 1;
    box-sizing: border-box;
}

.mobile_c3l_letters_list_box_wrap .c3l_letters_list_item_bookmarks svg {
    stroke: #4f4f4f;
}

.mobile_c3l_letters_list_box_wrap .c3l_letters_list_item_bottom {
    margin: 12px 0 0;
    padding-top: 12px;
    padding-bottom: 0;
    border-top: 1px solid #bdbdbd;
}

.mobile_c3l_letters_list_box_wrap .letters_attach_files_item.file_photo img {
    max-width: 60px;
    max-height: 38px;
}

.mobile_c3l_letters_list_box_wrap .letters_attach_files_item .media_file_status {
    display: none;
}

.mobile_c3l_letters_list_box_wrap .letters_attach_files_item.file_video {
    max-width: 60px;
    max-height: 38px;
    width: auto;
}

.mobile_c3l_letters_list_box_wrap .letters_attach_files_item {
    margin-right: 0 !important;
}

.mobile_c3l_letters_list_box_wrap .c3l_letters_list_item.right .slick-slide,
.mobile_c3l_letters_list_box_wrap .c3l_letters_list_item.left .slick-slide {
    margin-right: 8px !important;
}

.mobile_c3l_letters_list_box_wrap .letters_attach_files_item.file_audio {
    padding: 0 12px 0 10px !important;
    width: 189px !important;
    height: 38px !important;
}

.mobile_c3l_letters_list_box_wrap .letters_attach_files_item .letters_attach_files_item_play_title {
    font-size: 10px !important;
    line-height: 12px !important;
    width: 135px !important;
    left: 42px !important;
    top: 4px !important;
}

.mobile_c3l_letters_list_box_wrap .letters_attach_files_item img {
    width: 60px !important;
    height: 38px !important;
    object-position: center;
    object-fit: cover;
    //min-width: 9px !important;
}

.mobile_c3l_letters_list_box_wrap .letters_attach_files_item.file_audio img {
    width: 9px !important;
    height: 9px !important;
}

.mobile_c3l_letters_list_box_wrap .letters_attach_files_item .rhap_container {
    width: 100% !important;
    height: 100% !important;
}

.mobile_c3l_letters_list_box_wrap .letters_attach_files_item .rhap_play-pause-button {
    width: 22px;
    height: 22px;
}

.mobile_c3l_letters_list_box_wrap .letters_attach_files_item.file_video:after {
    top: 50%;
}

.mobile_c3l_letters_list_box_wrap .audioplayer_play {
    width: 22px;
    height: 22px;
}

.mobile_c3l_letters_list_box_wrap .letters_attach_files_item .rhap_progress-section {
    width: 135px !important;
    position: relative;
    top: 20px !important;
    left: 32px !important;
    justify-content: space-between;
    height: 17px !important;
}

.mobile_c3l_letters_list_box_wrap .letters_attach_files_item .rhap_current-time {
    font-size: 10px;
    line-height: 12px;
}

.mobile_c3l_letters_list_box_wrap .letters_attach_files_item .rhap_total-time {
    font-size: 10px;
    line-height: 12px;
}

.mobile_c3l_letters_list_box_wrap .rhap_progress-bar {
    height: 2px !important;
}

//.mobile_c3l_letters_list_box_wrap .rhap_progress-bar-show-download {
//  background-color: rgba(156, 110, 247, 0.5) !important;
//}

.mobile_c3l_letters_list_box_wrap .rhap_download-progress {
    background-color: rgba(156, 110, 247, 0.5) !important;
}

.mobile_c3l_letters_list_box_wrap .c3l_men_list_item_main_btn.btn-next img,
.mobile_c3l_letters_list_box_wrap .c3l_men_list_item_main_btn.btn-prev img {
    width: 8px !important;
}

.mobile_c3l_letters_list_box_wrap .c3l_letters_list_item .slick-arrow.slick-next {
    right: -14px !important;
}

.mobile_c3l_letters_list_box_wrap .c3l_letters_list_item .slick-arrow.slick-prev {
    left: -14px !important;
}

.mobile_c3l_letters_list_box_wrap .slick-arrow.slick-next,
.mobile_c3l_letters_list_box_wrap .slick-arrow.slick-prev {
    top: 14px !important;
}

.mobile_c3l_letters_list_box_wrap .slick-track {
    display: flex !important;
    align-items: flex-end !important;
}

.mobile_c3l_letters_list_box_wrap .c3l_letters_list_item.right .c3l_letters_list_item_user img {
    margin-left: 8px !important;
}

.mobile_c3l_letters_list_box_wrap .letters_attach_files_item_play {
    width: 100%;
    margin: 0 !important;
    padding: 0 !important;
    box-shadow: unset !important;
}

.mobile_c3l_letters_list_box_wrap .c3l_letters_list_item_user > div {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
}
